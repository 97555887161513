import {
  CHART_DATA_FETCHING,
  CHART_DATA_FETCHED,
  CHART_FETCH_FAIL,
  CHART_FETCH_ERROR
} from "../actions/types";

const initialState = [
  {
    dataCharts: {},
    dataDayToDayComparison: {},
    dataEngagmentByStore: {},
    dataOrderByCategory: {},
    dataTop10MostEngagingStores: {},
    dataTopCity: {},
    dataInteraction: {},
    dataWeekToWeekComparison: {},
    fetchStatus: false,
    fetchCode: 0,
    fetchMessage: "",
    isFetching: true
  }
];

function charts(state = initialState, action) {
  switch (action.type) {
    case CHART_DATA_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case CHART_DATA_FETCHED:
      let successFetchCount = Object.values(action.payload.chartData).reduce(
        (item, {success}) => item + success,
        0
      );
      let failFetchCount = Object.values(action.payload.chartData).reduce(
        (item, {success}) => item + !success,
        0
      );
      let constructData = {};
      Object.assign(
        constructData,
        action.payload.chartData.reduce((prev, cur) => {
          if (cur.success === false) {
            prev[`${cur.path.split("/")[1]}`] = cur;
          } else {
            prev[cur.message] = cur;
          }
          return prev;
        }, {})
      );
      let errorCatch = [];
      action.payload.chartData.map(item => {
        if (item.success === false) {
          errorCatch.push(item.message);
        }
        return errorCatch;
      });
      return {
        ...state,
        isFetching: false,
        dataCharts: constructData,
        dataDayToDayComparison: constructData.dayToDayComparison.payload,
        dataEngagmentByStore: constructData.engagmentByStore.payload,
        dataOrderByCategory: constructData.orderByCategory.payload,
        dataTop10MostEngagingStores: constructData.tOP_10.payload,
        dataTopCity: constructData.tOP_CITY.payload,
        /* FIXME: API data on progress */
        // dataInteraction: (constructData.interaction.payload === undefined) ? constructData.interaction.message : constructData.interaction.payload,
        dataWeekToWeekComparison: constructData.weekToWeekComparison.payload,
        fetchCode:
          failFetchCount === 0
            ? 200
            : `200S${successFetchCount}_F${failFetchCount}`,
        fetchStatus: {
          success: successFetchCount,
          fail: failFetchCount
        },
        fetchMessage:
          failFetchCount === 0
            ? "All Fetch Success!"
            : `${successFetchCount} Fetch Success & ${failFetchCount} Fetch Fail caused by : ${[
                ...errorCatch
              ]}`
      };
    case CHART_FETCH_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };

    case CHART_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };
    default:
      break;
  }
  return state;
}

export default charts;
