import {
  VARIANT_FECTHING,
  VARIANT_FETCHED,
  VARIANT_SEARCH_FETCHING,
  VARIANT_SEARCH_FETCHED,
  VARIANT_SEARCH_FAIL,
  VARIANT_SEARCH_ERROR,
  VARIANT_ADD_INIT,
  VARIANT_ADDING,
  VARIANT_ADDED,
  VARIANT_ADD_FAIL,
  VARIANT_ADD_ERROR,
  VARIANT_UPDATE_INIT,
  VARIANT_UPDATING,
  VARIANT_UPDATED,
  VARIANT_UPDATE_FAIL,
  VARIANT_UPDATE_ERROR,
  VARIANT_DELETING,
  VARIANT_DELETED,
  VARIANT_DELETE_FAIL,
  VARIANT_DELETE_ERROR,
  VARIANT_FAIL,
  VARIANT_ERROR
} from "../actions/types";

const initialState = {
  dataVariants: [],
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true,

  dataSearchedVariants: [],
  fetchSearchedVariantStatus: false,
  fetchSearchedVariantCode: 0,
  fetchSearchedVariantMessage: "",
  isSearchedVariantFetching: true,

  addVariants: {},
  fetchAddVariantStatus: false,
  fetchAddVariantCode: 0,
  fetchAddVariantMessage: "",
  isAddVariantFetching: true,

  updateVariants: {},
  fetchUpdateVariantStatus: false,
  fetchUpdateVariantCode: 0,
  fetchUpdateVariantMessage: "",
  isUpdateVariantFetching: true,

  deleteVariants: {},
  fetchDeleteVariantStatus: false,
  fetchDeleteVariantCode: 0,
  fetchDeleteVariantMessage: "",
  isDeleteVariantFetching: true
};
function variants(state = initialState, action) {
  switch (action.type) {
    case VARIANT_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case VARIANT_FETCHED:
      const constructData = {
        variantData: action.payload.payload.data,
        variantDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isFetching: false,
        dataVariants: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case VARIANT_SEARCH_FETCHING:
      return {
        ...state,
        isSearchedVariantFetching: true
      }

    case VARIANT_SEARCH_FETCHED:
      const constructSearchedVariantData = {
        variantData: action.payload.payload.data,
        variantDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isSearchedVariantFetching: false,
        dataSearchedVariants: constructSearchedVariantData,
        fetchSearchedVariantStatus: action.payload.success,
        fetchSearchedVariantCode: action.payload.code,
        fetchSearchedVariantMessage: action.payload.message
      }

    case VARIANT_SEARCH_FAIL:
      return {
        ...state,
        isSearchedVariantFetching: false,
        fetchSearchedVariantStatus: action.payload.success,
        fetchSearchedVariantCode: action.payload.code,
        fetchSearchedVariantMessage: action.payload.message
      }

    case VARIANT_SEARCH_ERROR:
      return {
        ...state,
        isSearchedVariantFetching: false,
        fetchSearchedVariantCode: action.payload.code,
        fetchSearchedVariantMessage: action.payload.message
      }

    case VARIANT_ADD_INIT:
      return {
        ...state,
        fetchAddVariantStatus: false,
        fetchAddVariantCode: 0,
        fetchAddVariantMessage: "",
      }
    case VARIANT_ADDING:
      return {
        ...state,
        isAddVariantFetching: true
      };
    case VARIANT_ADDED:
      return {
        ...state,
        isAddVariantFetching: false,
        addVariants: action.payload,
        dataVariants: state.dataVariants,
        fetchAddVariantStatus: action.payload.success,
        fetchAddVariantCode: action.payload.code,
        fetchAddVariantMessage: action.payload.message
      };
    case VARIANT_ADD_FAIL:
      return {
        ...state,
        isAddVariantFetching: false,
        fetchAddVariantStatus: action.payload.success,
        fetchAddVariantCode: action.payload.code,
        fetchAddVariantMessage: action.payload.message
      };
    case VARIANT_ADD_ERROR:
      return {
        ...state,
        isAddVariantFetching: false,
        fetchAddVariantMessage: action.payload.message
      };
    /*  */
    case VARIANT_UPDATING:
      return {
        ...state,
        isUpdateVariantFetching: true
      };
    case VARIANT_UPDATED:
      return {
        ...state,
        isUpdateVariantFetching: false,
        updateVariants: action.payload,
        dataVariants: state.dataVariants,
        fetchUpdateVariantStatus: action.payload.success,
        fetchUpdateVariantCode: action.payload.code,
        fetchUpdateVariantMessage: action.payload.message
      };
    case VARIANT_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateVariantStatus: false,
        fetchUpdateVariantCode: 0,
        fetchUpdateVariantMessage: "",
      }
    case VARIANT_UPDATE_FAIL:
      return {
        ...state,
        isUpdateVariantFetching: false,
        fetchUpdateVariantStatus: action.payload.success,
        fetchUpdateVariantCode: action.payload.code,
        fetchUpdateVariantMessage: action.payload.message
      };
    case VARIANT_UPDATE_ERROR:
      return {
        ...state,
        isUpdateVariantFetching: false,
        fetchUpdateVariantMessage: action.payload.message
      };
    case VARIANT_DELETING:
      return {
        ...state,
        isDeleteVariantFetching: true
      };
    case VARIANT_DELETED:
      return {
        ...state,
        isDeleteVariantFetching: false,
        deleteVariants: action.payload,
        dataVariants: state.dataVariants,
        fetchDeleteVariantStatus: action.payload.success,
        fetchDeleteVariantCode: action.payload.code,
        fetchDeleteVariantMessage: action.payload.message
      };
    case VARIANT_DELETE_FAIL:
      return {
        ...state,
        isDeleteVariantFetching: false,
        fetchDeleteVariantStatus: action.payload.success,
        fetchDeleteVariantCode: action.payload.code,
        fetchDeleteVariantMessage: action.payload.message
      };
    case VARIANT_DELETE_ERROR:
      return {
        ...state,
        isDeleteVariantFetching: false,
        fetchDeleteVariantMessage: action.payload.message
      };
    case VARIANT_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case VARIANT_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };

    default:
      break;
  }
  return state;
}

export default variants;
