import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "../reducers";

const defaultState = {};
const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducers, defaultState, composeEnhancers(applyMiddleware(...middleware)));

if (module.hot) {
  module.hot.accept("../reducers/", () => {
    const nextRootReducer = require("../reducers/index").default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
