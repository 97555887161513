import {
  PRODUCT_FECTHING,
  PRODUCT_FETCHED,
  PRODUCT_SEARCH_FETCHING,
  PRODUCT_SEARCH_FETCHED,
  PRODUCT_SEARCH_FAIL,
  PRODUCT_SEARCH_ERROR,
  PRODUCT_ADD_INIT,
  PRODUCT_ADD_FETCHING,
  PRODUCT_ADD_FETCHED,
  PRODUCT_ADD_FAIL,
  PRODUCT_ADD_ERROR,
  PRODUCT_UPDATE,
  PRODUCT_DELETE,
  PRODUCT_FAIL,
  PRODUCT_ERROR,
  PRODUCT_DETAIL_FETCHING,
  PRODUCT_DETAIL_FETCHED,
  PRODUCT_DETAIL_FAIL,
  PRODUCT_DETAIL_ERROR,
  PRODUCT_UPDATE_INIT,
  PRODUCT_UPDATE_FETCHING,
  PRODUCT_UPDATE_FETCHED,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_ERROR,
  PRODUCT_VARIANT_UPDATE_INIT,
  PRODUCT_VARIANT_UPDATE_FETCHING,
  PRODUCT_VARIANT_UPDATE_FETCHED,
  PRODUCT_VARIANT_UPDATE_FAIL,
  PRODUCT_VARIANT_UPDATE_ERROR,
} from "../actions/types";

const initialState = {
  dataProducts: [],
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true,

  /* Search Products */
  dataSearchedProducts: [],
  fetchSearchedStatus: false,
  fetchSearchedCode: 0,
  fetchSearchedMessage: "",
  isSearchedFetching: true,

  /* Add Products */
  addProductData: {},
  fetchAddProductStatus: false,
  fetchAddProductCode: 0,
  fetchAddProductMessage: "",
  isAddProductFetching: true,

  /* Detail Products */
  dataDetailProducts: {},
  fetchDetailProductStatus: false,
  fetchDetailProductCode: 0,
  fetchDetailProductMessage: "",
  isDetailProductFetching: true,

  /* Update Detail Products */
  updatedDetailProducts: {},
  fetchUpdateProductStatus: false,
  fetchUpdateProductCode: 0,
  fetchUpdateProductMessage: "",
  isUpdateProductFetching: true,

  /* Update Variant Detail Products */
  updatedVariantDetailProducts: {},
  fetchUpdateVariantProductStatus: false,
  fetchUpdateVariantProductCode: 0,
  fetchUpdateVariantProductMessage: "",
  isUpdateVariantProductFetching: true
};

function products(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case PRODUCT_FETCHED:
      const constructData = {
        productData: action.payload.payload.data,
        productDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isFetching: false,
        dataProducts: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PRODUCT_SEARCH_FETCHING:
      return {
        ...state,
        isSearchedFetching: true
      }
    case PRODUCT_SEARCH_FETCHED:
      const constructSearchedData = {
        productData: action.payload.payload.data,
        productDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isSearchedFetching: false,
        dataSearchedProducts: constructSearchedData,
        fetchSearchedStatus: action.payload.success,
        fetchSearchedCode: action.payload.code,
        fetchSearchedMessage: action.payload.message,
      }
    case PRODUCT_SEARCH_FAIL:
      return {
        ...state,
        isSearchedFetching: false,
        fetchSearchedStatus: action.payload.success,
        fetchSearchedCode: action.payload.code,
        fetchSearchedMessage: action.payload.message,
      }
    case PRODUCT_SEARCH_ERROR:
      return {
        ...state,
        isSearchedFetching: false,
        fetchSearchedMessage: action.payload.message,
      }
    case PRODUCT_ADD_INIT: 
      return {
        ...state,
        fetchAddProductStatus: false,
        fetchAddProductCode: 0,
        fetchAddProductMessage: "",
      }
    case PRODUCT_ADD_FETCHING:
      return {
        ...state,
        isAddProductFetching: true
      };
    case PRODUCT_ADD_FETCHED:
      return {
        ...state,
        isAddProductFetching: false,
        addProductData: action.payload.payload,
        fetchAddProductStatus: action.payload.success,
        fetchAddProductCode: action.payload.code,
        fetchAddProductMessage: action.payload.message
      };
    case PRODUCT_ADD_FAIL:
      return {
        ...state,
        isAddProductFetching: false,
        fetchAddProductStatus: action.payload.success,
        fetchAddProductCode: action.payload.code,
        fetchAddProductMessage: action.payload.message
      };
    case PRODUCT_ADD_ERROR:
      return {
        ...state,
        isAddProductFetching: false,
        fetchAddProductMessage: action.payload.message
      };
    case PRODUCT_UPDATE:
      return {
        ...state,
        isFetching: false
      };
    case PRODUCT_DELETE:
      return {
        ...state,
        isFetching: false
      };
    case PRODUCT_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };
    case PRODUCT_DETAIL_FETCHING:
      return {
        ...state,
        isDetailProductFetching: true
      };
    case PRODUCT_DETAIL_FETCHED:
      return {
        ...state,
        isDetailProductFetching: false,
        dataDetailProducts: action.payload.payload,
        fetchDetailProductCode: action.payload.code,
        fetchDetailProductStatus: action.payload.success,
        fetchDetailProductMessage: action.payload.message
      };
    case PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        isDetailProductFetching: false,
        fetchDetailProductCode: action.payload.code,
        fetchDetailProductStatus: action.payload.success,
        fetchDetailProductMessage: action.payload.message
      };
    case PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isDetailProductFetching: false,
        fetchDetailProductMessage: action.payload.message
      };
    case PRODUCT_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateProductStatus: false,
        fetchUpdateProductCode: 0,
        fetchUpdateProductMessage: "",
      }
    case PRODUCT_UPDATE_FETCHING:
      return {
        ...state,
        isUpdateProductFetching: true
      };
    case PRODUCT_UPDATE_FETCHED:
      return {
        ...state,
        isUpdateProductFetching: false,
        updatedDetailProducts: action.payload.payload,
        dataDetailProducts: state.dataDetailProducts,
        fetchUpdateProductCode: action.payload.code,
        fetchUpdateProductStatus: action.payload.success,
        fetchUpdateProductMessage: action.payload.message
      };
    case PRODUCT_UPDATE_FAIL:
      return {
        ...state,
        isUpdateProductFetching: false,
        fetchUpdateProductCode: action.payload.code,
        fetchUpdateProductStatus: action.payload.success,
        fetchUpdateProductMessage: action.payload.message
      };
    case PRODUCT_UPDATE_ERROR:
      return {
        ...state,
        isUpdateProductFetching: false,
        fetchUpdateProductMessage: action.payload.message
      };
    case PRODUCT_VARIANT_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateVariantProductStatus: false,
        fetchUpdateVariantProductCode: 0,
        fetchUpdateVariantProductMessage: "",
      }
    case PRODUCT_VARIANT_UPDATE_FETCHING:
      return {
        ...state,
        isUpdateVariantProductFetching: true
      };
    case PRODUCT_VARIANT_UPDATE_FETCHED:
      return {
        ...state,
        isUpdateVariantProductFetching: false,
        dataDetailProducts: state.dataDetailProducts,
        updatedVariantDetailProducts: action.payload.payload,
        fetchUpdateVariantProductCode: action.payload.code,
        fetchUpdateVariantProductStatus: action.payload.success,
        fetchUpdateVariantProductMessage: action.payload.message
      };
    case PRODUCT_VARIANT_UPDATE_FAIL:
      return {
        ...state,
        isUpdateVariantProductFetching: false,
        fetchUpdateVariantProductCode: action.payload.code,
        fetchUpdateVariantProductStatus: action.payload.success,
        fetchUpdateVariantProductMessage: action.payload.message
      };
    case PRODUCT_VARIANT_UPDATE_ERROR:
      return {
        ...state,
        isUpdateVariantProductFetching: false,
        fetchUpdateVariantProductMessage: action.payload.message
      };
    default:
      break;
  }
  return state;
}

export default products;
