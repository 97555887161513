import moment from 'moment';

export const shiftParser = (dateStart, dateFinish) => {
  let shift_rule = [
    {
      shift: '07-12',
      start: 7,
      finish: 12
    },
    {
      shift: '12-17',
      start: 12,
      finish: 17
    },
    {
      shift: '17-22',
      start: 17,
      finish: 22
    },
  ]
  let timeStart = moment(dateStart).format('HH');
  let timeFinish = moment(dateFinish).format('HH');

  return shift_rule.filter(s => s.start < timeFinish && s.finish > timeStart).map(s => s.shift);
}

export const shiftNumParser = (dateStart, dateFinish) => {
  let shift_rule = [
    {
      shift: 1,
      start: 7,
      finish: 12
    },
    {
      shift: 2,
      start: 12,
      finish: 17
    },
    {
      shift: 3,
      start: 17,
      finish: 22
    },
  ]
  let timeStart = moment(dateStart).format('HH');
  let timeFinish = moment(dateFinish).format('HH');

  return shift_rule.filter(s => s.start < timeFinish && s.finish > timeStart).map(s => s.shift);
}
