import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from "./store";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const router = (
  <Provider store={store}>
    <Router basename="{process.env.PUBLIC_URL}" history={history}>
      <Route path="/" render={() => <App history={history} />} />
    </Router>
  </Provider>
);

ReactDOM.render(router, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
