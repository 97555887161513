import {
  PRODUCT_PROFILE_FECTHING,
  PRODUCT_PROFILE_FETCHED,
  PRODUCT_PROFILE_ADDING,
  PRODUCT_PROFILE_ADDED,
  PRODUCT_PROFILE_ADD_FAIL,
  PRODUCT_PROFILE_ADD_ERROR,
  PRODUCT_PROFILE_UPDATING,
  PRODUCT_PROFILE_UPDATED,
  PRODUCT_PROFILE_UPDATE_FAIL,
  PRODUCT_PROFILE_UPDATE_ERROR,
  PRODUCT_PROFILE_DELETING,
  PRODUCT_PROFILE_DELETED,
  PRODUCT_PROFILE_DELETE_FAIL,
  PRODUCT_PROFILE_DELETE_ERROR,
  PRODUCT_PROFILE_FAIL,
  PRODUCT_PROFILE_ERROR
} from "../actions/types";

const initialState = {
  dataProductProfiles: [],
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true,

  addProductProfiles: {},
  fetchAddStatus: false,
  fetchAddCode: 0,
  fetchAddMessage: "",
  isAddFetching: true,

  updateProductProfiles: {},
  fetchUpdateStatus: false,
  fetchUpdateCode: 0,
  fetchUpdateMessage: "",
  isUpdateFetching: true,

  deleteProductProfiles: {},
  fetchDeleteStatus: false,
  fetchDeleteCode: 0,
  fetchDeleteMessage: "",
  isDeleteFetching: true
};

function productprofiles(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_PROFILE_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case PRODUCT_PROFILE_FETCHED:
      const constructData = {
        productProfileData: action.payload.payload.data,
        productProfileDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isFetching: false,
        dataProductProfiles: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PRODUCT_PROFILE_ADDING:
      return {
        ...state,
        isAddFetching: true
      };
    case PRODUCT_PROFILE_ADDED:
      return {
        ...state,
        isAddFetching: false,
        addProductProfiles: action.payload,
        dataProductProfiles: state.dataProductProfiles,
        fetchAddCode: action.payload.code,
        fetchAddStatus: action.payload.success,
        fetchAddMessage: action.payload.message
      };
    case PRODUCT_PROFILE_ADD_FAIL:
      return {
        ...state,
        isAddFetching: false,
        fetchAddCode: action.payload.code,
        fetchAddStatus: action.payload.success,
        fetchAddMessage: action.payload.message
      };
    case PRODUCT_PROFILE_ADD_ERROR:
      return {
        ...state,
        isAddFetching: false,
        fetchAddMessage: action.payload.message
      };
    case PRODUCT_PROFILE_UPDATING:
      return {
        ...state,
        isUpdateFetching: true
      };
    case PRODUCT_PROFILE_UPDATED:
      return {
        ...state,
        isUpdateFetching: true,
        updateProductProfiles: action.payload,
        dataProductProfiles: state.dataProductProfiles,
        fetchUpdateStatus: action.payload.success,
        fetchUpdateCode: action.payload.code,
        fetchUpdateMessage: action.payload.message
      };
    case PRODUCT_PROFILE_UPDATE_FAIL:
      return {
        ...state,
        isUpdateFetching: true,
        fetchUpdateStatus: action.payload.success,
        fetchUpdateCode: action.payload.code,
        fetchUpdateMessage: action.payload.message
      };
    case PRODUCT_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        isUpdateFetching: true,
        fetchUpdateMessage: action.payload.message
      };
    case PRODUCT_PROFILE_DELETING:
      return {
        ...state,
        isDeleteFetching: true
      };
    case PRODUCT_PROFILE_DELETED:
      return {
        ...state,
        isDeleteFetching: false,
        deleteProductProfiles: action.payload,
        dataProductProfiles: state.dataProductProfiles,
        fetchDeleteCode: action.payload.code,
        fetchDeleteStatus: action.payload.status,
        fetchDeleteMessage: action.payload.message
      };
    case PRODUCT_PROFILE_DELETE_FAIL:
      return {
        ...state,
        isDeleteFetching: false,
        fetchDeleteCode: action.payload.code,
        fetchDeleteStatus: action.payload.status,
        fetchDeleteMessage: action.payload.message
      };
    case PRODUCT_PROFILE_DELETE_ERROR:
      return {
        ...state,
        isDeleteFetching: false,
        fetchDeleteMessage: action.payload.message
      };
    case PRODUCT_PROFILE_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PRODUCT_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };

    default:
      break;
  }
  return state;
}

export default productprofiles;
