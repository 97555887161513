import {
  VOUCHER_FETCHING,
  VOUCHER_FETCHED,
  VOUCHER_FAIL,
  VOUCHER_ERROR,
  VOUCHER_SEARCH_FETCHING,
  VOUCHER_SEARCH_FETCHED,
  VOUCHER_SEARCH_FAIL,
  VOUCHER_SEARCH_ERROR,
  VOUCHER_DETAIL_FETCHING,
  VOUCHER_DETAIL_FETCHED,
  VOUCHER_DETAIL_FAIL,
  VOUCHER_DETAIL_ERROR,
  VOUCHER_APPLIEDFOR_SELECTED_FETCHING,
  VOUCHER_APPLIEDFOR_SELECTED_FETCHED,
  VOUCHER_APPLIEDFOR_SELECTED_FAIL,
  VOUCHER_APPLIEDFOR_SELECTED_ERROR,
  VOUCHER_APPLIEDFOR_LIST_INIT,
  VOUCHER_APPLIEDFOR_LIST_FETCHING,
  VOUCHER_APPLIEDFOR_LIST_FETCHED,
  VOUCHER_APPLIEDFOR_LIST_FAIL,
  VOUCHER_APPLIEDFOR_LIST_ERROR,
  VOUCHER_CODE_DETAIL_FETCHING,
  VOUCHER_CODE_DETAIL_FETCHED,
  VOUCHER_CODE_DETAIL_FAIL,
  VOUCHER_CODE_DETAIL_ERROR,
  VOUCHER_ADD_INIT,
  VOUCHER_ADD_FETCHING,
  VOUCHER_ADD_FETCHED,
  VOUCHER_ADD_FAIL,
  VOUCHER_ADD_ERROR,
  VOUCHER_EDIT_INIT,
  VOUCHER_EDIT_FETCHING,
  VOUCHER_EDIT_FETCHED,
  VOUCHER_EDIT_FAIL,
  VOUCHER_EDIT_ERROR,
} from '../actions/types';

import { formatRupiah } from '../helper/kursRupiah';

const initialState = {
  dataVouchers: {},
  isVouchersFetching: true,
  fetchVouchersStatus: false,
  fetchVouchersCode: 0,
  fetchVouchersMessage: '',

  dataSearchVoucher: {},
  isVoucherSearching: true,
  fetchSearchVoucherStatus: false,
  fetchSearchVoucherCode: 0,
  fetchSearchVoucherMessage: '',

  dataVoucherDetail: {},
  isVoucherDetailFetching: true,
  fetchVoucherDetailStatus: false,
  fetchVoucherDetailCode: 0,
  fetchVoucherDetailMessage: '',

  dataAppliedForList: [],
  isAppliedForListFetching: false,
  fetchAppliedForListStatus: false,
  fetchAppliedForListCode: 0,
  fetchAppliedForListMessage: '',

  dataAddVoucher: {},
  isAddVoucherFetching: true,
  fetchAddVoucherStatus: false,
  fetchAddVoucherCode: 0,
  fetchAddVoucherMessage: '',

  dataAppliedForSelected: [],
  isAppliedForSelectedFetching: true,
  fetchAppliedForSelectedStatus: false,
  fetchAppliedForSelectedCode: 0,
  fetchAppliedForSelectedMessage: '',

  dataVoucherCodeDetail: {},
  isVoucherCodeDetailFetching: true,


  dataEditVoucher: {},
  isEditVoucherFetching: true,
  fetchEditVoucherStatus: false,
  fetchEditVoucherCode: 0,
  fetchEditVoucherMessage: '',
};

function vouchers(state = initialState, action) {
  switch (action.type) {
    case VOUCHER_FETCHING:
      return {
        ...state,
        isVouchersFetching: true,
      };
    case VOUCHER_FETCHED:
      const constructVoucherData = {
        voucherData:
          action.payload.payload.data.length > 0
            ? Object.assign([], action.payload.payload.data).map((item) => {
              item.discount_type = item.discount_type
                ? item.discount_type
                : item.discount_type === null &&
                  (item.voucher_type === 3 || item.voucher_type === 4)
                  ? {
                    discount_type: 'all',
                    discount_type_name: 'All',
                    voucher_id: item.id,
                  }
                  : {
                    discount_type: null,
                    discount_type_name: 'No Discount Type',
                    voucher_id: item.id,
                  };
              item.status = item.status === 1 ? 'Active' : 'Inactive';
              item.discount_amount =
                item.voucher_type === 1 || item.voucher_type === 3
                  ? `${item.discount_amount}%`
                  : `Rp. ${formatRupiah(item.discount_amount.toString())}`;
              return item;
            })
            : action.payload.payload.data,
        voucherDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isVouchersFetching: false,
        dataVouchers: constructVoucherData,
        fetchVouchersStatus: action.payload.success,
        fetchVouchersCode: action.payload.code,
        fetchVouchersMessage: action.payload.message,
      };
    case VOUCHER_FAIL:
      return {
        ...state,
        isVouchersFetching: false,
        fetchVouchersStatus: action.payload.success,
        fetchVouchersCode: action.payload.code,
        fetchVouchersMessage: action.payload.message,
      };
    case VOUCHER_ERROR:
      return {
        ...state,
        isVouchersFetching: false,
        fetchVouchersCode: action.payload.code,
        fetchVouchersMessage: action.payload.message,
      };
    case VOUCHER_SEARCH_FETCHING:
      return {
        ...state,
        isVoucherSearching: true,
      };
    case VOUCHER_SEARCH_FETCHED:
      const constructVoucherDataSearch = {
        voucherData:
          action.payload.payload.data.length > 0
            ? Object.assign([], action.payload.payload.data).map((item) => {
              item.discount_type = item.discount_type
                ? item.discount_type
                : item.discount_type === null &&
                  (item.voucher_type === 3 || item.voucher_type === 4)
                  ? {
                    discount_type: 'all',
                    discount_type_name: 'All',
                    voucher_id: item.id,
                  }
                  : {
                    discount_type: null,
                    discount_type_name: 'No Discount Type',
                    voucher_id: item.id,
                  };
              item.status = item.status === 1 ? 'Active' : 'Inactive';
              item.discount_amount =
                item.voucher_type === 1 || item.voucher_type === 3
                  ? `${item.discount_amount}%`
                  : `Rp. ${formatRupiah(item.discount_amount.toString())}`;
              return item;
            })
            : action.payload.payload.data,
        voucherDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isVoucherSearching: false,
        dataSearchVoucher: constructVoucherDataSearch,
        fetchSearchVoucherStatus: action.payload.success,
        fetchSearchVoucherCode: action.payload.code,
        fetchSearchVoucherMessage: action.payload.message,
      };
    case VOUCHER_SEARCH_FAIL:
      return {
        ...state,
        isVoucherSearching: false,
        fetchSearchVoucherStatus: action.payload.success,
        fetchSearchVoucherCode: action.payload.code,
        fetchSearchVoucherMessage: action.payload.message,
      };
    case VOUCHER_SEARCH_ERROR:
      return {
        ...state,
        isVoucherSearching: false,
        fetchSearchVoucherCode: action.payload.code,
        fetchSearchVoucherMessage: action.payload.message,
      };

    case VOUCHER_APPLIEDFOR_LIST_INIT:
      return {
        ...state,
        fetchAppliedForListStatus: false,
        fetchAppliedForListCode: 0,
        fetchAppliedForListMessage: '',
      };
    case VOUCHER_APPLIEDFOR_LIST_FETCHING:
      return {
        ...state,
        isAppliedForListFetching: true,
      };
    case VOUCHER_APPLIEDFOR_LIST_FETCHED:
      const constructAppliedForList = {
        appliedForListData: Object.assign(
          [],
          action.payload.payload.data
            ? [...action.payload.payload.data].map((item) => ({
              data: item,
              label: item.name,
              value: item.id,
            }))
            : [...action.payload.payload].map((item) => ({
              data: item,
              label: item.name,
              value: item.id,
            }))
        ),
        appliedForListDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        dataAppliedForList: constructAppliedForList,
        isAppliedForListFetching: false,
        fetchAppliedForListStatus: action.payload.success,
        fetchAppliedForListCode: action.payload.code,
        fetchAppliedForListMessage: action.payload.message,
      };
    case VOUCHER_APPLIEDFOR_LIST_FAIL:
      return {
        ...state,
        isAppliedForListFetching: false,
        fetchAppliedForListStatus: action.payload.success,
        fetchAppliedForListCode: action.payload.code,
        fetchAppliedForListMessage: action.payload.message,
      };
    case VOUCHER_APPLIEDFOR_LIST_ERROR:
      return {
        ...state,
        isAppliedForListFetching: false,
        fetchAppliedForListCode: action.payload.code,
        fetchAppliedForListMessage: action.payload.message,
      };

    case VOUCHER_CODE_DETAIL_FETCHING:
      return {
        ...state,
        isVoucherCodeDetailFetching: true,
      }
    case VOUCHER_CODE_DETAIL_FETCHED:
      const constructDataVoucherCodeDetail = {
        voucherCodeDetailLength: action.payload.payload.total,
        voucherCodeDetailData: action.payload.payload.data
      }
      return {
        ...state,
        isVoucherCodeDetailFetching: false,
        dataVoucherCodeDetail: constructDataVoucherCodeDetail,
        fetchVoucherCodeDetailCode: action.payload.code,
        fetchVoucherCodeDetailStatus: action.payload.success,
        fetchVoucherCodeDetailMessage: action.payload.message,
      }
    case VOUCHER_CODE_DETAIL_FAIL:
      return {
        ...state,
        isVoucherCodeDetailFetching: false,
        fetchVoucherCodeDetailCode: action.payload.code,
        fetchVoucherCodeDetailStatus: action.payload.success,
        fetchVoucherCodeDetailMessage: action.payload.message,
      }
    case VOUCHER_CODE_DETAIL_ERROR:
      return {
        ...state,
        isVoucherCodeDetailFetching: false,
        fetchVoucherCodeDetailCode: action.payload.code,
        fetchVoucherCodeDetailStatus: action.payload.success,
        fetchVoucherCodeDetailMessage: action.payload.message,
      }

    case VOUCHER_ADD_INIT:
      return {
        ...state,
        fetchAddVoucherStatus: false,
        fetchAddVoucherCode: 0,
        fetchAddVoucherMessage: '',
      };

    case VOUCHER_ADD_FETCHING:
      return {
        ...state,
        isAddVoucherFetching: true,
      };

    case VOUCHER_ADD_FETCHED:
      return {
        ...state,
        isAddVoucherFetching: false,
        dataAddVoucher: action.payload.payload,
        fetchAddVoucherStatus: action.payload.success,
        fetchAddVoucherCode: action.payload.code,
        fetchAddVoucherMessage: action.payload.message,
      };

    case VOUCHER_ADD_FAIL:
      return {
        ...state,
        isAddVoucherFetching: false,
        fetchAddVoucherStatus: action.payload.success,
        fetchAddVoucherCode: action.payload.code,
        fetchAddVoucherMessage: action.payload.message,
      };

    case VOUCHER_ADD_ERROR:
      return {
        ...state,
        isAddVoucherFetching: false,
        fetchAddVoucherCode: action.payload.code,
        fetchAddVoucherMessage: action.payload.message,
      };
    case VOUCHER_EDIT_INIT:
      return {
        ...state,
        fetchEditVoucherStatus: false,
        fetchEditVoucherCode: 0,
        fetchEditVoucherMessage: '',
      };

    case VOUCHER_EDIT_FETCHING:
      return {
        ...state,
        isEditVoucherFetching: true,
      };

    case VOUCHER_EDIT_FETCHED:
      return {
        ...state,
        isEditVoucherFetching: false,
        dataEditVoucher: action.payload.payload,
        fetchEditVoucherStatus: action.payload.success,
        fetchEditVoucherCode: action.payload.code,
        fetchEditVoucherMessage: action.payload.message,
      };

    case VOUCHER_EDIT_FAIL:
      return {
        ...state,
        isEditVoucherFetching: false,
        fetchEditVoucherStatus: action.payload.success,
        fetchEditVoucherCode: action.payload.code,
        fetchEditVoucherMessage: action.payload.message,
      };

    case VOUCHER_EDIT_ERROR:
      return {
        ...state,
        isEditVoucherFetching: false,
        fetchEditVoucherCode: action.payload.code,
        fetchEditVoucherMessage: action.payload.message,
      };

    case VOUCHER_DETAIL_FETCHING:
      return {
        ...state,
        isVoucherDetailFetching: true,
      };
    case VOUCHER_DETAIL_FETCHED:
      const constructVoucherDetailData = Object.fromEntries(
        Object.entries(Object.assign({}, action.payload.payload))
          .map(([key, val]) => {
            if (key === "discount_type" && action.payload.payload.discount_type === null) {
              return [key, val = Object.assign({}, { discount_type: 0, voucher_id: action.payload.payload.id, discount_type_name: "No Discount Type" })]
            }
            if (key === "max_discount_amount" && action.payload.payload.with_max_discount === 0) {
              return [key, val = action.payload.payload.with_max_discount === 0 ? '' : action.payload.payload.max_discount_amount];
            }
            if (key === "with_max_discount") {
              return [key, val = action.payload.payload.with_max_discount === 0 ? false : true]
            }
            return [key, val];
          })
      );
      return {
        ...state,
        isVoucherDetailFetching: false,
        dataVoucherDetail: constructVoucherDetailData,
        fetchVoucherDetailStatus: action.payload.success,
        fetchVoucherDetailCode: action.payload.code,
        fetchVoucherDetailMessage: action.payload.message,
      };
    case VOUCHER_DETAIL_FAIL:
      return {
        ...state,
        isVoucherDetailFetching: false,
        fetchVoucherDetailStatus: action.payload.success,
        fetchVoucherDetailCode: action.payload.code,
        fetchVoucherDetailMessage: action.payload.message,
      };
    case VOUCHER_DETAIL_ERROR:
      return {
        ...state,
        isVoucherDetailFetching: false,
        fetchVoucherDetailCode: action.payload.code,
        fetchVoucherDetailMessage: action.payload.message,
      };
    case VOUCHER_APPLIEDFOR_SELECTED_FETCHING:
      return {
        ...state,
        isAppliedForSelectedFetching: true
      }
    case VOUCHER_APPLIEDFOR_SELECTED_FETCHED:
      const constructDataSelectedAppliedFor = {
        appliedForSelectedLength: action.payload.payload.total,
        appliedForSelectedData: Object.assign([], action.payload.payload.data.map((item, index) => ({
          label: item.name,
          value: item.reff_id,
          data: item
        })))
      };
      return {
        ...state,
        isAppliedForSelectedFetching: false,
        dataAppliedForSelected: constructDataSelectedAppliedFor,
        fetchAppliedForSelectedStatus: action.payload.success,
        fetchAppliedForSelectedCode: action.payload.code,
        fetchAppliedForSelectedMessage: action.payload.message,
      }
    case VOUCHER_APPLIEDFOR_SELECTED_FAIL:
      return {
        ...state,
        isAppliedForSelectedFetching: false,
        fetchAppliedForSelectedStatus: action.payload.success,
        fetchAppliedForSelectedCode: action.payload.code,
        fetchAppliedForSelectedMessage: action.payload.message,
      }
    case VOUCHER_APPLIEDFOR_SELECTED_ERROR:
      return {
        ...state,
        isAppliedForSelectedFetching: false,
        fetchAppliedForSelectedStatus: action.payload.success,
        fetchAppliedForSelectedCode: action.payload.code,
        fetchAppliedForSelectedMessage: action.payload.message,
      }
    default:
      return state;
  }
}

export default vouchers;
