/* USER LOGIN ACTION TYPE */
export const USER_LOGIN_START = 'USER_LOGIN_START';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_CHECK = 'USER_LOGIN_CHECK';
export const USER_LOGOUT = 'USER_LOGOUT';

/* DASHBOARD ACTION TYPE */
export const CHART_DATA_FETCHING = 'CHART_DATA_FETCHING';
export const CHART_DATA_FETCHED = 'CHART_DATA_FETCHED';
export const CHART_FETCH_FAIL = 'CHART_FETCH_FAIL';
export const CHART_FETCH_ERROR = 'CHART_FETCH_ERROR';

/* TRANS ORDER ACTION TYPE */
export const TRANS_ORDER_FECTHING = 'TRANS_ORDER_FECTHING';
export const TRANS_ORDER_FETCHED = 'TRANS_ORDER_FETCHED';
export const TRANS_ORDER_FAIL = 'TRANS_ORDER_FAIL';
export const TRANS_ORDER_ERROR = 'TRANS_ORDER_ERROR';
export const TRANS_ORDER_DETAIL_INIT = 'TRANS_ORDER_DETAIL_INIT';
export const TRANS_ORDER_DETAIL_FECTHING = 'TRANS_ORDER_DETAIL_FECTHING';
export const TRANS_ORDER_DETAIL_FETCHED = 'TRANS_ORDER_DETAIL_FETCHED';
export const TRANS_ORDER_DETAIL_FAIL = 'TRANS_ORDER_DETAIL_FAIL';
export const TRANS_ORDER_DETAIL_ERROR = 'TRANS_ORDER_DETAIL_ERROR';
export const TRANS_ORDER_PICKUP_INIT = 'TRANS_ORDER_PICKUP_INIT';
export const TRANS_ORDER_PICKUP_PROCESSING = 'TRANS_ORDER_PICKUP_PROCESSING';
export const TRANS_ORDER_PICKUP_PROCESSED = 'TRANS_ORDER_PICKUP_PROCESSED';
export const TRANS_ORDER_PICKUP_FAIL = 'TRANS_ORDER_PICKUP_FAIL';
export const TRANS_ORDER_PICKUP_ERROR = 'TRANS_ORDER_PICKUP_ERROR';
export const ORDER_TRACKING_DETAIL_INIT = 'ORDER_TRACKING_DETAIL_INIT';
export const ORDER_TRACKING_DETAIL_FECTHING = 'ORDER_TRACKING_DETAIL_FECTHING';
export const ORDER_TRACKING_DETAIL_FETCHED = 'ORDER_TRACKING_DETAIL_FETCHED';
export const ORDER_TRACKING_DETAIL_FAIL = 'ORDER_TRACKING_DETAIL_FAIL';
export const ORDER_TRACKING_DETAIL_ERROR = 'ORDER_TRACKING_DETAIL_ERROR';
export const ORDER_SEARCH_FETCHING = 'ORDER_SEARCH_FETCHING';
export const ORDER_SEARCH_FETCHED = 'ORDER_SEARCH_FETCHED';
export const ORDER_SEARCH_FAIL = 'ORDER_SEARCH_FAIL';
export const ORDER_SEARCH_ERROR = 'ORDER_SEARCH_ERROR';

/* TRANS_REPORT ACTION TYPE */
export const TRANS_REPORT_FECTHING = 'TRANS_REPORT_FECTHING';
export const TRANS_REPORT_FETCHED = 'TRANS_REPORT_FETCHED';
export const TRANS_REPORT_FAIL = 'TRANS_REPORT_FAIL';
export const TRANS_REPORT_ERROR = 'TRANS_REPORT_ERROR';

export const TRANS_REPORT_EXPORTING = 'TRANS_REPORT_EXPORTING';
export const TRANS_REPORT_EXPORTED = 'TRANS_REPORT_EXPORTED';
export const TRANS_REPORT_EXPORT_FAIL = 'TRANS_REPORT_EXPORT_FAIL';
export const TRANS_REPORT_EXPORT_ERROR = 'TRANS_REPORT_EXPORT_ERROR';

/* TRANS_ORDER_VIRTUAL ACTION TYPE */
export const TRANSORDER_VIRTUAL_FECTHING = 'TRANSORDER_VIRTUAL_FECTHING';
export const TRANSORDER_VIRTUAL_FETCHED = 'TRANSORDER_VIRTUAL_FETCHED';
export const TRANSORDER_VIRTUAL_FAIL = 'TRANSORDER_VIRTUAL_FAIL';
export const TRANSORDER_VIRTUAL_ERROR = 'TRANSORDER_VIRTUAL_ERROR';
export const TRANSORDER_VIRTUAL_DETAIL_FECTHING =
  'TRANSORDER_VIRTUAL_DETAIL_FECTHING';
export const TRANSORDER_VIRTUAL_DETAIL_FETCHED =
  'TRANSORDER_VIRTUAL_DETAIL_FETCHED';
export const TRANSORDER_VIRTUAL_DETAIL_FAIL = 'TRANSORDER_VIRTUAL_DETAIL_FAIL';
export const TRANSORDER_VIRTUAL_DETAIL_ERROR =
  'TRANSORDER_VIRTUAL_DETAIL_ERROR';
export const ORDERVIRTUAL_SEARCH_FETCHING = 'ORDERVIRTUAL_SEARCH_FETCHING';
export const ORDERVIRTUAL_SEARCH_FETCHED = 'ORDERVIRTUAL_SEARCH_FETCHED';
export const ORDERVIRTUAL_SEARCH_FAIL = 'ORDERVIRTUAL_SEARCH_FAIL';
export const ORDERVIRTUAL_SEARCH_ERROR = 'ORDERVIRTUAL_SEARCH_ERROR';

/* TRANS_REPORT_VIRTUAL ACTION TYPE */
export const TRANSREPORT_VIRTUAL_FECTHING = 'TRANSREPORT_VIRTUAL_FECTHING';
export const TRANSREPORT_VIRTUAL_FETCHED = 'TRANSREPORT_VIRTUAL_FETCHED';
export const TRANSREPORT_VIRTUAL_FAIL = 'TRANSREPORT_VIRTUAL_FAIL';
export const TRANSREPORT_VIRTUAL_ERROR = 'TRANSREPORT_VIRTUAL_ERROR';

export const TRANSREPORT_VIRTUAL_EXPORTING = 'TRANSREPORT_VIRTUAL_EXPORTING';
export const TRANSREPORT_VIRTUAL_EXPORTED = 'TRANSREPORT_VIRTUAL_EXPORTED';
export const TRANSREPORT_VIRTUAL_EXPORT_FAIL =
  'TRANSREPORT_VIRTUAL_EXPORT_FAIL';
export const TRANSREPORT_VIRTUAL_EXPORT_ERROR =
  'TRANSREPORT_VIRTUAL_EXPORT_ERROR';

/* Consumed by Trans Order,Trans Report & Banner Filter */
export const CLIENT_LIST_FECTHING = 'CLIENT_LIST_FECTHING';
export const CLIENT_LIST_FETCHED = 'CLIENT_LIST_FETCHED';
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL';
export const CLIENT_LIST_ERROR = 'CLIENT_LIST_ERROR';

/* Consumed by Live Schedule Filter & Banner Add and Edit */
export const CLIENTLOC_LIST_FECTHING = 'CLIENTLOC_LIST_FECTHING';
export const CLIENTLOC_LIST_FETCHED = 'CLIENTLOC_LIST_FETCHED';
export const CLIENTLOC_LIST_FAIL = 'CLIENTLOC_LIST_FAIL';
export const CLIENTLOC_LIST_ERROR = 'CLIENTLOC_LIST_ERROR';

/* Consumend by Banner Calendar Filter */
export const CLIENT_PLACEMENT_LIST_FECTHING = "CLIENT_PLACEMENT_LIST_FECTHING";
export const CLIENT_PLACEMENT_LIST_FETCHED = "CLIENT_PLACEMENT_LIST_FETCHED";
export const CLIENT_PLACEMENT_LIST_FAIL = "CLIENT_PLACEMENT_LIST_FAIL";
export const CLIENT_PLACEMENT_LIST_ERROR = "CLIENT_PLACEMENT_LIST_ERROR";
export const CLIENT_PLACEMENTBRANCH_LIST_FECTHING = "CLIENT_PLACEMENTBRANCH_LIST_FECTHING";
export const CLIENT_PLACEMENTBRANCH_LIST_FETCHED = "CLIENT_PLACEMENTBRANCH_LIST_FETCHED";
export const CLIENT_PLACEMENTBRANCH_LIST_FAIL = "CLIENT_PLACEMENTBRANCH_LIST_FAIL";
export const CLIENT_PLACEMENTBRANCH_LIST_ERROR = "CLIENT_PLACEMENTBRANCH_LIST_ERROR";
export const FILTER_BANNER_SLOT_FETCHING = "FILTER_BANNER_SLOT_FETCHING";
export const FILTER_BANNER_SLOT_FETCHED = "FILTER_BANNER_SLOT_FETCHED";
export const FILTER_BANNER_SLOT_FAIL = "FILTER_BANNER_SLOT_FAIL";
export const FILTER_BANNER_SLOT_ERROR = "FILTER_BANNER_SLOT_ERROR";

/* Consumed by Product Add and Edit */
export const CLIENTPRODUCT_LIST_FECTHING = 'CLIENTPRODUCT_LIST_FECTHING';
export const CLIENTPRODUCT_LIST_FETCHED = 'CLIENTPRODUCT_LIST_FETCHED';
export const CLIENTPRODUCT_LIST_FAIL = 'CLIENTPRODUCT_LIST_FAIL';
export const CLIENTPRODUCT_LIST_ERROR = 'CLIENTPRODUCT_LIST_ERROR';

/* PRODUCT ACTION TYPE */
export const PRODUCT_FECTHING = 'PRODUCT_FECTHING';
export const PRODUCT_FETCHED = 'PRODUCT_FETCHED';
export const PRODUCT_ADD_INIT = 'PRODUCT_ADD_INIT';
export const PRODUCT_ADD_FETCHING = 'PRODUCT_ADD_FETCHING';
export const PRODUCT_ADD_FETCHED = 'PRODUCT_ADD_FETCHED';
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL';
export const PRODUCT_ADD_ERROR = 'PRODUCT_ADD_ERROR';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_FAIL = 'PRODUCT_FAIL';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

/* PRODUCT SEARCH TYPE */
export const PRODUCT_SEARCH_FETCHING = 'PRODUCT_SEARCH_FETCHING';
export const PRODUCT_SEARCH_FETCHED = 'PRODUCT_SEARCH_FETCHED';
export const PRODUCT_SEARCH_FAIL = 'PRODUCT_SEARCH_FAIL';
export const PRODUCT_SEARCH_ERROR = 'PRODUCT_SEARCH_ERROR';

/* PRODUCT DETAIL TYPE*/
export const PRODUCT_DETAIL_FETCHING = 'PRODUCT_DETAIL_FETCHING';
export const PRODUCT_DETAIL_FETCHED = 'PRODUCT_DETAIL_FETCHED';
export const PRODUCT_DETAIL_FAIL = 'PRODUCT_DETAIL_FAIL';
export const PRODUCT_DETAIL_ERROR = 'PRODUCT_DETAIL_ERROR';

/* PRODUCT UPDATE TYPE*/
export const PRODUCT_UPDATE_INIT = 'PRODUCT_UPDATE_INIT';
export const PRODUCT_UPDATE_FETCHING = 'PRODUCT_UPDATE_FETCHING';
export const PRODUCT_UPDATE_FETCHED = 'PRODUCT_UPDATE_FETCHED';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR';

/* PRODUCT MANAGE TYPE*/
export const PRODUCT_VARIANT_UPDATE_INIT = 'PRODUCT_VARIANT_UPDATE_INIT';
export const PRODUCT_VARIANT_UPDATE_FETCHING =
  'PRODUCT_VARIANT_UPDATE_FETCHING';
export const PRODUCT_VARIANT_UPDATE_FETCHED = 'PRODUCT_VARIANT_UPDATE_FETCHED';
export const PRODUCT_VARIANT_UPDATE_FAIL = 'PRODUCT_VARIANT_UPDATE_FAIL';
export const PRODUCT_VARIANT_UPDATE_ERROR = 'PRODUCT_VARIANT_UPDATE_ERROR';

/* PRODUCT VIRTUAL TYPE */
export const PRODUCT_VIRTUAL_FECTHING = 'PRODUCT_VIRTUAL_FECTHING';
export const PRODUCT_VIRTUAL_FETCHED = 'PRODUCT_VIRTUAL_FETCHED';
export const PRODUCT_VIRTUAL_FAIL = 'PRODUCT_VIRTUAL_FAIL';
export const PRODUCT_VIRTUAL_ERROR = 'PRODUCT_VIRTUAL_ERROR';

export const PRODUCTVIRTUAL_SEARCH_FETCHING = 'PRODUCTVIRTUAL_SEARCH_FETCHING';
export const PRODUCTVIRTUAL_SEARCH_FETCHED = 'PRODUCTVIRTUAL_SEARCH_FETCHED';
export const PRODUCTVIRTUAL_SEARCH_FAIL = 'PRODUCTVIRTUAL_SEARCH_FAIL';
export const PRODUCTVIRTUAL_SEARCH_ERROR = 'PRODUCTVIRTUAL_SEARCH_ERROR';

export const PRODUCT_VIRTUAL_ADD_INIT = 'PRODUCT_VIRTUAL_ADD_INIT';
export const PRODUCT_VIRTUAL_ADD_FETCHING = 'PRODUCT_VIRTUAL_ADD_FETCHING';
export const PRODUCT_VIRTUAL_ADD_FETCHED = 'PRODUCT_VIRTUAL_ADD_FETCHED';
export const PRODUCT_VIRTUAL_ADD_FAIL = 'PRODUCT_VIRTUAL_ADD_FAIL';
export const PRODUCT_VIRTUAL_ADD_ERROR = 'PRODUCT_VIRTUAL_ADD_ERROR';
export const PRODUCTVIRTUAL_DETAIL_FETCHING = 'PRODUCTVIRTUAL_DETAIL_FETCHING';
export const PRODUCTVIRTUAL_DETAIL_FETCHED = 'PRODUCTVIRTUAL_DETAIL_FETCHED';
export const PRODUCTVIRTUAL_DETAIL_FAIL = 'PRODUCTVIRTUAL_DETAIL_FAIL';
export const PRODUCTVIRTUAL_DETAIL_ERROR = 'PRODUCTVIRTUAL_DETAIL_ERROR';

export const PRODUCTVIRTUAL_UPDATE_INIT = 'PRODUCTVIRTUAL_UPDATE_INIT';
export const PRODUCTVIRTUAL_UPDATE_FETCHING = 'PRODUCTVIRTUAL_UPDATE_FETCHING';
export const PRODUCTVIRTUAL_UPDATE_FETCHED = 'PRODUCTVIRTUAL_UPDATE_FETCHED';
export const PRODUCTVIRTUAL_UPDATE_FAIL = 'PRODUCTVIRTUAL_UPDATE_FAIL';
export const PRODUCTVIRTUAL_UPDATE_ERROR = 'PRODUCTVIRTUAL_UPDATE_ERROR';

export const PRODUCTVIRTUAL_VOUCHERADD_INIT = 'PRODUCTVIRTUAL_VOUCHERADD_INIT';
export const PRODUCTVIRTUAL_VOUCHERADDING = 'PRODUCTVIRTUAL_VOUCHERADDING';
export const PRODUCTVIRTUAL_VOUCHERADDED = 'PRODUCTVIRTUAL_VOUCHERADDED';
export const PRODUCTVIRTUAL_VOUCHERADD_FAIL = 'PRODUCTVIRTUAL_VOUCHERADD_FAIL';
export const PRODUCTVIRTUAL_VOUCHERADD_ERROR =
  'PRODUCTVIRTUAL_VOUCHERADD_ERROR';

export const PRODUCTVIRTUAL_VOUCHERUPDATE_INIT =
  'PRODUCTVIRTUAL_VOUCHERUPDATE_INIT';
export const PRODUCTVIRTUAL_VOUCHERUPDATING = 'PRODUCTVIRTUAL_VOUCHERUPDATING';
export const PRODUCTVIRTUAL_VOUCHERUPDATED = 'PRODUCTVIRTUAL_VOUCHERUPDATED';
export const PRODUCTVIRTUAL_VOUCHERUPDATE_FAIL =
  'PRODUCTVIRTUAL_VOUCHERUPDATE_FAIL';
export const PRODUCTVIRTUAL_VOUCHERUPDATE_ERROR =
  'PRODUCTVIRTUAL_VOUCHERUPDATE_ERROR';

export const PRODUCTVIRTUAL_VOUCHERDELETE_INIT =
  'PRODUCTVIRTUAL_VOUCHERDELETE_INIT';
export const PRODUCTVIRTUAL_VOUCHERDELETING = 'PRODUCTVIRTUAL_VOUCHERDELETING';
export const PRODUCTVIRTUAL_VOUCHERDELETED = 'PRODUCTVIRTUAL_VOUCHERDELETED';
export const PRODUCTVIRTUAL_VOUCHERDELETE_FAIL =
  'PRODUCTVIRTUAL_VOUCHERDELETE_FAIL';
export const PRODUCTVIRTUAL_VOUCHERDELETE_ERROR =
  'PRODUCTVIRTUAL_VOUCHERDELETE_ERROR';

export const PRODUCT_VIRTUAL_DELETE_INIT = 'PRODUCT_VIRTUAL_DELETE_INIT';
export const PRODUCT_VIRTUAL_DELETING = 'PRODUCT_VIRTUAL_DELETING';
export const PRODUCT_VIRTUAL_DELETED = 'PRODUCT_VIRTUAL_DELETED';
export const PRODUCT_VIRTUAL_DELETE_FAIL = 'PRODUCT_VIRTUAL_DELETE_FAIL';
export const PRODUCT_VIRTUAL_DELETE_ERROR = 'PRODUCT_VIRTUAL_DELETE_ERROR';

/* LIVE PRODUCT ACTION TYPE */
export const LIVE_PRODUCT_FECTHING = 'LIVE_PRODUCT_FECTHING';
export const LIVE_PRODUCT_FETCHED = 'LIVE_PRODUCT_FETCHED';
export const LIVEPRODUCT_BYDATE_FECTHING = 'LIVEPRODUCT_BYDATE_FECTHING';
export const LIVEPRODUCT_BYDATE_FETCHED = 'LIVEPRODUCT_BYDATE_FETCHED';
export const LIVEPRODUCT_BYDATE_FAIL = 'LIVEPRODUCT_BYDATE_FAIL';
export const LIVEPRODUCT_BYDATE_ERROR = 'LIVEPRODUCT_BYDATE_ERROR';
export const LIVEPRODUCT_BYDATEID_FECTHING = 'LIVEPRODUCT_BYDATEID_FECTHING';
export const LIVEPRODUCT_BYDATEID_FETCHED = 'LIVEPRODUCT_BYDATEID_FETCHED';
export const LIVEPRODUCT_BYDATEID_FAIL = 'LIVEPRODUCT_BYDATEID_FAIL';
export const LIVEPRODUCT_BYDATEID_ERROR = 'LIVEPRODUCT_BYDATEID_ERROR';
export const LIVEPRODUCT_ADD_INIT = 'LIVEPRODUCT_ADD_INIT';
export const LIVE_PRODUCT_ADDING = 'LIVE_PRODUCT_ADDING';
export const LIVE_PRODUCT_ADDED = 'LIVE_PRODUCT_ADDED';
export const LIVE_PRODUCT_ADD_FAIL = 'LIVE_PRODUCT_ADD_FAIL';
export const LIVE_PRODUCT_ADD_ERROR = 'LIVE_PRODUCT_ADD_ERROR';
export const LIVE_PRODUCT_UPDATING = 'LIVE_PRODUCT_UPDATING';
export const LIVE_PRODUCT_UPDATED = 'LIVE_PRODUCT_UPDATED';
export const LIVE_PRODUCT_UPDATE_FAIL = 'LIVE_PRODUCT_UPDATE_FAIL';
export const LIVE_PRODUCT_UPDATE_ERROR = 'LIVE_PRODUCT_UPDATE_ERROR';
export const LIVE_PRODUCT_DELETING = 'LIVE_PRODUCT_DELETING';
export const LIVE_PRODUCT_DELETED = 'LIVE_PRODUCT_DELETED';
export const LIVE_PRODUCT_DELETE_FAIL = 'LIVE_PRODUCT_DELETE_FAIL';
export const LIVE_PRODUCT_DELETE_ERROR = 'LIVE_PRODUCT_DELETE_ERROR';

export const LIVEPERIODIC_ADD_INIT = 'LIVEPERIODIC_ADD_INIT';
export const LIVE_PERIODIC_ADDING = 'LIVE_PERIODIC_ADDING';
export const LIVE_PERIODIC_ADDED = 'LIVE_PERIODIC_ADDED';
export const LIVE_PERIODIC_ADD_FAIL = 'LIVE_PERIODIC_ADD_FAIL';
export const LIVE_PERIODIC_ADD_ERROR = 'LIVE_PERIODIC_ADD_ERROR';

export const LIVEPERIODIC_EDIT_INIT = 'LIVEPERIODIC_EDIT_INIT';
export const LIVE_PERIODIC_EDITING = 'LIVE_PERIODIC_EDITING';
export const LIVE_PERIODIC_EDITED = 'LIVE_PERIODIC_EDITED';
export const LIVE_PERIODIC_EDIT_FAIL = 'LIVE_PERIODIC_EDIT_FAIL';
export const LIVE_PERIODIC_EDIT_ERROR = 'LIVE_PERIODIC_EDIT_ERROR';

export const LIVE_PRODUCT_FAIL = 'LIVE_PRODUCT_FAIL';
export const LIVE_PRODUCT_ERROR = 'LIVE_PRODUCT_ERROR';

/* DAV SENTIL ACTION TYPE */
export const DAVTOUCH_VIDEOS_FETCHING = 'DAVTOUCH_VIDEOS_FETCHING';
export const DAVTOUCH_VIDEOS_FETCHED = 'DAVTOUCH_VIDEOS_FETCHED';
export const DAVTOUCH_VIDEOS_FAIL = 'DAVTOUCH_VIDEOS_FAIL';
export const DAVTOUCH_VIDEOS_ERROR = 'DAVTOUCH_VIDEOS_ERROR';
export const DAVTOUCH_ADD_VIDEOS_INIT = 'DAVTOUCH_ADD_VIDEOS_INIT';
export const DAVTOUCH_ADD_VIDEOS_FETCHING = 'DAVTOUCH_ADD_VIDEOS_FETCHING';
export const DAVTOUCH_ADD_VIDEOS_FETCHED = 'DAVTOUCH_ADD_VIDEOS_FETCHED';
export const DAVTOUCH_ADD_VIDEOS_FAIL = 'DAVTOUCH_ADD_VIDEOS_FAIL';
export const DAVTOUCH_ADD_VIDEOS_ERROR = 'DAVTOUCH_ADD_VIDEOS_ERROR';
export const DAVTOUCH_GET_VIDEO_INIT = 'DAVTOUCH_GET_VIDEO_INIT';
export const DAVTOUCH_GET_VIDEO_FETCHING = 'DAVTOUCH_GET_VIDEO_FETCHING';
export const DAVTOUCH_GET_VIDEO_FETCHED = 'DAVTOUCH_GET_VIDEO_FETCHED';
export const DAVTOUCH_GET_VIDEO_FAIL = 'DAVTOUCH_GET_VIDEO_FAIL';
export const DAVTOUCH_GET_VIDEO_ERROR = 'DAVTOUCH_GET_VIDEO_ERROR';
export const DAVTOUCH_EDIT_VIDEOS_INIT = 'DAVTOUCH_EDIT_VIDEOS_INIT';
export const DAVTOUCH_EDIT_VIDEOS_FETCHING = 'DAVTOUCH_EDIT_VIDEOS_FETCHING';
export const DAVTOUCH_EDIT_VIDEOS_FETCHED = 'DAVTOUCH_EDIT_VIDEOS_FETCHED';
export const DAVTOUCH_EDIT_VIDEOS_FAIL = 'DAVTOUCH_EDIT_VIDEOS_FAIL';
export const DAVTOUCH_EDIT_VIDEOS_ERROR = 'DAVTOUCH_EDIT_VIDEOS_ERROR';
export const DAVTOUCH_STATISTIC_VIDEOS_INIT = 'DAVTOUCH_STATISTIC_VIDEOS_INIT';
export const DAVTOUCH_STATISTIC_VIDEOS_FETCHING = 'DAVTOUCH_STATISTIC_VIDEOS_FETCHING';
export const DAVTOUCH_STATISTIC_VIDEOS_FETCHED = 'DAVTOUCH_STATISTIC_VIDEOS_FETCHED';
export const DAVTOUCH_STATISTIC_VIDEOS_FAIL = 'DAVTOUCH_STATISTIC_VIDEOS_FAIL';
export const DAVTOUCH_STATISTIC_VIDEOS_ERROR = 'DAVTOUCH_STATISTIC_VIDEOS_ERROR';
export const DAVTOUCH_ADD_VOUCHERS_INIT = 'DAVTOUCH_ADD_VOUCHERS_INIT';
export const DAVTOUCH_ADD_VOUCHERS_FETCHING = 'DAVTOUCH_ADD_VOUCHERS_FETCHING';
export const DAVTOUCH_ADD_VOUCHERS_FETCHED = 'DAVTOUCH_ADD_VOUCHERS_FETCHED';
export const DAVTOUCH_ADD_VOUCHERS_FAIL = 'DAVTOUCH_ADD_VOUCHERS_FAIL';
export const DAVTOUCH_ADD_VOUCHERS_ERROR = 'DAVTOUCH_ADD_VOUCHERS_ERROR';
export const DAVTOUCH_VOUCHERS_FETCHING = 'DAVTOUCH_VOUCHERS_FETCHING';
export const DAVTOUCH_VOUCHERS_FETCHED = 'DAVTOUCH_VOUCHERS_FETCHED';
export const DAVTOUCH_VOUCHERS_FAIL = 'DAVTOUCH_VOUCHERS_FAIL';
export const DAVTOUCH_VOUCHERS_ERROR = 'DAVTOUCH_VOUCHERS_ERROR';
export const DAVTOUCH_DELETE_VOUCHERS_INIT = 'DAVTOUCH_DELETE_VOUCHERS_INIT';
export const DAVTOUCH_DELETE_VOUCHERS_FETCHING = 'DAVTOUCH_DELETE_VOUCHERS_FETCHING';
export const DAVTOUCH_DELETE_VOUCHERS_FETCHED = 'DAVTOUCH_DELETE_VOUCHERS_FETCHED';
export const DAVTOUCH_DELETE_VOUCHERS_FAIL = 'DAVTOUCH_DELETE_VOUCHERS_FAIL';
export const DAVTOUCH_DELETE_VOUCHERS_ERROR = 'DAVTOUCH_DELETE_VOUCHERS_ERROR';

/* CLIENT ACTION TYPE */
export const CLIENT_SEARCH_FETCHING = 'CLIENT_SEARCH_FETCHING';
export const CLIENT_SEARCH_FETCHED = 'CLIENT_SEARCH_FETCHED';
export const CLIENT_SEARCH_FAIL = 'CLIENT_SEARCH_FAIL';
export const CLIENT_SEARCH_ERROR = 'CLIENT_SEARCH_ERROR';
export const CLIENT_FECTHING = 'CLIENT_FECTHING';
export const CLIENT_FETCHED = 'CLIENT_FETCHED';
export const CLIENT_ADD_INIT = 'CLIENT_ADD_INIT';
export const CLIENT_ADDING = 'CLIENT_ADDING';
export const CLIENT_ADDED = 'CLIENT_ADDED';
export const CLIENT_ADD_FAIL = 'CLIENT_ADD_FAIL';
export const CLIENT_ADD_ERROR = 'CLIENT_ADD_ERROR';
export const CLIENT_UPDATE_INIT = 'CLIENT_UPDATE_INIT';
export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_DELETING = 'CLIENT_DELETING';
export const CLIENT_DELETED = 'CLIENT_DELETED';
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL';
export const CLIENT_DELETE_ERROR = 'CLIENT_DELETE_ERROR';
export const CLIENT_FAIL = 'CLIENT_FAIL';
export const CLIENT_ERROR = 'CLIENT_ERROR';

/* Submenu of Client Content */
/* a. Client Product */
/* 1.Client Product List */
export const CLIENT_PRODUCT_FECTHING = 'CLIENT_PRODUCT_FECTHING';
export const CLIENT_PRODUCT_FETCHED = 'CLIENT_PRODUCT_FETCHED';
export const CLIENT_PRODUCT_ADDING_PROFILE = 'CLIENT_PRODUCT_ADDING_PROFILE';
export const CLIENT_PRODUCT_ADDED_PROFILE = 'CLIENT_PRODUCT_ADDED_PROFILE';
export const CLIENT_PRODUCT_DELETING_PROFILE =
  'CLIENT_PRODUCT_DELETING_PROFILE';
export const CLIENT_PRODUCT_DELETED_PROFILE = 'CLIENT_PRODUCT_DELETED_PROFILE';
export const CLIENT_PRODUCT_UPDATING = 'CLIENT_PRODUCT_UPDATING';
export const CLIENT_PRODUCT_UPDATED = 'CLIENT_PRODUCT_UPDATED';
export const CLIENT_PRODUCT_DELETING = 'CLIENT_PRODUCT_DELETING';
export const CLIENT_PRODUCT_DELETED = 'CLIENT_PRODUCT_DELETED';
export const CLIENT_PRODUCT_FAIL = 'CLIENT_PRODUCT_FAIL';
export const CLIENT_PRODUCT_ERROR = 'CLIENT_PRODUCT_ERROR';

/* 2.Client Product Variant */
export const CLIENT_VARIANT_FETCHING = 'CLIENT_VARIANT_FETCHING';
export const CLIENT_VARIANT_FETCHED = 'CLIENT_VARIANT_FETCHED';
export const CLIENT_VARIANT_FAIL = 'CLIENT_VARIANT_FAIL';
export const CLIENT_VARIANT_ERROR = 'CLIENT_VARIANT_ERROR';

/* b.Client User */
export const CLIENT_USER_FECTHING = 'CLIENT_USER_FECTHING';
export const CLIENT_USER_FETCHED = 'CLIENT_USER_FETCHED';
export const CLIENT_USER_FAIL = 'CLIENT_USER_FAIL';
export const CLIENT_USER_ERROR = 'CLIENT_USER_ERROR';
export const CLIENTUSER_ADD_INIT = 'CLIENTUSER_ADD_INIT';
export const CLIENTUSER_ADD_FECTHING = 'CLIENTUSER_ADD_FECTHING';
export const CLIENTUSER_ADD_FETCHED = 'CLIENTUSER_ADD_FETCHED';
export const CLIENTUSER_ADD_FAIL = 'CLIENTUSER_ADD_FAIL';
export const CLIENTUSER_ADD_ERROR = 'CLIENTUSER_ADD_ERROR';
export const CLIENTUSER_UPDATE_INIT = 'CLIENTUSER_UPDATE_INIT';
export const CLIENTUSER_UPDATE_FECTHING = 'CLIENTUSER_UPDATE_FECTHING';
export const CLIENTUSER_UPDATE_FETCHED = 'CLIENTUSER_UPDATE_FETCHED';
export const CLIENTUSER_UPDATE_FAIL = 'CLIENTUSER_UPDATE_FAIL';
export const CLIENTUSER_UPDATE_ERROR = 'CLIENTUSER_UPDATE_ERROR';
export const CLIENTUSER_DELETE_FECTHING = 'CLIENTUSER_DELETE_FECTHING';
export const CLIENTUSER_DELETE_FETCHED = 'CLIENTUSER_DELETE_FETCHED';
export const CLIENTUSER_DELETE_FAIL = 'CLIENTUSER_DELETE_FAIL';
export const CLIENTUSER_DELETE_ERROR = 'CLIENTUSER_DELETE_ERROR';

/* xxx.Client Head Office */
export const CLIENT_HEADOFFICE_SEARCH_FETCHING =
  'CLIENT_HEADOFFICE_SEARCH_FETCHING';
export const CLIENT_HEADOFFICE_SEARCH_FETCHED =
  'CLIENT_HEADOFFICE_SEARCH_FETCHED';
export const CLIENT_HEADOFFICE_SEARCH_FAIL = 'CLIENT_HEADOFFICE_SEARCH_FAIL';
export const CLIENT_HEADOFFICE_SEARCH_ERROR = 'CLIENT_HEADOFFICE_SEARCH_ERROR';
export const CLIENT_HEADOFFICE_FETCHING = 'CLIENT_HEADOFFICE_FETCHING';
export const CLIENT_HEADOFFICE_FETCHED = 'CLIENT_HEADOFFICE_FETCHED';
export const CLIENT_HEADOFFICE_FAIL = 'CLIENT_HEADOFFICE_FAIL';
export const CLIENT_HEADOFFICE_ERROR = 'CLIENT_HEADOFFICE_ERROR';
export const CLIENT_HEADOFFICE_LIST_FETCHING =
  'CLIENT_HEADOFFICE_LIST_FETCHING';
export const CLIENT_HEADOFFICE_LIST_FETCHED = 'CLIENT_HEADOFFICE_LIST_FETCHED';
export const CLIENT_HEADOFFICE_LIST_FAIL = 'CLIENT_HEADOFFICE_LIST_FAIL';
export const CLIENT_HEADOFFICE_LIST_ERROR = 'CLIENT_HEADOFFICE_LIST_ERROR';
export const CLIENT_HEADOFFICE_ADD_INIT = 'CLIENT_HEADOFFICE_ADD_INIT';
export const CLIENT_HEADOFFICE_ADD_FECTHING = 'CLIENT_HEADOFFICE_ADD_FECTHING';
export const CLIENT_HEADOFFICE_ADD_FETCHED = 'CLIENT_HEADOFFICE_ADD_FETCHED';
export const CLIENT_HEADOFFICE_ADD_FAIL = 'CLIENT_HEADOFFICE_ADD_FAIL';
export const CLIENT_HEADOFFICE_ADD_ERROR = 'CLIENT_HEADOFFICE_ADD_ERROR';
export const CLIENT_HEADOFFICE_UPDATE_INIT = 'CLIENT_HEADOFFICE_UPDATE_INIT';
export const CLIENT_HEADOFFICE_UPDATE_FECTHING =
  'CLIENT_HEADOFFICE_UPDATE_FECTHING';
export const CLIENT_HEADOFFICE_UPDATE_FETCHED =
  'CLIENT_HEADOFFICE_UPDATE_FETCHED';
export const CLIENT_HEADOFFICE_UPDATE_FAIL = 'CLIENT_HEADOFFICE_UPDATE_FAIL';
export const CLIENT_HEADOFFICE_UPDATE_ERROR = 'CLIENT_HEADOFFICE_UPDATE_ERROR';

/* c.Client Branch */
export const CLIENT_BRANCH_FECTHING = 'CLIENT_BRANCH_FECTHING';
export const CLIENT_BRANCH_FETCHED = 'CLIENT_BRANCH_FETCHED';
export const CLIENT_BRANCH_FAIL = 'CLIENT_BRANCH_FAIL';
export const CLIENT_BRANCH_ERROR = 'CLIENT_BRANCH_ERROR';
export const CLIENTBRANCH_SEARCH_FETCHING = 'CLIENTBRANCH_SEARCH_FETCHING';
export const CLIENTBRANCH_SEARCH_FETCHED = 'CLIENTBRANCH_SEARCH_FETCHED';
export const CLIENTBRANCH_SEARCH_FAIL = 'CLIENTBRANCH_SEARCH_FAIL';
export const CLIENTBRANCH_SEARCH_ERROR = 'CLIENTBRANCH_SEARCH_ERROR';
export const CLIENTBRANCH_ADD_INIT = 'CLIENTBRANCH_ADD_INIT';
export const CLIENTBRANCH_ADD_FECTHING = 'CLIENTBRANCH_ADD_FECTHING';
export const CLIENTBRANCH_ADD_FETCHED = 'CLIENTBRANCH_ADD_FETCHED';
export const CLIENTBRANCH_ADD_FAIL = 'CLIENTBRANCH_ADD_FAIL';
export const CLIENTBRANCH_ADD_ERROR = 'CLIENTBRANCH_ADD_ERROR';
export const CLIENTBRANCH_UPDATE_INIT = 'CLIENTBRANCH_UPDATE_INIT';
export const CLIENTBRANCH_UPDATE_FECTHING = 'CLIENTBRANCH_UPDATE_FECTHING';
export const CLIENTBRANCH_UPDATE_FETCHED = 'CLIENTBRANCH_UPDATE_FETCHED';
export const CLIENTBRANCH_UPDATE_FAIL = 'CLIENTBRANCH_UPDATE_FAIL';
export const CLIENTBRANCH_UPDATE_ERROR = 'CLIENTBRANCH_UPDATE_ERROR';
export const CLIENTBRANCH_DELETE_INIT = 'CLIENTBRANCH_DELETE_INIT';
export const CLIENTBRANCH_DELETE_FECTHING = 'CLIENTBRANCH_DELETE_FECTHING';
export const CLIENTBRANCH_DELETE_FETCHED = 'CLIENTBRANCH_DELETE_FETCHED';
export const CLIENTBRANCH_DELETE_FAIL = 'CLIENTBRANCH_DELETE_FAIL';
export const CLIENTBRANCH_DELETE_ERROR = 'CLIENTBRANCH_DELETE_ERROR';

/* d.Client Branch Device */
export const CLIENTBRANCH_DEVICE_FECTHING = 'CLIENTBRANCH_DEVICE_FECTHING';
export const CLIENTBRANCH_DEVICE_FETCHED = 'CLIENTBRANCH_DEVICE_FETCHED';
export const CLIENTBRANCH_DEVICE_FAIL = 'CLIENTBRANCH_DEVICE_FAIL';
export const CLIENTBRANCH_DEVICE_ERROR = 'CLIENTBRANCH_DEVICE_ERROR';
export const CLIENTBRANCH_ADD_DEVICE_FECTHING =
  'CLIENTBRANCH_ADD_DEVICE_FECTHING';
export const CLIENTBRANCH_ADD_DEVICE_FETCHED =
  'CLIENTBRANCH_ADD_DEVICE_FETCHED';
export const CLIENTBRANCH_ADD_DEVICE_FAIL = 'CLIENTBRANCH_ADD_DEVICE_FAIL';
export const CLIENTBRANCH_ADD_DEVICE_ERROR = 'CLIENTBRANCH_ADD_DEVICE_ERROR';
export const CLIENTBRANCH_UPDATE_DEVICE_FECTHING =
  'CLIENTBRANCH_UPDATE_DEVICE_FECTHING';
export const CLIENTBRANCH_UPDATE_DEVICE_FETCHED =
  'CLIENTBRANCH_UPDATE_DEVICE_FETCHED';
export const CLIENTBRANCH_UPDATE_DEVICE_FAIL =
  'CLIENTBRANCH_UPDATE_DEVICE_FAIL';
export const CLIENTBRANCH_UPDATE_DEVICE_ERROR =
  'CLIENTBRANCH_UPDATE_DEVICE_ERROR';
export const CLIENTBRANCH_DELETE_DEVICE_FECTHING =
  'CLIENTBRANCH_DELETE_DEVICE_FECTHING';
export const CLIENTBRANCH_DELETE_DEVICE_FETCHED =
  'CLIENTBRANCH_DELETE_DEVICE_FETCHED';
export const CLIENTBRANCH_DELETE_DEVICE_FAIL =
  'CLIENTBRANCH_DELETE_DEVICE_FAIL';
export const CLIENTBRANCH_DELETE_DEVICE_ERROR =
  'CLIENTBRANCH_DELETE_DEVICE_ERROR';

/* e.Client Warehouse */
export const CLIENT_WAREHOUSE_FETCHING = 'CLIENT_WAREHOUSE_FETCHING';
export const CLIENT_WAREHOUSE_FETCHED = 'CLIENT_WAREHOUSE_FETCHED';
export const CLIENT_WAREHOUSE_FAIL = 'CLIENT_WAREHOUSE_FAIL';
export const CLIENT_WAREHOUSE_ERROR = 'CLIENT_WAREHOUSE_ERROR';
export const CLIENTWAREHOUSE_ADD_INIT = 'CLIENTWAREHOUSE_ADD_INIT';
export const CLIENTWAREHOUSE_ADD_FETCHING = 'CLIENTWAREHOUSE_ADD_FETCHING';
export const CLIENTWAREHOUSE_ADD_FETCHED = 'CLIENTWAREHOUSE_ADD_FETCHED';
export const CLIENTWAREHOUSE_ADD_FAIL = 'CLIENTWAREHOUSE_ADD_FAIL';
export const CLIENTWAREHOUSE_ADD_ERROR = 'CLIENTWAREHOUSE_ADD_ERROR';
export const CLIENTWAREHOUSE_UPDATE_INIT = 'CLIENTWAREHOUSE_UPDATE_INIT';
export const CLIENTWAREHOUSE_UPDATE_FETCHING =
  'CLIENTWAREHOUSE_UPDATE_FETCHING';
export const CLIENTWAREHOUSE_UPDATE_FETCHED = 'CLIENTWAREHOUSE_UPDATE_FETCHED';
export const CLIENTWAREHOUSE_UPDATE_FAIL = 'CLIENTWAREHOUSE_UPDATE_FAIL';
export const CLIENTWAREHOUSE_UPDATE_ERROR = 'CLIENTWAREHOUSE_UPDATE_ERROR';
export const CLIENTWAREHOUSE_DELETE_FETCHING =
  'CLIENTWAREHOUSE_DELETE_FETCHING';
export const CLIENTWAREHOUSE_DELETE_FETCHED = 'CLIENTWAREHOUSE_DELETE_FETCHED';
export const CLIENTWAREHOUSE_DELETE_FAIL = 'CLIENTWAREHOUSE_DELETE_FAIL';
export const CLIENTWAREHOUSE_DELETE_ERROR = 'CLIENTWAREHOUSE_DELETE_ERROR';

/* f.Client Branch List (General) */
export const CLIENTBRANCH_GENERALLIST_FETCHING =
  'CLIENTBRANCH_GENERALLIST_FETCHING';
export const CLIENTBRANCH_GENERALLIST_FETCHED =
  'CLIENTBRANCH_GENERALLIST_FETCHED';
export const CLIENTBRANCH_GENERALLIST_FAIL = 'CLIENTBRANCH_GENERALLIST_FAIL';
export const CLIENTBRANCH_GENERALLIST_ERROR = 'CLIENTBRANCH_GENERALLIST_ERROR';

/* g.Client Branch Report Interaction */
export const CLIENTBRANCH_REPORTINTERACTION_FECTHING =
  'CLIENTBRANCH_REPORTINTERACTION_FECTHING';
export const CLIENTBRANCH_REPORTINTERACTION_FETCHED =
  'CLIENTBRANCH_REPORTINTERACTION_FETCHED';
export const CLIENTBRANCH_REPORTINTERACTION_FAIL =
  'CLIENTBRANCH_REPORTINTERACTION_FAIL';
export const CLIENTBRANCH_REPORTINTERACTION_ERROR =
  'CLIENTBRANCH_REPORTINTERACTION_ERROR';

/* CATEGORY ACTION TYPE */
export const CATEGORY_FECTHING = 'CATEGORY_FECTHING';
export const CATEGORY_FETCHED = 'CATEGORY_FETCHED';
export const CATEGORY_SEARCH_FETCHING = 'CATEGORY_SEARCH_FETCHING';
export const CATEGORY_SEARCH_FETCHED = 'CATEGORY_SEARCH_FETCHED';
export const CATEGORY_SEARCH_FAIL = 'CATEGORY_SEARCH_FAIL';
export const CATEGORY_SEARCH_ERROR = 'CATEGORY_SEARCH_ERROR';
export const CATEGORY_ADD_INIT = 'CATEGORY_ADD_INIT';
export const CATEGORY_ADDING = 'CATEGORY_ADDING';
export const CATEGORY_ADDED = 'CATEGORY_ADDED';
export const CATEGORY_ADD_FAIL = 'CATEGORY_ADD_FAIL';
export const CATEGORY_ADD_ERROR = 'CATEGORY_ADD_ERROR';
export const CATEGORY_UPDATE_INIT = 'CATEGORY_UPDATE_INIT';
export const CATEGORY_UPDATING = 'CATEGORY_UPDATING';
export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';
export const CATEGORY_UPDATE_ERROR = 'CATEGORY_UPDATE_ERROR';
export const CATEGORY_DELETING = 'CATEGORY_DELETING';
export const CATEGORY_DELETED = 'CATEGORY_DELETED';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';
export const CATEGORY_DELETE_ERROR = 'CATEGORY_DELETE_ERROR';
export const CATEGORY_FAIL = 'CATEGORY_FAIL';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';

/* VARIANT ACTION TYPE */
export const VARIANT_FECTHING = 'VARIANT_FECTHING';
export const VARIANT_FETCHED = 'VARIANT_FETCHED';
export const VARIANT_SEARCH_FETCHING = 'VARIANT_SEARCH_FETCHING';
export const VARIANT_SEARCH_FETCHED = 'VARIANT_SEARCH_FETCHED';
export const VARIANT_SEARCH_FAIL = 'VARIANT_SEARCH_FAIL';
export const VARIANT_SEARCH_ERROR = 'VARIANT_SEARCH_ERROR';
export const VARIANT_ADD_INIT = 'VARIANT_ADD_INIT';
export const VARIANT_ADDING = 'VARIANT_ADDING';
export const VARIANT_ADDED = 'VARIANT_ADDED';
export const VARIANT_ADD_FAIL = 'VARIANT_ADD_FAIL';
export const VARIANT_ADD_ERROR = 'VARIANT_ADD_ERROR';
export const VARIANT_UPDATE_INIT = 'VARIANT_UPDATE_INIT';
export const VARIANT_UPDATING = 'VARIANT_UPDATING';
export const VARIANT_UPDATED = 'VARIANT_UPDATED';
export const VARIANT_UPDATE_FAIL = 'VARIANT_UPDATE_FAIL';
export const VARIANT_UPDATE_ERROR = 'VARIANT_UPDATE_ERROR';
export const VARIANT_DELETING = 'VARIANT_DELETING';
export const VARIANT_DELETED = 'VARIANT_DELETED';
export const VARIANT_DELETE_FAIL = 'VARIANT_DELETE_FAIL';
export const VARIANT_DELETE_ERROR = 'VARIANT_DELETE_ERROR';
export const VARIANT_FAIL = 'VARIANT_FAIL';
export const VARIANT_ERROR = 'VARIANT_ERROR';

/* PRODUCT PROFILE ACTION TYPE */
export const PRODUCT_PROFILE_FECTHING = 'PRODUCT_PROFILE_FECTHING';
export const PRODUCT_PROFILE_FETCHED = 'PRODUCT_PROFILE_FETCHED';
export const PRODUCT_PROFILE_FAIL = 'PRODUCT_PROFILE_FAIL';
export const PRODUCT_PROFILE_ERROR = 'PRODUCT_PROFILE_ERROR';
export const PRODUCT_PROFILE_ADDING = 'PRODUCT_PROFILE_ADDING';
export const PRODUCT_PROFILE_ADDED = 'PRODUCT_PROFILE_ADDED';
export const PRODUCT_PROFILE_ADD_FAIL = 'PRODUCT_PROFILE_ADD_FAIL';
export const PRODUCT_PROFILE_ADD_ERROR = 'PRODUCT_PROFILE_ADD_ERROR';
export const PRODUCT_PROFILE_UPDATING = 'PRODUCT_PROFILE_UPDATING';
export const PRODUCT_PROFILE_UPDATED = 'PRODUCT_PROFILE_UPDATED';
export const PRODUCT_PROFILE_UPDATE_FAIL = 'PRODUCT_PROFILE_UPDATE_FAIL';
export const PRODUCT_PROFILE_UPDATE_ERROR = 'PRODUCT_PROFILE_UPDATE_ERROR';
export const PRODUCT_PROFILE_DELETING = 'PRODUCT_PROFILE_DELETING';
export const PRODUCT_PROFILE_DELETED = 'PRODUCT_PROFILE_DELETED';
export const PRODUCT_PROFILE_DELETE_FAIL = 'PRODUCT_PROFILE_DELETE_FAIL';
export const PRODUCT_PROFILE_DELETE_ERROR = 'PRODUCT_PROFILE_DELETE_ERROR';

/* VOUCHER ACTION TYPE */
export const VOUCHER_FETCHING = 'VOUCHER_FETCHING';
export const VOUCHER_FETCHED = 'VOUCHER_FETCHED';
export const VOUCHER_FAIL = 'VOUCHER_FAIL';
export const VOUCHER_ERROR = 'VOUCHER_ERROR';
export const VOUCHER_SEARCH_FETCHING = 'VOUCHER_SEARCH_FETCHING';
export const VOUCHER_SEARCH_FETCHED = 'VOUCHER_SEARCH_FETCHED';
export const VOUCHER_SEARCH_FAIL = 'VOUCHER_SEARCH_FAIL';
export const VOUCHER_SEARCH_ERROR = 'VOUCHER_SEARCH_ERROR';
export const VOUCHER_DETAIL_FETCHING = 'VOUCHER_DETAIL_FETCHING';
export const VOUCHER_DETAIL_FETCHED = 'VOUCHER_DETAIL_FETCHED';
export const VOUCHER_DETAIL_FAIL = 'VOUCHER_DETAIL_FAIL';
export const VOUCHER_DETAIL_ERROR = 'VOUCHER_DETAIL_ERROR';
export const VOUCHER_APPLIEDFOR_SELECTED_FETCHING = 'VOUCHER_APPLIEDFOR_SELECTED_FETCHING';
export const VOUCHER_APPLIEDFOR_SELECTED_FETCHED = 'VOUCHER_APPLIEDFOR_SELECTED_FETCHED';
export const VOUCHER_APPLIEDFOR_SELECTED_FAIL = 'VOUCHER_APPLIEDFOR_SELECTED_FAIL';
export const VOUCHER_APPLIEDFOR_SELECTED_ERROR = 'VOUCHER_APPLIEDFOR_SELECTED_ERROR';
export const VOUCHER_CODE_DETAIL_FETCHING = 'VOUCHER_CODE_DETAIL_FETCHING';
export const VOUCHER_CODE_DETAIL_FETCHED = 'VOUCHER_CODE_DETAIL_FETCHED';
export const VOUCHER_CODE_DETAIL_FAIL = 'VOUCHER_CODE_DETAIL_FAIL';
export const VOUCHER_CODE_DETAIL_ERROR = 'VOUCHER_CODE_DETAIL_ERROR';
export const VOUCHER_ADD_INIT = 'VOUCHER_ADD_INIT';
export const VOUCHER_ADD_FETCHING = 'VOUCHER_ADD_FETCHING';
export const VOUCHER_ADD_FETCHED = 'VOUCHER_ADD_FETCHED';
export const VOUCHER_ADD_FAIL = 'VOUCHER_ADD_FAIL';
export const VOUCHER_ADD_ERROR = 'VOUCHER_ADD_ERROR';
export const VOUCHER_EDIT_INIT_FETCHING = 'VOUCHER_EDIT_INIT_FETCHING';
export const VOUCHER_EDIT_INIT = 'VOUCHER_EDIT_INIT';
export const VOUCHER_EDIT_FETCHING = 'VOUCHER_EDIT_FETCHING';
export const VOUCHER_EDIT_FETCHED = 'VOUCHER_EDIT_FETCHED';
export const VOUCHER_EDIT_FAIL = 'VOUCHER_EDIT_FAIL';
export const VOUCHER_EDIT_ERROR = 'VOUCHER_EDIT_ERROR';
export const VOUCHER_APPLIEDFOR_LIST_INIT = 'VOUCHER_APPLIEDFOR_LIST_INIT';
export const VOUCHER_APPLIEDFOR_LIST_FETCHING =
  'VOUCHER_APPLIEDFOR_LIST_FETCHING';
export const VOUCHER_APPLIEDFOR_LIST_FETCHED =
  'VOUCHER_APPLIEDFOR_LIST_FETCHED';
export const VOUCHER_APPLIEDFOR_LIST_FAIL = 'VOUCHER_APPLIEDFOR_LIST_FAIL';
export const VOUCHER_APPLIEDFOR_LIST_ERROR = 'VOUCHER_APPLIEDFOR_LIST_ERROR';

/* BANNER ACTION TYPE */
export const BANNER_FECTHING = 'BANNER_FECTHING';
export const BANNER_FETCHED = 'BANNER_FETCHED';
export const BANNER_ADD_INIT = 'BANNER_ADD_INIT';
export const BANNER_ADDING = 'BANNER_ADDING';
export const BANNER_ADDED = 'BANNER_ADDED';
export const BANNER_ADD_FAIL = 'BANNER_ADD_FAIL';
export const BANNER_ADD_ERROR = 'BANNER_ADD_ERROR';
export const BANNER_UPDATE_INIT = 'BANNER_UPDATE_INIT';
export const BANNER_UPDATING = 'BANNER_UPDATING';
export const BANNER_UPDATED = 'BANNER_UPDATED';
export const BANNER_UPDATE_FAIL = 'BANNER_UPDATE_FAIL';
export const BANNER_UPDATE_ERROR = 'BANNER_UPDATE_ERROR';
export const BANNER_DELETE_INIT = 'BANNER_DELETE_INIT';
export const BANNER_DELETING = 'BANNER_DELETING';
export const BANNER_DELETED = 'BANNER_DELETED';
export const BANNER_DELETE_FAIL = 'BANNER_DELETE_FAIL';
export const BANNER_DELETE_ERROR = 'BANNER_DELETE_ERROR';
export const BANNER_FAIL = 'BANNER_FAIL';
export const BANNER_ERROR = 'BANNER_ERROR';

/* Consumed by Banner Add */
export const BANNER_SLOT_INIT = "BANNER_SLOT_INIT";
export const BANNER_SLOT_FETCHING = "BANNER_SLOT_FETCHING";
export const BANNER_SLOT_FETCHED = "BANNER_SLOT_FETCHED";
export const BANNER_SLOT_FAIL = "BANNER_SLOT_FAIL";
export const BANNER_SLOT_ERROR = "BANNER_SLOT_ERROR";

/* Consumed by Banner Profile */
export const PROFILE_LIST_FECTHING = 'PROFILE_LIST_FECTHING';
export const PROFILE_LIST_FETCHED = 'PROFILE_LIST_FETCHED';
export const PROFILE_LIST_FAIL = 'PROFILE_LIST_FAIL';
export const PROFILE_LIST_ERROR = 'PROFILE_LIST_ERROR';

/* BANNER PROFILE ACTION TYPE */
export const PROFILE_BANNER_FECTHING = 'PROFILE_BANNER_FECTHING';
export const PROFILE_BANNER_FETCHED = 'PROFILE_BANNER_FETCHED';
export const PROFILE_BANNER_ADDING = 'PROFILE_BANNER_ADDING';
export const PROFILE_BANNER_ADDED = 'PROFILE_BANNER_ADDED';
export const PROFILE_BANNER_DELETING = 'PROFILE_BANNER_DELETING';
export const PROFILE_BANNER_DELETED = 'PROFILE_BANNER_DELETED';
export const PROFILE_BANNER_FAIL = 'PROFILE_BANNER_FAIL';
export const PROFILE_BANNER_ERROR = 'PROFILE_BANNER_ERROR';

/* DOMICILE ACTION TYPE */
export const DOMICILE_INIT_DATA_REGION = 'DOMICILE_INIT_DATA_REGION';
export const DOMICILE_ALL_FETCHING = 'DOMICILE_ALL_FETCHING';
export const DOMICILE_ALL_FETCHED = 'DOMICILE_ALL_FETCHED';
export const DOMICILE_ALL_FAIL = 'DOMICILE_ALL_FAIL';
export const DOMICILE_ALL_ERROR = 'DOMICILE_ALL_ERROR';

export const DOMICILE_INIT_DATA = 'DOMICILE_INIT_DATA';
export const DOMICILE_INIT_DATA_CITY = 'DOMICILE_INIT_DATA_CITY';
export const DOMICILE_INIT_DATA_DISTRICT = 'DOMICILE_INIT_DATA_DISTRICT';
export const DOMICILE_PROVINCE_FETCHING = 'DOMICILE_PROVINCE_FETCHING';
export const DOMICILE_PROVINCE_FETCHED = 'DOMICILE_PROVINCE_FETCHED';
export const DOMICILE_PROVINCE_FAIL = 'DOMICILE_PROVINCE_FAIL';
export const DOMICILE_PROVINCE_ERROR = 'DOMICILE_PROVINCE_ERROR';

export const DOMICILE_CITY_FETCHING = 'DOMICILE_CITY_FETCHING';
export const DOMICILE_CITY_FETCHED = 'DOMICILE_CITY_FETCHED';
export const DOMICILE_CITY_FAIL = 'DOMICILE_CITY_FAIL';
export const DOMICILE_CITY_ERROR = 'DOMICILE_CITY_ERROR';

export const DOMICILE_DISTRICT_FETCHING = 'DOMICILE_DISTRICT_FETCHING';
export const DOMICILE_DISTRICT_FETCHED = 'DOMICILE_DISTRICT_FETCHED';
export const DOMICILE_DISTRICT_FAIL = 'DOMICILE_DISTRICT_FAIL';
export const DOMICILE_DISTRICT_ERROR = 'DOMICILE_DISTRICT_ERROR';

export const DOMICILE_SUBDISTRICT_FETCHING = 'DOMICILE_SUBDISTRICT_FETCHING';
export const DOMICILE_SUBDISTRICT_FETCHED = 'DOMICILE_SUBDISTRICT_FETCHED';
export const DOMICILE_SUBDISTRICT_FAIL = 'DOMICILE_SUBDISTRICT_FAIL';
export const DOMICILE_SUBDISTRICT_ERROR = 'DOMICILE_SUBDISTRICT_ERROR';

/* REPORT INTERACTION ACTION TYPE */
export const REPORT_INTERACTION_FETCHING = 'REPORT_INTERACTION_FETCHING';
export const REPORT_INTERACTION_FETCHED = 'REPORT_INTERACTION_FETCHED';
export const REPORT_INTERACTION_FAIL = 'REPORT_INTERACTION_FAIL';
export const REPORT_INTERACTION_ERROR = 'REPORT_INTERACTION_ERROR';

export const REPORT_INTERACTION_EXPORTING = 'REPORT_INTERACTION_EXPORTING';
export const REPORT_INTERACTION_EXPORTED = 'REPORT_INTERACTION_EXPORTED';
export const REPORT_INTERACTION_EXPORT_FAIL = 'REPORT_INTERACTION_EXPORT_FAIL';
export const REPORT_INTERACTION_EXPORT_ERROR =
  'REPORT_INTERACTION_EXPORT_ERROR';

export const REPORTINTERACTION_V2_INIT = 'REPORTINTERACTION_V2_INIT';
export const REPORTINTERACTION_V2_EXPORTING = 'REPORTINTERACTION_V2_EXPORTING';
export const REPORTINTERACTION_V2_EXPORTED = 'REPORTINTERACTION_V2_EXPORTED';
export const REPORTINTERACTION_V2_EXPORT_FAIL =
  'REPORTINTERACTION_V2_EXPORT_FAIL';
export const REPORTINTERACTION_V2_EXPORT_ERROR =
  'REPORTINTERACTION_V2_EXPORT_ERROR';

/* PRODUCT HIGHLIGHT */
export const SET_PRODUCT_HIGHLIGHT = 'SET_PRODUCT_HIGHLIGHT';
export const SET_PUBLISH_PRODUCT_HIGHLIGHT = 'SET_PUBLISH_PRODUCT_HIGHLIGHT';
export const SET_UNPUBLISH_PRODUCT_HIGHLIGHT =
  'SET_UNPUBLISH_PRODUCT_HIGHLIGHT';
export const SET_FETCHING = 'SET_FETCHING';
