import {
  LIVE_PRODUCT_FECTHING,
  LIVE_PRODUCT_FETCHED,
  LIVEPRODUCT_BYDATE_FECTHING,
  LIVEPRODUCT_BYDATE_FETCHED,
  LIVEPRODUCT_BYDATE_FAIL,
  LIVEPRODUCT_BYDATE_ERROR,
  LIVEPRODUCT_BYDATEID_FECTHING,
  LIVEPRODUCT_BYDATEID_FETCHED,
  LIVEPRODUCT_BYDATEID_FAIL,
  LIVEPRODUCT_BYDATEID_ERROR,
  LIVEPRODUCT_ADD_INIT,
  LIVE_PRODUCT_ADDING,
  LIVE_PRODUCT_ADDED,
  LIVE_PRODUCT_ADD_FAIL,
  LIVE_PRODUCT_ADD_ERROR,
  LIVE_PRODUCT_UPDATED,
  LIVE_PRODUCT_DELETING,
  LIVE_PRODUCT_DELETED,
  LIVE_PRODUCT_DELETE_FAIL,
  LIVE_PRODUCT_DELETE_ERROR,
  LIVEPERIODIC_ADD_INIT,
  LIVE_PERIODIC_ADDING,
  LIVE_PERIODIC_ADDED,
  LIVE_PERIODIC_ADD_FAIL,
  LIVE_PERIODIC_ADD_ERROR,
  LIVEPERIODIC_EDIT_INIT,
  LIVE_PERIODIC_EDITING,
  LIVE_PERIODIC_EDITED,
  LIVE_PERIODIC_EDIT_FAIL,
  LIVE_PERIODIC_EDIT_ERROR,
  LIVE_PRODUCT_FAIL,
  LIVE_PRODUCT_ERROR
} from "../actions/types";

const initialState = {
  dataLiveProducts: [],
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true,

  dataGetByDateLiveProduct: {},
  fetchGetByDateLiveProductStatus: false,
  fetchGetByDateLiveProductCode: 0,
  fetchGetByDateLiveProductMessage: "",
  isGetByDateLiveProductFetching: true,

  dataGetByDateIdLiveProduct: {},
  fetchGetByDateIdLiveProductStatus: false,
  fetchGetByDateIdLiveProductCode: 0,
  fetchGetByDateIdLiveProductMessage: "",
  isGetByDateIdLiveProductFetching: true,

  dataAddLiveProduct: {},
  fetchAddLiveProductStatus: false,
  fetchAddLiveProductCode: 0,
  fetchAddLiveProductMessage: "",
  isAddLiveProductFetching: true,

  dataUpdateLiveProduct: {},
  fetchUpdateLiveProductStatus: false,
  fetchUpdateLiveProductCode: 0,
  fetchUpdateLiveProductMessage: "",
  isUpdateLiveProductFetching: true,

  dataDeleteLiveProduct: {},
  fetchDeleteLiveProductStatus: false,
  fetchDeleteLiveProductCode: 0,
  fetchDeleteLiveProductMessage: "",
  isDeleteLiveProductFetching: true,

  dataAddPeriodicLiveProduct: {},
  fetchAddPeriodicLiveProductStatus: false,
  fetchAddPeriodicLiveProductCode: 0,
  fetchAddPeriodicLiveProductMessage: "",
  isAddPeriodicLiveProductFetching: true,

  dataEditPeriodicLiveProduct: {},
  fetchEditPeriodicLiveProductStatus: false,
  fetchEditPeriodicLiveProductCode: 0,
  fetchEditPeriodicLiveProductMessage: "",
  isEditPeriodicLiveProductFetching: true
};
function liveproducts(state = initialState, action) {
  switch (action.type) {
    case LIVE_PRODUCT_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case LIVE_PRODUCT_FETCHED:
      return {
        ...state,
        isFetching: false,
        dataLiveProducts: action.payload.payload,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case LIVEPRODUCT_BYDATE_FECTHING:
      return {
        ...state,
        isGetByDateLiveProductFetching: true
      };
    case LIVEPRODUCT_BYDATE_FETCHED:
      return {
        ...state,
        isGetByDateLiveProductFetching: false,
        dataGetByDateLiveProduct: action.payload.payload,
        fetchGetByDateLiveProductStatus: action.payload.success,
        fetchGetByDateLiveProductCode: action.payload.code,
        fetchGetByDateLiveProductMessage: action.payload.message
      };
    case LIVEPRODUCT_BYDATE_FAIL:
      return {
        ...state,
        isGetByDateLiveProductFetching: false,
        fetchGetByDateLiveProductStatus: action.payload.success,
        fetchGetByDateLiveProductCode: action.payload.code,
        fetchGetByDateLiveProductMessage: action.payload.message
      };
    case LIVEPRODUCT_BYDATE_ERROR:
      return {
        ...state,
        isGetByDateLiveProductFetching: false,
        fetchGetByDateLiveProductMessage: action.payload.message
      };


    case LIVEPRODUCT_BYDATEID_FECTHING:
      return {
        ...state,
        isGetByDateIdLiveProductFetching: true
      }
    case LIVEPRODUCT_BYDATEID_FETCHED:
      return {
        ...state,
        isGetByDateIdLiveProductFetching: false,
        dataGetByDateIdLiveProduct: action.payload.payload,
        fetchGetByDateIdLiveProductStatus: action.payload.success,
        fetchGetByDateIdLiveProductCode: action.payload.code,
        fetchGetByDateIdLiveProductMessage: action.payload.message
      }
    case LIVEPRODUCT_BYDATEID_FAIL:
      return {
        ...state,
        isGetByDateIdLiveProductFetching: false,
        fetchGetByDateIdLiveProductStatus: action.payload.success,
        fetchGetByDateIdLiveProductCode: action.payload.code,
        fetchGetByDateIdLiveProductMessage: action.payload.message
      }
    case LIVEPRODUCT_BYDATEID_ERROR:
      return {
        ...state,
        isGetByDateIdLiveProductFetching: false,
        fetchGetByDateIdLiveProductMessage: ""
      }

    case LIVEPRODUCT_ADD_INIT:
      return {
        ...state,
        fetchAddLiveProductStatus: false,
        fetchAddLiveProductCode: 0,
        fetchAddLiveProductMessage: ""
      }
    case LIVE_PRODUCT_ADDING:
      return {
        ...state,
        isAddLiveProductFetching: true
      }
    case LIVE_PRODUCT_ADDED:
      return {
        ...state,
        isAddLiveProductFetching: false,
        dataGetByDateLiveProduct: state.dataGetByDateLiveProduct,
        dataAddLiveProduct: action.payload.payload,
        fetchAddLiveProductStatus: action.payload.success,
        fetchAddLiveProductCode: action.payload.code,
        fetchAddLiveProductMessage: action.payload.message,
      };
    case LIVE_PRODUCT_ADD_FAIL:
      return {
        ...state,
        isAddLiveProductFetching: false,
        fetchAddLiveProductStatus: action.payload.success,
        fetchAddLiveProductCode: action.payload.code,
        fetchAddLiveProductMessage: action.payload.message,
      }
    case LIVE_PRODUCT_ADD_ERROR:
      return {
        ...state,
        isAddLiveProductFetching: false,
        fetchAddLiveProductMessage: action.payload.message,
      }
    case LIVE_PRODUCT_UPDATED:
      return {
        ...state,
        isFetching: false
      };
    case LIVE_PRODUCT_DELETING:
      return {
        ...state,
        isDeleteLiveProductFetching: true
      }
    case LIVE_PRODUCT_DELETED:
      return {
        ...state,
        isDeleteLiveProductFetching: false,
        dataDeleteLiveProduct: action.payload,
        dataGetByDateLiveProduct: state.dataGetByDateLiveProduct,
        fetchDeleteLiveProductStatus: action.payload.success,
        fetchDeleteLiveProductCode: action.payload.code,
        fetchDeleteLiveProductMessage: action.payload.message,
      }
    case LIVE_PRODUCT_DELETE_FAIL:
      return {
        ...state,
        isDeleteLiveProductFetching: false,
      }
    case LIVE_PRODUCT_DELETE_ERROR:
      return {
        ...state,
        isDeleteLiveProductFetching: false,
      }
    case LIVEPERIODIC_ADD_INIT:
      return {
        ...state,
        fetchAddPeriodicLiveProductStatus: false,
        fetchAddPeriodicLiveProductCode: 0,
        fetchAddPeriodicLiveProductMessage: ""
      }
    case LIVE_PERIODIC_ADDING:
      return {
        ...state,
        isAddPeriodicLiveProductFetching: true
      }
    case LIVE_PERIODIC_ADDED:
      return {
        ...state,
        isAddPeriodicLiveProductFetching: false,
        dataAddPeriodicLiveProduct: action.payload.payload,
        fetchAddPeriodicLiveProductStatus: action.payload.success,
        fetchAddPeriodicLiveProductCode: action.payload.code,
        fetchAddPeriodicLiveProductMessage: action.payload.message
      }
    case LIVE_PERIODIC_ADD_FAIL:
      return {
        ...state,
        isAddPeriodicLiveProductFetching: false,
        fetchAddPeriodicLiveProductStatus: action.payload.success,
        fetchAddPeriodicLiveProductCode: action.payload.code,
        fetchAddPeriodicLiveProductMessage: action.payload.message
      }
    case LIVE_PERIODIC_ADD_ERROR:
      return {
        ...state,
        fetchAddPeriodicLiveProductCode: action.payload.code,
        fetchAddPeriodicLiveProductMessage: action.payload.message
      }
    case LIVEPERIODIC_EDIT_INIT:
      return {
        ...state,
        fetchEditPeriodicLiveProductStatus: false,
        fetchEditPeriodicLiveProductCode: 0,
        fetchEditPeriodicLiveProductMessage: "",
      }
    case LIVE_PERIODIC_EDITING:
      return {
        ...state,
        isEditPeriodicLiveProductFetching: true,
      }
    case LIVE_PERIODIC_EDITED:
      return {
        ...state,
        isEditPeriodicLiveProductFetching: false,
        dataEditPeriodicLiveProduct: action.payload.payload,
        fetchEditPeriodicLiveProductStatus: action.payload.success,
        fetchEditPeriodicLiveProductCode: action.payload.code,
        fetchEditPeriodicLiveProductMessage: action.payload.message
      }
    case LIVE_PERIODIC_EDIT_FAIL:
      return {
        ...state,
        isEditPeriodicLiveProductFetching: false,
        fetchEditPeriodicLiveProductStatus: action.payload.success,
        fetchEditPeriodicLiveProductCode: action.payload.code,
        fetchEditPeriodicLiveProductMessage: action.payload.message
      }
    case LIVE_PERIODIC_EDIT_ERROR:
      return {
        ...state,
        isEditPeriodicLiveProductFetching: false,
        fetchEditPeriodicLiveProductCode: action.payload.code,
        fetchEditPeriodicLiveProductMessage: action.payload.message
      }
    case LIVE_PRODUCT_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case LIVE_PRODUCT_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };

    default:
      break;
  }
  return state;
}

export default liveproducts;
