import {
  CLIENT_FECTHING,
  CLIENT_FETCHED,
  CLIENT_LIST_FECTHING,
  CLIENT_LIST_FETCHED,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_ERROR,
  CLIENTLOC_LIST_FECTHING,
  CLIENTLOC_LIST_FETCHED,
  CLIENTLOC_LIST_FAIL,
  CLIENTLOC_LIST_ERROR,
  CLIENTPRODUCT_LIST_FECTHING,
  CLIENTPRODUCT_LIST_FETCHED,
  CLIENTPRODUCT_LIST_FAIL,
  CLIENTPRODUCT_LIST_ERROR,
  CLIENT_SEARCH_FETCHING,
  CLIENT_SEARCH_FETCHED,
  CLIENT_SEARCH_FAIL,
  CLIENT_SEARCH_ERROR,
  CLIENT_ADD_INIT,
  CLIENT_ADDING,
  CLIENT_ADDED,
  CLIENT_ADD_FAIL,
  CLIENT_ADD_ERROR,
  CLIENT_UPDATE_INIT,
  CLIENT_UPDATE,
  CLIENT_DELETING,
  CLIENT_DELETED,
  CLIENT_DELETE_FAIL,
  CLIENT_DELETE_ERROR,
  CLIENT_FAIL,
  CLIENT_ERROR,
  CLIENT_PRODUCT_FECTHING,
  CLIENT_PRODUCT_FETCHED,
  CLIENT_PRODUCT_ADDING_PROFILE,
  CLIENT_PRODUCT_ADDED_PROFILE,
  CLIENT_PRODUCT_DELETING_PROFILE,
  CLIENT_PRODUCT_DELETED_PROFILE,
  CLIENT_PRODUCT_UPDATING,
  CLIENT_PRODUCT_UPDATED,
  CLIENT_PRODUCT_DELETING,
  CLIENT_PRODUCT_DELETED,
  CLIENT_PRODUCT_FAIL,
  CLIENT_PRODUCT_ERROR,
  CLIENT_VARIANT_FETCHING,
  CLIENT_VARIANT_FETCHED,
  CLIENT_VARIANT_FAIL,
  CLIENT_VARIANT_ERROR,
  CLIENT_USER_FECTHING,
  CLIENT_USER_FETCHED,
  CLIENT_USER_FAIL,
  CLIENT_USER_ERROR,
  CLIENTUSER_ADD_INIT,
  CLIENTUSER_ADD_FECTHING,
  CLIENTUSER_ADD_FETCHED,
  CLIENTUSER_ADD_FAIL,
  CLIENTUSER_ADD_ERROR,
  CLIENTUSER_UPDATE_INIT,
  CLIENTUSER_UPDATE_FECTHING,
  CLIENTUSER_UPDATE_FETCHED,
  CLIENTUSER_UPDATE_FAIL,
  CLIENTUSER_UPDATE_ERROR,
  CLIENTUSER_DELETE_FECTHING,
  CLIENTUSER_DELETE_FETCHED,
  CLIENTUSER_DELETE_FAIL,
  CLIENTUSER_DELETE_ERROR,
  CLIENT_HEADOFFICE_SEARCH_FETCHING,
  CLIENT_HEADOFFICE_SEARCH_FETCHED,
  CLIENT_HEADOFFICE_SEARCH_FAIL,
  CLIENT_HEADOFFICE_SEARCH_ERROR,
  CLIENT_HEADOFFICE_FETCHING,
  CLIENT_HEADOFFICE_FETCHED,
  CLIENT_HEADOFFICE_FAIL,
  CLIENT_HEADOFFICE_ERROR,
  CLIENT_HEADOFFICE_LIST_FETCHING,
  CLIENT_HEADOFFICE_LIST_FETCHED,
  CLIENT_HEADOFFICE_LIST_FAIL,
  CLIENT_HEADOFFICE_LIST_ERROR,
  CLIENT_HEADOFFICE_ADD_INIT,
  CLIENT_HEADOFFICE_ADD_FECTHING,
  CLIENT_HEADOFFICE_ADD_FETCHED,
  CLIENT_HEADOFFICE_ADD_FAIL,
  CLIENT_HEADOFFICE_ADD_ERROR,
  CLIENT_HEADOFFICE_UPDATE_INIT,
  CLIENT_HEADOFFICE_UPDATE_FECTHING,
  CLIENT_HEADOFFICE_UPDATE_FETCHED,
  CLIENT_HEADOFFICE_UPDATE_FAIL,
  CLIENT_HEADOFFICE_UPDATE_ERROR,
  CLIENT_BRANCH_FECTHING,
  CLIENT_BRANCH_FETCHED,
  CLIENT_BRANCH_FAIL,
  CLIENT_BRANCH_ERROR,
  CLIENTBRANCH_SEARCH_FETCHING,
  CLIENTBRANCH_SEARCH_FETCHED,
  CLIENTBRANCH_SEARCH_FAIL,
  CLIENTBRANCH_SEARCH_ERROR,
  CLIENTBRANCH_ADD_INIT,
  CLIENTBRANCH_ADD_FECTHING,
  CLIENTBRANCH_ADD_FETCHED,
  CLIENTBRANCH_ADD_FAIL,
  CLIENTBRANCH_ADD_ERROR,
  CLIENTBRANCH_UPDATE_INIT,
  CLIENTBRANCH_UPDATE_FECTHING,
  CLIENTBRANCH_UPDATE_FETCHED,
  CLIENTBRANCH_UPDATE_FAIL,
  CLIENTBRANCH_UPDATE_ERROR,
  CLIENTBRANCH_DELETE_FECTHING,
  CLIENTBRANCH_DELETE_FETCHED,
  CLIENTBRANCH_DELETE_FAIL,
  CLIENTBRANCH_DELETE_ERROR,
  CLIENTBRANCH_DEVICE_FECTHING,
  CLIENTBRANCH_DEVICE_FETCHED,
  CLIENTBRANCH_DEVICE_FAIL,
  CLIENTBRANCH_DEVICE_ERROR,
  CLIENTBRANCH_ADD_DEVICE_FECTHING,
  CLIENTBRANCH_ADD_DEVICE_FETCHED,
  CLIENTBRANCH_ADD_DEVICE_FAIL,
  CLIENTBRANCH_ADD_DEVICE_ERROR,
  CLIENTBRANCH_UPDATE_DEVICE_FECTHING,
  CLIENTBRANCH_UPDATE_DEVICE_FETCHED,
  CLIENTBRANCH_UPDATE_DEVICE_FAIL,
  CLIENTBRANCH_UPDATE_DEVICE_ERROR,
  CLIENTBRANCH_DELETE_DEVICE_FECTHING,
  CLIENTBRANCH_DELETE_DEVICE_FETCHED,
  CLIENTBRANCH_DELETE_DEVICE_FAIL,
  CLIENTBRANCH_DELETE_DEVICE_ERROR,
  CLIENT_WAREHOUSE_FETCHING,
  CLIENT_WAREHOUSE_FETCHED,
  CLIENT_WAREHOUSE_FAIL,
  CLIENT_WAREHOUSE_ERROR,
  CLIENTWAREHOUSE_ADD_INIT,
  CLIENTWAREHOUSE_ADD_FETCHING,
  CLIENTWAREHOUSE_ADD_FETCHED,
  CLIENTWAREHOUSE_ADD_FAIL,
  CLIENTWAREHOUSE_ADD_ERROR,
  CLIENTWAREHOUSE_UPDATE_INIT,
  CLIENTWAREHOUSE_UPDATE_FETCHING,
  CLIENTWAREHOUSE_UPDATE_FETCHED,
  CLIENTWAREHOUSE_UPDATE_FAIL,
  CLIENTWAREHOUSE_UPDATE_ERROR,
  CLIENTWAREHOUSE_DELETE_FETCHING,
  CLIENTWAREHOUSE_DELETE_FETCHED,
  CLIENTWAREHOUSE_DELETE_FAIL,
  CLIENTWAREHOUSE_DELETE_ERROR,
} from '../actions/types';

const initialState = {
  /* Client Global */
  dataClients: [],
  clientLists: [],
  clientLocLists: [],
  clientProductLists: [],

  dataSearchedClients: [],
  fetchSearchedClientStatus: false,
  fetchSearchedClientCode: 0,
  fetchSearchedClientMessage: '',
  isSearchedClientFetching: true,

  /* ProductVariant */
  fetchedClientProductVariant: [],
  fetchedClientProductWarehouse: [],

  /* Client CRUD */
  addClients: {},
  isClientAdding: false,
  fetchAddClientCode: 0,
  fetchAddClientStatus: false,
  fetchAddClientMessage: '',

  updateClients: {},
  isClientUpdating: false,
  fetchUpdateClientCode: 0,
  fetchUpdateClientStatus: false,
  fetchUpdateClientMessage: '',

  deleteClients: {},
  isClientDeleting: false,
  fetchDeleteClientCode: 0,
  fetchDeleteClientStatus: false,
  fetchDeleteClientMessage: '',

  /* Client Product */
  dataClientProducts: [],

  /* Client Product Profile Data*/
  dataClientProductProfile: [],

  /* Client Product Profile CRUD*/
  addDataClientProductProfile: {},
  deleteDataClientProductProfile: {},
  dataUpdateClientProduct: {},
  deleteClientProduct: {},

  /* Client Head Search*/
  isDataClientHeadOfficeFetching: true,
  dataClientHeadOffices: [],
  fetchClientHeadOfficeStatus: false,
  fetchClientHeadOfficeCode: 0,
  fetchClientHeadOfficeMessage: '',

  /* Client Head */
  isSearchClientHOFetching: true,
  dataSearchClientHOs: [],
  fetchSearchClientHOStatus: false,
  fetchSearchClientHOCode: 0,
  fetchSearchClientHOMessage: '',

  isDataClientHOListFetching: true,
  dataClientHOLists: [],
  fetchClientHOListStatus: false,
  fetchClientHOListCode: 0,
  fetchClientHOListMessage: '',

  isAddClientHeadOffice: true,
  dataAddClientHeadOffice: {},
  fetchAddClientHeadOfficeStatus: false,
  fetchAddClientHeadOfficeCode: 0,
  fetchAddClientHeadOfficeMessage: '',

  isUpdateClientHeadOffice: true,
  dataUpdateClientHeadOffice: {},
  fetchUpdateClientHeadOfficeStatus: false,
  fetchUpdateClientHeadOfficeCode: 0,
  fetchUpdateClientHeadOfficeMessage: '',

  /* Client Branch */
  dataClientBranchs: [],

  /* Client Branch Search */
  dataSearchedClientBranchs: [],
  fetchSearchedClientBranchStatus: false,
  fetchSearchedClientBranchCode: 0,
  fetchSearchedClientBranchMessage: '',
  isSearchedClientBranchFetching: true,

  /* Client Branch CRUD */
  dataAddClientBranch: {},
  fetchAddClientBranchStatus: false,
  fetchAddClientBranchCode: 0,
  fetchAddClientBranchMessage: '',

  dataUpdateClientBranch: {},
  fetchUpdateClientBranchStatus: false,
  fetchUpdateClientBranchCode: 0,
  fetchUpdateClientBranchMessage: '',

  deleteClientBranch: {},
  fetchDeleteClientBranchStatus: false,
  fetchDeleteClientBranchCode: 0,
  fetchDeleteClientBranchMessage: '',

  /* Client Branch Devices  */
  dataClientBranchDevices: [],

  /* Client Branch Device CRUD */
  addClientBranchDeviceData: {},
  updateClientBranchDevice: {},
  deleteClientBranchDevice: {},

  /* Client Warehouse */
  dataClientWarehouses: [],
  fetchClientWarehouseStatus: false,
  fetchClientWarehouseCode: 0,
  fetchClientWarehouseMessage: '',

  /* Client Warehouse CRUD */
  dataAddClientWarehouse: {},
  fetchAddClientWarehouseStatus: false,
  fetchAddClientWarehouseCode: 0,
  fetchAddClientWarehouseMessage: '',

  dataUpdateClientWarehouse: {},
  fetchUpdateClientWarehouseStatus: false,
  fetchUpdateClientWarehouseCode: 0,
  fetchUpdateClientWarehouseMessage: '',

  deleteClientWarehouse: {},
  fetchDeleteClientWarehouseStatus: false,
  fetchDeleteClientWarehouseCode: 0,
  fetchDeleteClientWarehouseMessage: '',

  /* Fetch Status of Client Reducer */
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: '',
  fetchingClientProductVariant: true,
  fetchingClientProductWarehouse: true,

  /* Fetch Status of Client Location List */
  fetchClientLocStatus: false,
  fetchClientLocCode: 0,
  fetchClientLocMessage: '',

  /* Fetch Status of Client Product List */
  fetchClientProductListStatus: false,
  fetchClientProductListCode: 0,
  fetchClientProductListMessage: '',

  // Client Branch Fetching State
  isClientBranchAdding: false,
  isClientBranchUpdating: false,
  isClientBranchDeleting: false,
  isClientBranchFetching: true,

  // Client Branch Device Fetching State
  isClientBranchDeviceAdding: false,
  isClientBranchDeviceUpdating: false,
  isClientBranchDeviceDeleting: false,
  isClientBranchDeviceFetching: true,

  // Client Warehouse Fetching State
  isClientWarehouseAdding: false,
  isClientWarehouseUpdating: false,
  isClientWarehouseDeleting: false,
  isClientWarehouseFetching: true,

  /* Client User CRUD */

  isClientUserFetching: true,
  dataClientUsers: [],
  fetchClientUserCode: 0,
  fetchClientUserStatus: false,
  fetchClientUserMessage: '',

  isClientUserAdding: false,
  addClientUserData: {},
  fetchAddClientUserCode: 0,
  fetchAddClientUserStatus: false,
  fetchAddClientUserMessage: '',

  isClientUserUpdating: false,
  updateClientUserData: {},
  fetchUpdateClientUserCode: 0,
  fetchUpdateClientUserStatus: false,
  fetchUpdateClientUserMessage: '',

  isClientUserDeleting: false,

  // Client Loc Fetchingstate
  isClientLocFetching: true,

  // Client Product List Fetching State
  isClientProductListFetching: true,

  isClientFetching: true,
  isClientProductFetching: true,
  isFetching: true,
};
function clients(state = initialState, action) {
  switch (action.type) {
    /* Client List Action Case */
    case CLIENT_LIST_FECTHING:
      return {
        ...state,
        isClientFetching: true,
      };
    case CLIENT_LIST_FETCHED:
      return {
        ...state,
        isClientFetching: false,
        clientLists: action.payload.payload.data.map((data) => ({
          data: data,
          id: data.id,
          name: data.name,
        })),
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENT_LIST_FAIL:
      return {
        ...state,
        isClientFetching: false,
        clientLists: action.payload,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENT_LIST_ERROR:
      return {
        ...state,
        isClientFetching: false,
        fetchMessage: action.payload,
      };
    /* Client Loc List Action Case */
    case CLIENTLOC_LIST_FECTHING:
      return {
        ...state,
        isClientLocFetching: true,
      };
    case CLIENTLOC_LIST_FETCHED:
      return {
        ...state,
        isClientLocFetching: false,
        clientLocLists: action.payload.payload.data.map((data) => ({
          data: data,
          id: data.id,
          name: data.name,
        })),
        fetchClientLocCode: action.payload.code,
        fetchClientLocStatus: action.payload.success,
        fetchClientLocMessage: action.payload.message,
      };
    case CLIENTLOC_LIST_FAIL:
      return {
        ...state,
        isClientLocFetching: false,
        clientLocLists: action.payload,
        fetchClientLocCode: action.payload.code,
        fetchClientLocStatus: action.payload.success,
        fetchClientLocMessage: action.payload.message,
      };
    case CLIENTLOC_LIST_ERROR:
      return {
        ...state,
        isClientLocFetching: false,
        fetchClientLocMessage: action.payload,
      };

    /* Client Product List Action Case */
    case CLIENTPRODUCT_LIST_FECTHING:
      return {
        ...state,
        isClientProductListFetching: true,
      };
    case CLIENTPRODUCT_LIST_FETCHED:
      const constructClientProductListData = {
        clientProductListData: action.payload.payload.data,
        clientProductListDataLength: action.payload.payload.data.length,
      };
      return {
        ...state,
        isClientProductListFetching: false,
        clientProductLists: constructClientProductListData,
        fetchClientProductListCode: action.payload.code,
        fetchClientProductListStatus: action.payload.success,
        fetchClientProductListMessage: action.payload.message,
      };
    case CLIENTPRODUCT_LIST_FAIL:
      return {
        ...state,
        isClientProductListFetching: false,
        clientProductLists: action.payload,
        fetchClientProductListCode: action.payload.code,
        fetchClientProductListStatus: action.payload.success,
        fetchClientProductListMessage: action.payload.message,
      };
    case CLIENTPRODUCT_LIST_ERROR:
      return {
        ...state,
        isClientProductListFetching: false,
        fetchClientProductListMessage: action.payload,
      };

    /* Client Fetching */
    case CLIENT_FECTHING:
      return {
        ...state,
        isFetching: true,
      };
    case CLIENT_FETCHED:
      const constructData = {
        clientData: action.payload.payload.data,
        clientDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isFetching: false,
        dataClients: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };

    case CLIENT_SEARCH_FETCHING:
      return {
        ...state,
        isSearchedClientFetching: true,
      };
    case CLIENT_SEARCH_FETCHED:
      const constructSearchedClientData = {
        clientData: action.payload.payload.data,
        clientDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isSearchedClientFetching: false,
        dataSearchedClients: constructSearchedClientData,
        fetchSearchedClientStatus: action.payload.success,
        fetchSearchedClientCode: action.payload.code,
        fetchSearchedClientMessage: action.payload.message,
      };
    case CLIENT_SEARCH_FAIL:
      return {
        ...state,
        isSearchedClientFetching: false,
        fetchSearchedClientStatus: action.payload.success,
        fetchSearchedClientCode: action.payload.code,
        fetchSearchedClientMessage: action.payload.message,
      };
    case CLIENT_SEARCH_ERROR:
      return {
        ...state,
        isSearchedClientFetching: false,
        fetchSearchedClientCode: action.payload.code,
        fetchSearchedClientMessage: action.payload.message,
      };
    /* Client CRUD */
    case CLIENT_ADD_INIT:
      return {
        ...state,
        fetchAddClientCode: 0,
        fetchAddClientStatus: false,
        fetchAddClientMessage: '',
      };
    case CLIENT_ADDING:
      return {
        ...state,
        isClientAdding: true,
      };
    case CLIENT_ADDED:
      return {
        ...state,
        isClientAdding: false,
        addClients: action.payload,
        dataClients: state.dataClients,
        fetchAddClientCode: action.payload.code,
        fetchAddClientStatus: action.payload.success,
        fetchAddClientMessage: action.payload.message,
      };
    case CLIENT_ADD_FAIL:
      return {
        ...state,
        isClientAdding: false,
        fetchAddClientCode: action.payload.code,
        fetchAddClientStatus: action.payload.success,
        fetchAddClientMessage: action.payload.message,
      };
    case CLIENT_ADD_ERROR:
      return {
        ...state,
        isClientAdding: false,
        fetchAddClientMessage: action.payload.message,
      };
    case CLIENT_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateClientCode: 0,
        fetchUpdateClientStatus: false,
        fetchUpdateClientMessage: '',
      };
    case CLIENT_UPDATE:
      return {
        ...state,
        isClientUpdating: false,
        updateClients: action.payload,
        fetchUpdateClientCode: action.payload.code,
        fetchUpdateClientStatus: action.payload.success,
        fetchUpdateClientMessage: action.payload.message,
      };
    case CLIENT_DELETING:
      return {
        ...state,
        isClientDeleting: false,
      };
    case CLIENT_DELETED:
      return {
        ...state,
        isClientDeleting: false,
        deleteClients: action.payload,
        dataClients: state.dataClients,
        fetchDeleteClientCode: action.payload.code,
        fetchDeleteClientStatus: action.payload.success,
        fetchDeleteClientMessage: action.payload.message,
      };
    case CLIENT_DELETE_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchDeleteClientCode: action.payload.code,
        fetchDeleteClientStatus: action.payload.success,
        fetchDeleteClientMessage: action.payload.message,
      };
    case CLIENT_DELETE_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchDeleteClientMessage: action.payload.message,
      };
    case CLIENT_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENT_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload,
      };
    /* Client Product Fetch */
    case CLIENT_PRODUCT_FECTHING:
      return {
        ...state,
        isClientProductFetching: true,
      };
    case CLIENT_PRODUCT_FETCHED:
      const constructClientProductData = {
        clientProductData: action.payload.payload.data,
        clientProductDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isClientProductFetching: false,
        dataClientProducts: constructClientProductData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENT_PRODUCT_ADDING_PROFILE:
      return {
        ...state,
        isClientProductFetching: true,
      };
    case CLIENT_PRODUCT_ADDED_PROFILE:
      return {
        ...state,
        isClientProductFetching: false,
      };
    case CLIENT_PRODUCT_DELETING_PROFILE:
      return {
        ...state,
        isClientProductFetching: true,
      };
    case CLIENT_PRODUCT_DELETED_PROFILE:
      return {
        ...state,
        isClientProductFetching: false,
      };
    case CLIENT_PRODUCT_UPDATING:
      return {
        ...state,
        isClientProductFetching: true,
      };
    case CLIENT_PRODUCT_UPDATED:
      return {
        ...state,
        isClientProductFetching: false,
      };
    case CLIENT_PRODUCT_DELETING:
      return {
        ...state,
        isClientProductFetching: true,
      };
    case CLIENT_PRODUCT_DELETED:
      return {
        ...state,
        isClientProductFetching: false,
      };
    case CLIENT_PRODUCT_FAIL:
      return {
        ...state,
        isClientProductFetching: false,
      };
    case CLIENT_PRODUCT_ERROR:
      return {
        ...state,
        isClientProductFetching: false,
      };
    case CLIENT_VARIANT_FETCHING:
      return {
        ...state,
        fetchingClientProductVariant: true,
      };
    case CLIENT_VARIANT_FETCHED:
      return {
        ...state,
        fetchingClientProductVariant: false,
        fetchedClientProductVariant: action.payload.payload.data,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENT_VARIANT_FAIL:
      return {
        ...state,
        fetchingClientProductVariant: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENT_VARIANT_ERROR:
      return {
        ...state,
        fetchingClientProductVariant: false,
        fetchMessage: action.payload.message,
      };
    case CLIENT_USER_FECTHING:
      return {
        ...state,
        isClientUserFetching: true,
      };
    case CLIENT_USER_FETCHED:
      const constructClientUsers = {
        clientUserData: action.payload.payload.data,
        clientUserDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isClientUserFetching: false,
        dataClientUsers: constructClientUsers,
        fetchClientUserCode: action.payload.code,
        fetchClientUserStatus: action.payload.success,
        fetchClientUserMessage: action.payload.message,
      };
    case CLIENT_USER_FAIL:
      return {
        ...state,
        isClientUserFetching: false,
        fetchClientUserCode: action.payload.code,
        fetchClientUserStatus: action.payload.success,
        fetchClientUserMessage: action.payload.message,
      };
    case CLIENT_USER_ERROR:
      return {
        ...state,
        isClientUserFetching: false,
        fetchClientUserCode: action.payload.code,
        fetchClientUserMessage: action.payload.message,
      };
    case CLIENTUSER_ADD_INIT:
      return {
        ...state,
        fetchAddClientUserCode: 0,
        fetchAddClientUserStatus: false,
        fetchAddClientUserMessage: '',
      };
    case CLIENTUSER_ADD_FECTHING:
      return {
        ...state,
        isClientUserAdding: true,
      };
    case CLIENTUSER_ADD_FETCHED:
      return {
        ...state,
        isClientUserAdding: false,
        addClientUserData: action.payload.payload,
        fetchAddClientUserCode: action.payload.code,
        fetchAddClientUserStatus: action.payload.success,
        fetchAddClientUserMessage: action.payload.message,
      };
    case CLIENTUSER_ADD_FAIL:
      return {
        ...state,
        isClientUserAdding: false,
        fetchAddClientUserCode: action.payload.code,
        fetchAddClientUserStatus: action.payload.success,
        fetchAddClientUserMessage: action.payload.message,
      };
    case CLIENTUSER_ADD_ERROR:
      return {
        ...state,
        isClientUserAdding: false,
        fetchAddClientUserCode: action.payload.code,
        fetchAddClientUserMessage: action.payload.message,
      };
    case CLIENTUSER_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateClientUserCode: 0,
        fetchUpdateClientUserStatus: false,
        fetchUpdateClientUserMessage: '',
      };
    case CLIENTUSER_UPDATE_FECTHING:
      return {
        ...state,
        isClientUserUpdating: true,
      };
    case CLIENTUSER_UPDATE_FETCHED:
      return {
        ...state,
        isClientUserUpdating: false,
        updateClientUserData: action.payload.payload,
        dataClientUsers: state.dataClientUsers,
        fetchUpdateClientUserCode: action.payload.code,
        fetchUpdateClientUserStatus: action.payload.success,
        fetchUpdateClientUserMessage: action.payload.message,
      };
    case CLIENTUSER_UPDATE_FAIL:
      return {
        ...state,
        isClientUserUpdating: false,
        fetchUpdateClientUserCode: action.payload.code,
        fetchUpdateClientUserStatus: action.payload.success,
        fetchUpdateClientUserMessage: action.payload.message,
      };
    case CLIENTUSER_UPDATE_ERROR:
      return {
        ...state,
        isClientUserUpdating: false,
        fetchUpdateClientUserCode: action.payload.code,
        fetchUpdateClientUserMessage: action.payload.message,
      };
    case CLIENTUSER_DELETE_FECTHING:
      return {
        ...state,
        isClientUserDeleting: true,
      };
    case CLIENTUSER_DELETE_FETCHED:
      return {
        ...state,
        isClientUserDeleting: false,
        deleteClientUserData: action.payload.payload,
        dataClientUsers: state.dataClientUsers,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENTUSER_DELETE_FAIL:
      return {
        ...state,
        isClientUserDeleting: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message,
      };
    case CLIENTUSER_DELETE_ERROR:
      return {
        ...state,
        isClientUserDeleting: false,
        fetchMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_SEARCH_FETCHING:
      return {
        ...state,
        isSearchClientHOFetching: true,
      };
    case CLIENT_HEADOFFICE_SEARCH_FETCHED:
      const constructSearchedClientHOData = {
        clientHeadOfficeData: action.payload.payload.data,
        clientHeadOfficeDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isSearchClientHOFetching: false,
        dataSearchClientHOs: constructSearchedClientHOData,
        fetchSearchClientHOStatus: action.payload.success,
        fetchSearchClientHOCode: action.payload.code,
        fetchSearchClientHOMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_SEARCH_FAIL:
      return {
        ...state,
        isSearchClientHOFetching: false,
        fetchSearchClientHOStatus: action.payload.success,
        fetchSearchClientHOCode: action.payload.code,
        fetchSearchClientHOMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_SEARCH_ERROR:
      return {
        ...state,
        isSearchClientHOFetching: false,
        fetchSearchClientHOCode: action.payload.code,
        fetchSearchClientHOMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_FETCHING:
      return {
        ...state,
        isDataClientHeadOfficeFetching: true,
      };
    case CLIENT_HEADOFFICE_FETCHED:
      const constructClientHeadOffices = {
        clientHeadOfficeData: action.payload.payload.data,
        clientHeadOfficeDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isDataClientHeadOfficeFetching: false,
        dataClientHeadOffices: constructClientHeadOffices,
        fetchClientHeadOfficeStatus: action.payload.success,
        fetchClientHeadOfficeCode: action.payload.code,
        fetchClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_FAIL:
      return {
        ...state,
        isDataClientHeadOfficeFetching: false,
        fetchClientHeadOfficeStatus: action.payload.success,
        fetchClientHeadOfficeCode: action.payload.code,
        fetchClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_ERROR:
      return {
        ...state,
        isDataClientHeadOfficeFetching: false,
        fetchClientHeadOfficeCode: action.payload.code,
        fetchClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_LIST_FETCHING:
      return {
        ...state,
        isDataClientHOListFetching: true,
      };
    case CLIENT_HEADOFFICE_LIST_FETCHED:
      return {
        ...state,
        isDataClientHOListFetching: false,
        dataClientHOLists: action.payload.payload.data,
        fetchClientHOListStatus: action.payload.success,
        fetchClientHOListCode: action.payload.code,
        fetchClientHOListMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_LIST_FAIL:
      return {
        ...state,
        isDataClientHOListFetching: false,
        fetchClientHOListStatus: action.payload.success,
        fetchClientHOListCode: action.payload.code,
        fetchClientHOListMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_LIST_ERROR:
      return {
        ...state,
        isDataClientHOListFetching: false,
        fetchClientHOListCode: action.payload.code,
        fetchClientHOListMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_ADD_INIT:
      return {
        ...state,
        fetchAddClientHeadOfficeStatus: false,
        fetchAddClientHeadOfficeCode: 0,
        fetchAddClientHeadOfficeMessage: '',
      };
    case CLIENT_HEADOFFICE_ADD_FECTHING:
      return {
        ...state,
        isAddClientHeadOffice: true,
      };
    case CLIENT_HEADOFFICE_ADD_FETCHED:
      return {
        ...state,
        isAddClientHeadOffice: false,
        dataAddClientHeadOffice: action.payload.payload,
        fetchAddClientHeadOfficeStatus: action.payload.status,
        fetchAddClientHeadOfficeCode: action.payload.code,
        fetchAddClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_ADD_FAIL:
      return {
        ...state,
        isAddClientHeadOffice: false,
        fetchAddClientHeadOfficeStatus: action.payload.status,
        fetchAddClientHeadOfficeCode: action.payload.code,
        fetchAddClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_ADD_ERROR:
      return {
        ...state,
        isAddClientHeadOffice: false,
        fetchAddClientHeadOfficeCode: action.payload.code,
        fetchAddClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateClientHeadOfficeStatus: false,
        fetchUpdateClientHeadOfficeCode: 0,
        fetchUpdateClientHeadOfficeMessage: '',
      };
    case CLIENT_HEADOFFICE_UPDATE_FECTHING:
      return {
        ...state,
        isUpdateClientHeadOffice: true,
      };
    case CLIENT_HEADOFFICE_UPDATE_FETCHED:
      return {
        ...state,
        isUpdateClientHeadOffice: false,
        dataUpdateClientHeadOffice: action.payload.payload,
        fetchUpdateClientHeadOfficeStatus: action.payload.status,
        fetchUpdateClientHeadOfficeCode: action.payload.code,
        fetchUpdateClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_UPDATE_FAIL:
      return {
        ...state,
        fetchUpdateClientHeadOfficeStatus: action.payload.status,
        fetchUpdateClientHeadOfficeCode: action.payload.code,
        fetchUpdateClientHeadOfficeMessage: action.payload.message,
      };
    case CLIENT_HEADOFFICE_UPDATE_ERROR:
      return {
        ...state,
        fetchUpdateClientHeadOfficeCode: action.payload.code,
        fetchUpdateClientHeadOfficeMessage: action.payload.message,
      };

    case CLIENT_BRANCH_FECTHING:
      return {
        ...state,
        isClientBranchFetching: true,
      };
    case CLIENT_BRANCH_FETCHED:
      const constructClientBranchs = {
        clientBranchData: action.payload.payload.data,
        clientBranchDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isClientBranchFetching: false,
        dataClientBranchs: constructClientBranchs,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENT_BRANCH_FAIL:
      return {
        ...state,
        isClientBranchFetching: false,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENT_BRANCH_ERROR:
      return {
        ...state,
        isClientBranchFetching: false,
        fetchMessage: action.payload.message,
      };

    case CLIENTBRANCH_SEARCH_FETCHING:
      return {
        ...state,
        isSearchedClientBranchFetching: true,
      };
    case CLIENTBRANCH_SEARCH_FETCHED:
      const constructSearchedClientBranchData = {
        clientBranchData: action.payload.payload.data,
        clientBranchDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isSearchedClientBranchFetching: false,
        dataSearchedClientBranchs: constructSearchedClientBranchData,
        fetchSearchedClientBranchStatus: action.payload.success,
        fetchSearchedClientBranchCode: action.payload.code,
        fetchSearchedClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_SEARCH_FAIL:
      return {
        ...state,
        isSearchedClientBranchFetching: false,
        fetchSearchedClientBranchStatus: action.payload.success,
        fetchSearchedClientBranchCode: action.payload.code,
        fetchSearchedClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_SEARCH_ERROR:
      return {
        ...state,
        isSearchedClientBranchFetching: false,
        fetchSearchedClientBranchCode: action.payload.code,
        fetchSearchedClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_ADD_INIT:
      return {
        ...state,
        fetchAddClientBranchStatus: false,
        fetchAddClientBranchCode: 0,
        fetchAddClientBranchMessage: '',
      };
    case CLIENTBRANCH_ADD_FECTHING:
      return {
        ...state,
        isClientBranchAdding: true,
      };
    case CLIENTBRANCH_ADD_FETCHED:
      return {
        ...state,
        isClientBranchAdding: false,
        dataAddClientBranch: action.payload.payload,
        fetchAddClientBranchStatus: action.payload.success,
        fetchAddClientBranchCode: action.payload.code,
        fetchAddClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_ADD_FAIL:
      return {
        ...state,
        isClientBranchAdding: false,
        fetchAddClientBranchStatus: action.payload.success,
        fetchAddClientBranchCode: action.payload.code,
        fetchAddClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_ADD_ERROR:
      return {
        ...state,
        isClientBranchAdding: false,
        fetchAddClientBranchCode: action.payload.code,
        fetchAddClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateClientBranchStatus: false,
        fetchUpdateClientBranchCode: 0,
        fetchUpdateClientBranchMessage: '',
      };
    case CLIENTBRANCH_UPDATE_FECTHING:
      return {
        ...state,
        isClientBranchUpdating: true,
      };
    case CLIENTBRANCH_UPDATE_FETCHED:
      return {
        ...state,
        isClientBranchUpdating: false,
        dataUpdateClientBranch: action.payload.payload,
        fetchUpdateClientBranchStatus: action.payload.success,
        fetchUpdateClientBranchCode: action.payload.code,
        fetchUpdateClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_UPDATE_FAIL:
      return {
        ...state,
        isClientBranchUpdating: false,
        fetchUpdateClientBranchStatus: action.payload.success,
        fetchUpdateClientBranchCode: action.payload.code,
        fetchUpdateClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_UPDATE_ERROR:
      return {
        ...state,
        isClientBranchUpdating: false,
        fetchUpdateClientBranchCode: action.payload.code,
        fetchUpdateClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DELETE_FECTHING:
      return {
        ...state,
        isClientBranchDeleting: true,
      };
    case CLIENTBRANCH_DELETE_FETCHED:
      return {
        ...state,
        isClientBranchDeleting: false,
        deleteClientBranch: action.payload.payload,
        dataClientBranchs: state.dataClientBranchs,
        fetchDeleteClientBranchStatus: action.payload.success,
        fetchDeleteClientBranchCode: action.payload.code,
        fetchDeleteClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DELETE_FAIL:
      return {
        ...state,
        isClientBranchDeleting: false,
        fetchDeleteClientBranchStatus: action.payload.success,
        fetchDeleteClientBranchCode: action.payload.code,
        fetchDeleteClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DELETE_ERROR:
      return {
        ...state,
        isClientBranchDeleting: false,
        fetchDeleteClientBranchCode: action.payload.code,
        fetchDeleteClientBranchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DEVICE_FECTHING:
      return {
        ...state,
        isClientBranchDeviceFetching: true,
      };
    case CLIENTBRANCH_DEVICE_FETCHED:
      const constructBranchDevice = Object.assign(
        [action.payload.payload],
        [action.payload.payload].forEach((item, i) => {
          item.no = i + 1;
        })
      );
      return {
        ...state,
        isClientBranchDeviceFetching: false,
        dataClientBranchDevices: constructBranchDevice,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DEVICE_FAIL:
      return {
        ...state,
        isClientBranchDeviceFetching: false,
        dataClientBranchDevices: action.payload.payload,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DEVICE_ERROR:
      return {
        ...state,
        isClientBranchDeviceFetching: false,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_ADD_DEVICE_FECTHING:
      return {
        ...state,
        isClientBranchAdding: true,
      };
    case CLIENTBRANCH_ADD_DEVICE_FETCHED:
      return {
        ...state,
        isClientBranchAdding: false,
        addClientBranchDeviceData: action.payload.payload,
        dataClientBranchDevices: state.dataClientBranchDevices,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_ADD_DEVICE_FAIL:
      return {
        ...state,
        isClientBranchAdding: false,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_ADD_DEVICE_ERROR:
      return {
        ...state,
        isClientBranchAdding: false,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_UPDATE_DEVICE_FECTHING:
      return {
        ...state,
        isClientBranchUpdating: true,
      };
    case CLIENTBRANCH_UPDATE_DEVICE_FETCHED:
      return {
        ...state,
        isClientBranchUpdating: false,
        updateClientBranchDevice: action.payload.payload,
        dataClientBranchDevices: state.dataClientBranchDevices,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_UPDATE_DEVICE_FAIL:
      return {
        ...state,
        isClientBranchUpdating: false,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_UPDATE_DEVICE_ERROR:
      return {
        ...state,
        isClientBranchUpdating: false,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DELETE_DEVICE_FECTHING:
      return {
        ...state,
        isClientBranchDeleting: true,
      };
    case CLIENTBRANCH_DELETE_DEVICE_FETCHED:
      return {
        ...state,
        isClientBranchDeleting: false,
        deleteClientBranchDevice: action.payload.payload,
        dataClientBranchDevices: state.dataClientBranchDevices,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DELETE_DEVICE_FAIL:
      return {
        ...state,
        isClientBranchDeleting: false,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTBRANCH_DELETE_DEVICE_ERROR:
      return {
        ...state,
        isClientBranchDeleting: false,
        fetchMessage: action.payload.message,
      };
    case CLIENT_WAREHOUSE_FETCHING:
      return {
        ...state,
        isClientWarehouseFetching: true,
      };
    case CLIENT_WAREHOUSE_FETCHED:
      const constructClientWarehouses = {
        clientWarehouseData: action.payload.payload.data,
        clientWarehouseDataLength: action.payload.payload.total,
      };
      return {
        ...state,
        isClientWarehouseFetching: false,
        dataClientWarehouses: constructClientWarehouses,
        fetchClientWarehouseStatus: action.payload.success,
        fetchClientWarehouseCode: action.payload.code,
        fetchClientWarehouseMessage: action.payload.message,
      };
    case CLIENT_WAREHOUSE_FAIL:
      return {
        ...state,
        isClientWarehouseFetching: false,
        fetchClientWarehouseStatus: action.payload.success,
        fetchClientWarehouseCode: action.payload.code,
        fetchClientWarehouseMessage: action.payload.message,
      };
    case CLIENT_WAREHOUSE_ERROR:
      return {
        ...state,
        isClientWarehouseFetching: false,
        fetchClientWarehouseCode: action.payload.code,
        fetchClientWarehouseMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_ADD_INIT:
      return {
        ...state,
        fetchAddClientWarehouseStatus: false,
        fetchAddClientWarehouseCode: 0,
        fetchAddClientWarehouseMessage: '',
      };
    case CLIENTWAREHOUSE_ADD_FETCHING:
      return {
        ...state,
        isClientWarehouseAdding: true,
      };
    case CLIENTWAREHOUSE_ADD_FETCHED:
      return {
        ...state,
        isClientWarehouseAdding: false,
        dataAddClientWarehouse: action.payload.payload,
        fetchAddClientWarehouseStatus: action.payload.success,
        fetchAddClientWarehouseCode: action.payload.code,
        fetchAddClientWarehouseMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_ADD_FAIL:
      return {
        ...state,
        isClientWarehouseAdding: false,
        fetchAddClientWarehouseStatus: action.payload.success,
        fetchAddClientWarehouseCode: action.payload.code,
        fetchAddClientWarehouseMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_ADD_ERROR:
      return {
        ...state,
        isClientWarehouseAdding: false,
        fetchAddClientWarehouseCode: action.payload.code,
        fetchAddClientWarehouseMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateClientWarehouseStatus: false,
        fetchUpdateClientWarehouseCode: 0,
        fetchUpdateClientWarehouseMessage: '',
      };
    case CLIENTWAREHOUSE_UPDATE_FETCHING:
      return {
        ...state,
        isClientWarehouseUpdating: true,
      };
    case CLIENTWAREHOUSE_UPDATE_FETCHED:
      return {
        ...state,
        isClientWarehouseUpdating: false,
        dataUpdateClientWarehouse: action.payload.payload,
        fetchUpdateClientWarehouseStatus: action.payload.success,
        fetchUpdateClientWarehouseCode: action.payload.code,
        fetchUpdateClientWarehouseMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_UPDATE_FAIL:
      return {
        ...state,
        isClientWarehouseUpdating: false,
        fetchUpdateClientWarehouseStatus: action.payload.success,
        fetchUpdateClientWarehouseCode: action.payload.code,
        fetchUpdateClientWarehouseMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_UPDATE_ERROR:
      return {
        ...state,
        isClientWarehouseUpdating: false,
        fetchUpdateClientWarehouseCode: action.payload.code,
        fetchUpdateClientWarehouseMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_DELETE_FETCHING:
      return {
        ...state,
        isClientWarehouseDeleting: true,
      };
    case CLIENTWAREHOUSE_DELETE_FETCHED:
      return {
        ...state,
        isClientWarehouseDeleting: false,
        dataClientWarehouses: state.dataClientWarehouses,
        deleteClientWarehouse: action.payload.payload,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_DELETE_FAIL:
      return {
        ...state,
        isClientWarehouseDeleting: false,
        fetchStatus: action.payload.success,
        fetchCode: action.payload.code,
        fetchMessage: action.payload.message,
      };
    case CLIENTWAREHOUSE_DELETE_ERROR:
      return {
        ...state,
        isClientWarehouseDeleting: false,
        fetchMessage: action.payload.message,
      };
    default:
      break;
  }

  return state;
}

export default clients;
