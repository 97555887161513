import { SET_PRODUCT_HIGHLIGHT, SET_PUBLISH_PRODUCT_HIGHLIGHT, SET_UNPUBLISH_PRODUCT_HIGHLIGHT, SET_FETCHING } from '../actions/types';

const initialState = {
    productHighlight: [],
    productPublishHighlight:[],
    isFetching: false,
}

const productHighlightReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCT_HIGHLIGHT:
            return {
                ...state,
                productHighlight: action.payload
            }
        case SET_PUBLISH_PRODUCT_HIGHLIGHT:
            return {
                ...state,
                productPublishHighlight: action.payload
            }
        case SET_UNPUBLISH_PRODUCT_HIGHLIGHT:
            return {
                ...state,
                productUnpublishHighlight: action.payload
            }
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            }
        default: 
            return state;
    }
}

export default productHighlightReducer;