import {
    TRANSREPORT_VIRTUAL_FECTHING,
    TRANSREPORT_VIRTUAL_FETCHED,
    TRANSREPORT_VIRTUAL_FAIL,
    TRANSREPORT_VIRTUAL_ERROR,
    CLIENT_LIST_FECTHING,
    CLIENT_LIST_FETCHED,
    CLIENT_LIST_FAIL,
    CLIENT_LIST_ERROR,
    TRANSREPORT_VIRTUAL_EXPORTING,
    TRANSREPORT_VIRTUAL_EXPORTED,
    TRANSREPORT_VIRTUAL_EXPORT_FAIL,
    TRANSREPORT_VIRTUAL_EXPORT_ERROR
} from "../actions/types";

const initialState = {
    dataClients: [],
    isClientFetching: true,
    fetchStatus: false,
    fetchCode: 0,
    fetchMessage: "",
    isFetching: true,

    dataVirtualReports: [],
    fetchVirtualReportStatus: false,
    fetchVirtualReportCode: 0,
    fetchVirtualReportMessage: "",
    isVirtualReportFetching: true,

    isExportDataVirtualFetching: true,
    dataVirtualExports: {},
    fetchExportDataVirtualStatus: false,
    fetchExportDataVirtualCode: 0,
    fetchExportDataVirtualMessage: ""
};

function transReportVirtualReducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT_LIST_FECTHING:
            return {
                ...state,
                isClientFetching: true
            };
        case CLIENT_LIST_FETCHED:
            return {
                ...state,
                isClientFetching: false,
                dataClients: action.payload.payload.data.map(data => ({
                    value: data.id,
                    label: data.name,
                    client: data
                })),
                fetchCode: action.payload.code,
                fetchStatus: action.payload.success,
                fetchMessage: action.payload.message
            };
        case CLIENT_LIST_FAIL:
            return {
                ...state,
                isClientFetching: false,
                dataClients: action.payload.payload.data,
                fetchCode: action.payload.code,
                fetchStatus: action.payload.success,
                fetchMessage: action.payload.message
            };
        case CLIENT_LIST_ERROR:
            return {
                ...state,
                isClientFetching: false,
                fetchMessage: action.payload
            };
        case TRANSREPORT_VIRTUAL_FECTHING:
            return {
                ...state,
                isVirtualReportFetching: true,
            }
        case TRANSREPORT_VIRTUAL_FETCHED:
            const extractForExportVirtual = action.payload.payload.data.map(item => {
                const tempObj = Object.assign({}, item);
                delete tempObj.opened;
                return tempObj;
            });

            const constructVirtualData = {
                reportVirtualData: action.payload.payload.data,
                reportVirtualDataExport: extractForExportVirtual,
                reportVirtualDataLength: action.payload.payload.total
            };

            return {
                ...state,
                isVirtualReportFetching: false,
                dataVirtualReports: constructVirtualData,
                fetchVirtualReportStatus: action.payload.success,
                fetchVirtualReportCode: action.payload.code,
                fetchVirtualReportMessage: action.payload.message,
            }
        case TRANSREPORT_VIRTUAL_FAIL:
            return {
                ...state,
                isVirtualReportFetching: false,
                fetchVirtualReportStatus: action.payload.success,
                fetchVirtualReportCode: action.payload.code,
                fetchVirtualReportMessage: action.payload.message
            }
        case TRANSREPORT_VIRTUAL_ERROR:
            return {
                ...state,
                isVirtualReportFetching: false,
                fetchVirtualReportCode: action.payload.code,
                fetchVirtualReportMessage: action.payload.message
            }
        case TRANSREPORT_VIRTUAL_EXPORTING:
            return {
                ...state,
                isExportDataVirtualFetching: true,
            }
        case TRANSREPORT_VIRTUAL_EXPORTED:
            return {
                ...state,
                isExportDataVirtualFetching: false,
                dataVirtualExports: action.payload.data,
                fetchExportDataVirtualStatus: action.payload.status,
                fetchExportDataVirtualCode: action.payload.code,
                fetchExportDataVirtualMessage: action.payload.message
            }
        case TRANSREPORT_VIRTUAL_EXPORT_FAIL:
            return {
                ...state,
                isExportDataVirtualFetching: false,
                fetchExportDataVirtualStatus: action.payload.status,
                fetchExportDataVirtualCode: action.payload.code,
                fetchExportDataVirtualMessage: action.payload.message
            }
        case TRANSREPORT_VIRTUAL_EXPORT_ERROR:
            return {
                ...state,
                isExportDataVirtualFetching: false,
                fetchExportDataVirtualCode: action.payload.code,
                fetchExportDataVirtualMessage: action.payload.message
            }

        default:
            break;
    }
    return state;
}

export default transReportVirtualReducer;