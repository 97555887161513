import {
  TRANS_ORDER_FECTHING,
  TRANS_ORDER_FETCHED,
  TRANS_ORDER_FAIL,
  TRANS_ORDER_ERROR,
  TRANS_ORDER_DETAIL_INIT,
  TRANS_ORDER_DETAIL_FECTHING,
  TRANS_ORDER_DETAIL_FETCHED,
  TRANS_ORDER_DETAIL_FAIL,
  TRANS_ORDER_DETAIL_ERROR,
  TRANS_ORDER_PICKUP_INIT,
  TRANS_ORDER_PICKUP_PROCESSING,
  TRANS_ORDER_PICKUP_PROCESSED,
  TRANS_ORDER_PICKUP_FAIL,
  TRANS_ORDER_PICKUP_ERROR,
  ORDER_TRACKING_DETAIL_INIT,
  ORDER_TRACKING_DETAIL_FECTHING,
  ORDER_TRACKING_DETAIL_FETCHED,
  ORDER_TRACKING_DETAIL_FAIL,
  ORDER_TRACKING_DETAIL_ERROR,
  ORDER_SEARCH_FETCHING,
  ORDER_SEARCH_FETCHED,
  ORDER_SEARCH_FAIL,
  ORDER_SEARCH_ERROR
} from "../actions/types";

import { payment_type, order_status } from "../helper/statusAPIParser";

const initialState = {
  dataTransOrders: [],
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true,

  dataSearchedOrders: [],
  fetchSearchedOrderStatus: false,
  fetchSearchedOrderCode: 0,
  fetchSearchedOrderMessage: "",
  isSearchedOrderFetching: true,

  fetchPickupStatus: false,
  fetchPickupCode: 0,
  fetchPickupMessage: "",
  isPickupFetching: true,

  dataTransOrderDetails: {},
  fetchDetailStatus: false,
  fetchDetailCode: 0,
  fetchDetailMessage: "",
  isDetailFetching: true,

  dataOrderTrackingDatas: {},
  fetchTrackingOrderStatus: false,
  fetchTrackingOrderCode: 0,
  fetchTrackingOrderMessage: "",
  isTrackingOrderFetching: true
};
function transOrder(state = initialState, action) {
  switch (action.type) {
    case TRANS_ORDER_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case TRANS_ORDER_FETCHED:
      const constructData = {
        orderData: Object.assign(
          action.payload.payload.data,
          action.payload.payload.data.forEach(item => {
            item.customer_name = item.customer.name;
            item.payment_type = payment_type(item.payment);
            item.order_status = order_status(item.status);
          })
        ),
        orderDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isFetching: false,
        dataTransOrders: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case TRANS_ORDER_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case TRANS_ORDER_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload.message
      };
    case ORDER_SEARCH_FETCHING:
      return {
        ...state,
        isSearchedOrderFetching: true
      }
    case ORDER_SEARCH_FETCHED:
      const constructSearchedData = {
        orderData: Object.assign(
          action.payload.payload.data,
          action.payload.payload.data.forEach(item => {
            item.customer_name = item.customer.name;
            item.payment_type = payment_type(item.payment);
            item.order_status = order_status(item.status);
          })
        ),
        orderDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isSearchedOrderFetching: false,
        dataSearchedOrders: constructSearchedData,
        fetchSearchedOrderStatus: action.payload.success,
        fetchSearchedOrderCode: action.payload.code,
        fetchSearchedOrderMessage: action.payload.message,
      }
    case ORDER_SEARCH_FAIL:
      return {
        ...state,
        isSearchedOrderFetching: false,
        fetchSearchedOrderStatus: action.payload.success,
        fetchSearchedOrderCode: action.payload.code,
        fetchSearchedOrderMessage: action.payload.message,
      }
    case ORDER_SEARCH_ERROR:
      return {
        ...state,
        isSearchedOrderFetching: false,
        fetchSearchedOrderMessage: action.payload.message,
      }
    case TRANS_ORDER_DETAIL_INIT:
      return {
        ...state,
        fetchDetailStatus: false,
        fetchDetailCode: 0,
        fetchDetailMessage: "",
      };
    case TRANS_ORDER_DETAIL_FECTHING:
      return {
        ...state,
        isDetailFetching: true
      };
    case TRANS_ORDER_DETAIL_FETCHED:
      return {
        ...state,
        dataTransOrderDetails: action.payload.payload,
        fetchDetailStatus: action.payload.success,
        fetchDetailCode: action.payload.code,
        fetchDetailMessage: action.payload.message,
        isDetailFetching: false
      };
    case TRANS_ORDER_DETAIL_FAIL:
      return {
        ...state,
        fetchDetailStatus: action.payload.success,
        fetchDetailCode: action.payload.code,
        fetchDetailMessage: action.payload.message,
        isDetailFetching: false
      };
    case TRANS_ORDER_DETAIL_ERROR:
      return {
        ...state,
        fetchDetailCode: action.payload.code,
        fetchDetailMessage: action.payload.message,
        isDetailFetching: false
      };
    case TRANS_ORDER_PICKUP_INIT:
      return {
        ...state,
        fetchPickupStatus: false,
        fetchPickupCode: 0,
        fetchPickupMessage: "",
      }
    case TRANS_ORDER_PICKUP_PROCESSING:
      return {
        ...state,
        isPickupFetching: true,
      }
    case TRANS_ORDER_PICKUP_PROCESSED:
      return {
        ...state,
        isPickupFetching: false,
        dataTransOrderDetails: state.dataTransOrderDetails,
        fetchPickupStatus: action.payload.success,
        fetchPickupCode: action.payload.code,
        fetchPickupMessage: action.payload.message,
      }
    case TRANS_ORDER_PICKUP_FAIL:
      return {
        ...state,
        isPickupFetching: false,
        fetchPickupStatus: action.payload.success,
        fetchPickupCode: action.payload.code,
        fetchPickupMessage: action.payload.message,
      }
    case TRANS_ORDER_PICKUP_ERROR:
      return {
        ...state,
        isPickupFetching: false,
        fetchPickupCode: action.payload.code,
        fetchPickupMessage: action.payload.message,
      }
    case ORDER_TRACKING_DETAIL_FECTHING:
      return {
        ...state,
        isTrackingOrderFetching: true
      };
    case ORDER_TRACKING_DETAIL_INIT:
      return {
        ...state,
        fetchTrackingOrderCode: 0,
        fetchTrackingOrderMessage: "",
        isTrackingOrderFetching: true
      }
    case ORDER_TRACKING_DETAIL_FETCHED:
      return {
        ...state,
        isTrackingOrderFetching: false,
        dataOrderTrackingDatas: action.payload.payload,
        fetchTrackingOrderStatus: action.payload.success,
        fetchTrackingOrderCode: action.payload.code,
        fetchTrackingOrderMessage: action.payload.message
      };
    case ORDER_TRACKING_DETAIL_FAIL:
      return {
        ...state,
        isTrackingOrderFetching: false,
        fetchTrackingOrderStatus: action.payload.success,
        fetchTrackingOrderCode: action.payload.code,
        fetchTrackingOrderMessage: action.payload.message
      };
    case ORDER_TRACKING_DETAIL_ERROR:
      return {
        ...state,
        isTrackingOrderFetching: false,
        fetchTrackingOrderMessage: action.payload.message
      };
    default:
      break;
  }
  return state;
}

export default transOrder;
