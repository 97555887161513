import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_ERROR,
  USER_LOGIN_CHECK,
  USER_LOGOUT
} from "../actions/types";

import { getCookieValue } from "../helper/cookieSetGet";

const initialState = [
  {
    users: [],
    session: null,
    authStatus: false,
    authCode: 0,
    authMessage: "",
    isSigning: false,
    isChecking: false
  }
];

function logins(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_START:
      return {
        ...state,
        isSigning: true
      };
    case USER_LOGIN_SUCCESS:
      const { code, success, message } = action.payload;
      return {
        ...state,
        authStatus: success,
        authCode: code,
        authMessage: message,
        session: getCookieValue(),
        isSigning: false
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        authStatus: action.payload.success,
        authCode: action.payload.code,
        authMessage: action.payload.message,
        isSigning: false
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        authCode: action.payload.code,
        authMessage: action.payload.message,
        isSigning: false
      };
    case USER_LOGIN_CHECK:
      return {
        ...state,
        session: action.payload,
        isChecking: false
      };
    case USER_LOGOUT:
      return {
        ...state,
        isChecking: false,
        session: action.payload
      };
    default:
      break;
  }
  return state;
}

export default logins;
