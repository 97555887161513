import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import chartReducer from './chartReducer';
import transOrderReducer from './transOrderReducer';
import transReportReducer from './transReportReducer';

import transOrderVirtualReducer from './transOrderVirtualReducer';
import transReportVirtualReducer from './transReportVirtualReducer';

import productReducer from './productReducer';
import productVirtualReducer from './productvirtualReducer';
import liveProductReducer from './liveproductReducer';
import davTouchReducer from './davTouchReducer';
import clientReducer from './clientReducer';
import domicileReducer from './domicileReducer';
import categoryReducer from './categoryReducer';
import variantReducer from './variantReducer';
import productprofileReducer from './productprofileReducer';
import voucherReducer from './voucherReducer';
import bannerReducer from './bannerReducer';
import productHighlightReducer from './productHighlightReducer';
import reportInteractionReducer from './reportInteractionReducer';

export default combineReducers({
  logins: loginReducer,
  charts: chartReducer,
  transOrder: transOrderReducer,
  transReport: transReportReducer,

  transOrderVirtual: transOrderVirtualReducer,
  transReportVirtual: transReportVirtualReducer,

  products: productReducer,
  productvirtuals: productVirtualReducer,
  liveproducts: liveProductReducer,
  davtouchs: davTouchReducer,
  clients: clientReducer,
  domiciles: domicileReducer,
  categories: categoryReducer,
  variants: variantReducer,
  productprofiles: productprofileReducer,
  vouchers: voucherReducer,
  banners: bannerReducer,
  productHighlight: productHighlightReducer,
  reportInteractions: reportInteractionReducer,
});
