export const payment_type = typePayment => {
  if (typePayment !== null) {
    switch (typePayment.type) {
      case 0:
        return "Cashier";
      case 1:
        return "EMoney";
      case 2:
        return "TMoney";
      case 3:
        return "Midtrans";
      default:
        return "Unknown";
    }
  } else {
    return "Unknown";
  }
};
export const payment_status = statusPayment => {
  switch (statusPayment) {
    case 0:
      return "Entry";
    case 1:
      return "Partial Paid";
    case 2:
      return "Paid";
    case 3:
      return "Refund";
    default:
      return "Unknown";
  }
};

export const order_status = statusOrder => {
  switch (statusOrder) {
    case 1:
      return "Waiting Payment";
    case 2:
      return "Paid";
    case 3:
      return "Ship";
    case 4:
      return "Done";
    case 5:
      return "Cancel Auto";
    case 6:
      return "Cancel CMS";
    default:
      return "Entry";
  }
};
