import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { getCookieValue } from "./helper/cookieSetGet";

// import "./App.css";
const LoginCMS = React.lazy(() => import("./components/Login"));
const Dashboard = React.lazy(() => import("./container/Dashboard"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

class App extends Component {
  render() {
    const redirect = getCookieValue();
    if (redirect === undefined) {
      return (
        <Router history={this.props.history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                path="/"
                name="Login Page"
                render={props => <LoginCMS {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      )
    } else {
      return (
        <Router history={this.props.history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={props => <LoginCMS {...props} />}
              />
              <Route
                path="/"
                name="Dashboard Content"
                render={props => <Dashboard {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      )
    }
  }
}

export default App;
