import {
  DAVTOUCH_VIDEOS_FETCHING,
  DAVTOUCH_VIDEOS_FETCHED,
  DAVTOUCH_VIDEOS_FAIL,
  DAVTOUCH_VIDEOS_ERROR,
  DAVTOUCH_GET_VIDEO_INIT,
  DAVTOUCH_GET_VIDEO_FETCHING,
  DAVTOUCH_GET_VIDEO_FETCHED,
  DAVTOUCH_GET_VIDEO_FAIL,
  DAVTOUCH_GET_VIDEO_ERROR,
  DAVTOUCH_ADD_VIDEOS_INIT,
  DAVTOUCH_ADD_VIDEOS_FETCHING,
  DAVTOUCH_ADD_VIDEOS_FETCHED,
  DAVTOUCH_ADD_VIDEOS_FAIL,
  DAVTOUCH_ADD_VIDEOS_ERROR,
  DAVTOUCH_EDIT_VIDEOS_INIT,
  DAVTOUCH_EDIT_VIDEOS_FETCHING,
  DAVTOUCH_EDIT_VIDEOS_FETCHED,
  DAVTOUCH_EDIT_VIDEOS_FAIL,
  DAVTOUCH_EDIT_VIDEOS_ERROR,
  DAVTOUCH_STATISTIC_VIDEOS_INIT,
  DAVTOUCH_STATISTIC_VIDEOS_FETCHING,
  DAVTOUCH_STATISTIC_VIDEOS_FETCHED,
  DAVTOUCH_STATISTIC_VIDEOS_FAIL,
  DAVTOUCH_STATISTIC_VIDEOS_ERROR,
  DAVTOUCH_VOUCHERS_FETCHING,
  DAVTOUCH_VOUCHERS_FETCHED,
  DAVTOUCH_VOUCHERS_FAIL,
  DAVTOUCH_VOUCHERS_ERROR,
  DAVTOUCH_ADD_VOUCHERS_INIT,
  DAVTOUCH_ADD_VOUCHERS_FETCHING,
  DAVTOUCH_ADD_VOUCHERS_FETCHED,
  DAVTOUCH_ADD_VOUCHERS_FAIL,
  DAVTOUCH_ADD_VOUCHERS_ERROR,
  DAVTOUCH_DELETE_VOUCHERS_INIT,
  DAVTOUCH_DELETE_VOUCHERS_FETCHING,
  DAVTOUCH_DELETE_VOUCHERS_FETCHED,
  DAVTOUCH_DELETE_VOUCHERS_FAIL,
  DAVTOUCH_DELETE_VOUCHERS_ERROR,
} from '../actions/types';

const initialState = {

  isDAVTouchVideosFetching: true,
  dataDAVTouchVideos: {},
  fetchDAVTouchVideosStatus: false,
  fetchDAVTouchVideosCode: 0,
  fetchDAVTouchVideosMessage: '',

  isGetDAVTouchVideoFetching: true,
  dataGetDAVTouchVideo: {},
  fetchGetDAVTouchVideoStatus: false,
  fetchGetDAVTouchVideoCode: 0,
  fetchGetDAVTouchVideoMessage: '',

  isAddDAVTouchVideoFetching: true,
  dataAddDAVTouchVideo: {},
  fetchAddDAVTouchVideoStatus: false,
  fetchAddDAVTouchVideoCode: 0,
  fetchAddDAVTouchVideoMessage: '',

  isUpdateDAVTouchVideoFetching: true,
  dataUpdateDAVTouchVideo: {},
  fetchUpdateDAVTouchVideoStatus: false,
  fetchUpdateDAVTouchVideoCode: 0,
  fetchUpdateDAVTouchVideoMessage: '',

  isStatisticsDAVTouchVideoFetching: true,
  dataStatisticsDAVTouchVideo: {},
  fetchStatisticsDAVTouchVideoStatus: false,
  fetchStatisticsDAVTouchVideoCode: 0,
  fetchStatisticsDAVTouchVideoMessage: '',

  isDAVTouchVoucherFetching: true,
  dataDAVTouchVouchers: {},
  fetchDAVTouchVoucherStatus: false,
  fetchDAVTouchVoucherCode: 0,
  fetchDAVTouchVoucherMessage: '',

  isAddDAVTouchVoucherFetching: true,
  dataAddDAVTouchVouchers: {},
  fetchAddDAVTouchVoucherStatus: false,
  fetchAddDAVTouchVoucherCode: 0,
  fetchAddDAVTouchVoucherMessage: '',

  isDeleteDAVTouchVoucherFetching: true,
  dataDeleteDAVTouchVouchers: {},
  fetchDeleteDAVTouchVoucherStatus: false,
  fetchDeleteDAVTouchVoucherCode: 0,
  fetchDeleteDAVTouchVoucherMessage: '',
}

function devtouchs(state = initialState, action) {
  switch (action.type) {
    case DAVTOUCH_VIDEOS_FETCHING:
      return {
        ...state,
        isDAVTouchVideosFetching: true,
      }
    case DAVTOUCH_VIDEOS_FETCHED:
      const constructDAVTouchVideoData = {
        davTouchVideoData: [...action.payload.payload.data].map(touchVideo => ({
          id: touchVideo.id,
          title: touchVideo.title,
          start_date: touchVideo.start ? touchVideo.start : '',
          end_date: touchVideo.end ? touchVideo.end : '',
          status: touchVideo.status === 1 ? 'Active' : 'Inactive'
        })),
        davTouchVideoDataLength: action.payload.payload.total,
      }
      return {
        ...state,
        isDAVTouchVideosFetching: false,
        dataDAVTouchVideos: constructDAVTouchVideoData,
        fetchDAVTouchVideosStatus: action.payload.success,
        fetchDAVTouchVideosCode: action.payload.code,
        fetchDAVTouchVideosMessage: action.payload.message,
      }
    case DAVTOUCH_VIDEOS_FAIL:
      return {
        ...state,
        isDAVTouchVideosFetching: false,
        fetchDAVTouchVideosStatus: action.payload.success,
        fetchDAVTouchVideosCode: action.payload.code,
        fetchDAVTouchVideosMessage: action.payload.message,
      }
    case DAVTOUCH_VIDEOS_ERROR:
      return {
        ...state,
        isDAVTouchVideosFetching: false,
        fetchDAVTouchVideosStatus: action.payload.success,
        fetchDAVTouchVideosCode: action.payload.code,
        fetchDAVTouchVideosMessage: action.payload.message,
      }
    case DAVTOUCH_GET_VIDEO_INIT:
      return {
        ...state,
        isGetDAVTouchVideoFetching: true,
        fetchGetDAVTouchVideoStatus: false,
        fetchGetDAVTouchVideoCode: 0,
        fetchGetDAVTouchVideoMessage: '',
      }
    case DAVTOUCH_GET_VIDEO_FETCHING:
      return {
        ...state,
        isGetDAVTouchVideoFetching: true,
      }
    case DAVTOUCH_GET_VIDEO_FETCHED:
      const constructGetDAVTouchVideoData = {
        id: action.payload.payload.id,
        title: action.payload.payload.title,
        start: action.payload.payload.start,
        end: action.payload.payload.end,
        description: action.payload.payload.description !== "" ? action.payload.payload.description : '',
        video_idle: action.payload.payload.video_idle,
        video_nogift: action.payload.payload.video_nogift,
        video_poke: action.payload.payload.video_poke,
        status: action.payload.payload.status,
        mail_alert: action.payload.payload.mail_alert,
        max_sentil: action.payload.payload.max_sentil,
      }
      return {
        ...state,
        isGetDAVTouchVideoFetching: false,
        dataGetDAVTouchVideo: constructGetDAVTouchVideoData,
        fetchGetDAVTouchVideoStatus: action.payload.success,
        fetchGetDAVTouchVideoCode: action.payload.code,
        fetchGetDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_GET_VIDEO_FAIL:
      return {
        ...state,
        isGetDAVTouchVideoFetching: false,
        fetchGetDAVTouchVideoStatus: action.payload.success,
        fetchGetDAVTouchVideoCode: action.payload.code,
        fetchGetDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_GET_VIDEO_ERROR:
      return {
        ...state,
        isGetDAVTouchVideoFetching: false,
        fetchGetDAVTouchVideoStatus: action.payload.success,
        fetchGetDAVTouchVideoCode: action.payload.code,
        fetchGetDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_ADD_VIDEOS_INIT:
      return {
        ...state,
        isAddDAVTouchVideoFetching: true,
        fetchAddDAVTouchVideoStatus: false,
        fetchAddDAVTouchVideoCode: 0,
        fetchAddDAVTouchVideoMessage: '',
      }
    case DAVTOUCH_ADD_VIDEOS_FETCHING:
      return {
        ...state,
        isAddDAVTouchVideoFetching: true,
      }
    case DAVTOUCH_ADD_VIDEOS_FETCHED:
      return {
        ...state,
        isAddDAVTouchVideoFetching: false,
        dataAddDAVTouchVideo: action.payload.payload,
        fetchAddDAVTouchVideoStatus: action.payload.success,
        fetchAddDAVTouchVideoCode: action.payload.code,
        fetchAddDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_ADD_VIDEOS_FAIL:
      return {
        ...state,
        isAddDAVTouchVideoFetching: false,
        fetchAddDAVTouchVideoStatus: action.payload.success,
        fetchAddDAVTouchVideoCode: action.payload.code,
        fetchAddDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_ADD_VIDEOS_ERROR:
      return {
        ...state,
        isAddDAVTouchVideoFetching: false,
        fetchAddDAVTouchVideoStatus: action.payload.success,
        fetchAddDAVTouchVideoCode: action.payload.code,
        fetchAddDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_EDIT_VIDEOS_INIT:
      return {
        ...state,
        isUpdateDAVTouchVideoFetching: true,
        fetchUpdateDAVTouchVideoStatus: false,
        fetchUpdateDAVTouchVideoCode: 0,
        fetchUpdateDAVTouchVideoMessage: '',
      }
    case DAVTOUCH_EDIT_VIDEOS_FETCHING:
      return {
        ...state,
        isUpdateDAVTouchVideoFetching: true,
      }
    case DAVTOUCH_EDIT_VIDEOS_FETCHED:
      return {
        ...state,
        isUpdateDAVTouchVideoFetching: false,
        dataUpdateDAVTouchVideo: action.payload.payload,
        fetchUpdateDAVTouchVideoStatus: action.payload.success,
        fetchUpdateDAVTouchVideoCode: action.payload.code,
        fetchUpdateDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_EDIT_VIDEOS_FAIL:
      return {
        ...state,
        isUpdateDAVTouchVideoFetching: false,
        fetchUpdateDAVTouchVideoStatus: action.payload.success,
        fetchUpdateDAVTouchVideoCode: action.payload.code,
        fetchUpdateDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_EDIT_VIDEOS_ERROR:
      return {
        ...state,
        isUpdateDAVTouchVideoFetching: false,
        fetchUpdateDAVTouchVideoStatus: action.payload.success,
        fetchUpdateDAVTouchVideoCode: action.payload.code,
        fetchUpdateDAVTouchVideoMessage: action.payload.message,
      }

    case DAVTOUCH_STATISTIC_VIDEOS_INIT:
      return {
        ...state,
        isStatisticDAVTouchVideoFetching: true,
        fetchStatisticDAVTouchVideoStatus: false,
        fetchStatisticDAVTouchVideoCode: 0,
        fetchStatisticDAVTouchVideoMessage: '',
      }
    case DAVTOUCH_STATISTIC_VIDEOS_FETCHING:
      return {
        ...state,
        isStatisticDAVTouchVideoFetching: true,
      }
    case DAVTOUCH_STATISTIC_VIDEOS_FETCHED:
      return {
        ...state,
        isStatisticsDAVTouchVideoFetching: false,
        dataStatisticsDAVTouchVideo: action.payload.payload,
        fetchStatisticsDAVTouchVideoStatus: action.payload.success,
        fetchStatisticsDAVTouchVideoCode: action.payload.code,
        fetchStatisticsDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_STATISTIC_VIDEOS_FAIL:
      return {
        ...state,
        isStatisticsDAVTouchVideoFetching: false,
        fetchStatisticsDAVTouchVideoStatus: action.payload.success,
        fetchStatisticsDAVTouchVideoCode: action.payload.code,
        fetchStatisticsDAVTouchVideoMessage: action.payload.message,
      }
    case DAVTOUCH_STATISTIC_VIDEOS_ERROR:
      return {
        ...state,
        isStatisticsDAVTouchVideoFetching: false,
        fetchStatisticsDAVTouchVideoStatus: action.payload.success,
        fetchStatisticsDAVTouchVideoCode: action.payload.code,
        fetchStatisticsDAVTouchVideoMessage: action.payload.message,
      }

    case DAVTOUCH_VOUCHERS_FETCHING:
      return {
        ...state,
        isDAVTouchVoucherFetching: true,
      }
    case DAVTOUCH_VOUCHERS_FETCHED:
      const constructDAVTouchVoucherData = {
        davTouchVoucherData: [...action.payload.payload.data].map(touchVideoVoucher => ({
          id: touchVideoVoucher.id,
          code: touchVideoVoucher.code,
          status: touchVideoVoucher.status === 1 ? 'Used' : 'Available',
          disabledDelVoucher: touchVideoVoucher.status === 0 ? false : true
        })),
        davTouchVoucherDataLength: action.payload.payload.total,
      }
      return {
        ...state,
        isDAVTouchVoucherFetching: false,
        dataDAVTouchVouchers: constructDAVTouchVoucherData,
        fetchDAVTouchVoucherStatus: action.payload.success,
        fetchDAVTouchVoucherCode: action.payload.code,
        fetchDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_VOUCHERS_FAIL:
      return {
        ...state,
        isDAVTouchVoucherFetching: false,
        fetchDAVTouchVoucherStatus: action.payload.success,
        fetchDAVTouchVoucherCode: action.payload.code,
        fetchDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_VOUCHERS_ERROR:
      return {
        ...state,
        isDAVTouchVoucherFetching: false,
        fetchDAVTouchVoucherStatus: action.payload.success,
        fetchDAVTouchVoucherCode: action.payload.code,
        fetchDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_ADD_VOUCHERS_INIT:
      return {
        ...state,
        isAddDAVTouchVoucherFetching: true,
        fetchAddDAVTouchVoucherStatus: false,
        fetchAddDAVTouchVoucherCode: 0,
        fetchAddDAVTouchVoucherMessage: '',
      }
    case DAVTOUCH_ADD_VOUCHERS_FETCHING:
      return {
        ...state,
        isAddDAVTouchVoucherFetching: true,
      }
    case DAVTOUCH_ADD_VOUCHERS_FETCHED:
      return {
        ...state,
        isAddDAVTouchVoucherFetching: false,
        dataAddDAVTouchVouchers: action.payload.payload,
        fetchAddDAVTouchVoucherStatus: action.payload.success,
        fetchAddDAVTouchVoucherCode: action.payload.code,
        fetchAddDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_ADD_VOUCHERS_FAIL:
      return {
        ...state,
        isAddDAVTouchVoucherFetching: false,
        fetchAddDAVTouchVoucherStatus: action.payload.success,
        fetchAddDAVTouchVoucherCode: action.payload.code,
        fetchAddDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_ADD_VOUCHERS_ERROR:
      return {
        ...state,
        isAddDAVTouchVoucherFetching: false,
        fetchAddDAVTouchVoucherStatus: action.payload.success,
        fetchAddDAVTouchVoucherCode: action.payload.code,
        fetchAddDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_DELETE_VOUCHERS_INIT:
      return {
        ...state,
        isDeleteDAVTouchVoucherFetching: true,
        fetchDeleteDAVTouchVoucherStatus: false,
        fetchDeleteDAVTouchVoucherCode: 0,
        fetchDeleteDAVTouchVoucherMessage: '',
      }
    case DAVTOUCH_DELETE_VOUCHERS_FETCHING:
      return {
        ...state,
        isDeleteDAVTouchVoucherFetching: true,
      }
    case DAVTOUCH_DELETE_VOUCHERS_FETCHED:
      return {
        ...state,
        isDeleteDAVTouchVoucherFetching: false,
        dataDeleteDAVTouchVouchers: action.payload.payload,
        fetchDeleteDAVTouchVoucherStatus: action.payload.success,
        fetchDeleteDAVTouchVoucherCode: action.payload.code,
        fetchDeleteDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_DELETE_VOUCHERS_FAIL:
      return {
        ...state,
        isDeleteDAVTouchVoucherFetching: false,
        fetchDeleteDAVTouchVoucherStatus: action.payload.success,
        fetchDeleteDAVTouchVoucherCode: action.payload.code,
        fetchDeleteDAVTouchVoucherMessage: action.payload.message,
      }
    case DAVTOUCH_DELETE_VOUCHERS_ERROR:
      return {
        ...state,
        isDeleteDAVTouchVoucherFetching: false,
        fetchDeleteDAVTouchVoucherStatus: action.payload.success,
        fetchDeleteDAVTouchVoucherCode: action.payload.code,
        fetchDeleteDAVTouchVoucherMessage: action.payload.message,
      }
    default:
      return state;
  }
}

export default devtouchs;
