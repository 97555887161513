import { shiftParser, shiftNumParser } from '../helper/shiftParser';
import {
  BANNER_FECTHING,
  BANNER_FETCHED,
  BANNER_ADD_INIT,
  BANNER_ADDING,
  BANNER_ADDED,
  BANNER_ADD_FAIL,
  BANNER_ADD_ERROR,
  BANNER_UPDATE_INIT,
  BANNER_UPDATING,
  BANNER_UPDATED,
  BANNER_UPDATE_FAIL,
  BANNER_UPDATE_ERROR,
  BANNER_DELETE_INIT,
  BANNER_DELETING,
  BANNER_DELETED,
  BANNER_DELETE_FAIL,
  BANNER_DELETE_ERROR,
  BANNER_FAIL,
  BANNER_ERROR,
  CLIENT_LIST_FECTHING,
  CLIENT_LIST_FETCHED,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_ERROR,
  CLIENT_PLACEMENT_LIST_FECTHING,
  CLIENT_PLACEMENT_LIST_FETCHED,
  CLIENT_PLACEMENT_LIST_FAIL,
  CLIENT_PLACEMENT_LIST_ERROR,
  CLIENT_PLACEMENTBRANCH_LIST_FECTHING,
  CLIENT_PLACEMENTBRANCH_LIST_FETCHED,
  CLIENT_PLACEMENTBRANCH_LIST_FAIL,
  CLIENT_PLACEMENTBRANCH_LIST_ERROR,
  FILTER_BANNER_SLOT_FETCHING,
  FILTER_BANNER_SLOT_FETCHED,
  FILTER_BANNER_SLOT_FAIL,
  FILTER_BANNER_SLOT_ERROR,
  BANNER_SLOT_INIT,
  BANNER_SLOT_FETCHING,
  BANNER_SLOT_FETCHED,
  BANNER_SLOT_FAIL,
  BANNER_SLOT_ERROR,
  PROFILE_LIST_FECTHING,
  PROFILE_LIST_FETCHED,
  PROFILE_LIST_FAIL,
  PROFILE_LIST_ERROR,
  PROFILE_BANNER_FECTHING,
  PROFILE_BANNER_FETCHED,
  PROFILE_BANNER_ADDING,
  PROFILE_BANNER_ADDED,
  PROFILE_BANNER_DELETING,
  PROFILE_BANNER_DELETED,
  PROFILE_BANNER_FAIL,
  PROFILE_BANNER_ERROR
} from "../actions/types";

const initialState = {
  dataClients: [],
  isClientFetching: true,
  dataProductProfiles: [],
  isProductProfilesFetching: true,
  dataBanners: {},

  dataClientPlacements: [],
  isClientPlacementFetching: true,
  fetchClientPlacementCode: 0,
  fetchClientPlacementStatus: false,
  fetchClientPlacementMessage: '',

  dataClientBranchs: [],
  isClientBranchFetching: true,
  fetchClientBranchCode: 0,
  fetchClientBranchStatus: false,
  fetchClientBranchMessage: '',

  dataBannerSlots: {},
  isBannerSlotFetching: true,
  fetchBannerSlotCode: 0,
  fetchBannerSlotStatus: false,
  fetchBannerSlotMessage: '',

  dataCheckBannerSlots: {},
  isCheckBannerSlotFetching: true,
  fetchCheckBannerSlotCode: 0,
  fetchCheckBannerSlotStatus: false,
  fetchCheckBannerSlotMessage: '',

  addBanners: {},
  isBannerAddFetching: true,
  fetchAddBannerStatus: false,
  fetchAddBannerCode: 0,
  fetchAddBannerMessage: "",

  updateBanners: {},
  isBannerUpdateFetching: true,
  fetchUpdateBannerStatus: false,
  fetchUpdateBannerCode: 0,
  fetchUpdateBannerMessage: "",

  deleteBanners: {},
  isBannerDeleteFetching: true,
  fetchDeleteBannerStatus: false,
  fetchDeleteBannerCode: 0,
  fetchDeleteBannerMessage: "",

  dataProfileBanners: [],
  isProfileBannersFetching: true,
  addProfileBanners: {},
  deleteProfileBanners: {},
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true
};

function banners(state = initialState, action) {
  switch (action.type) {
    // Casting Client List
    case CLIENT_LIST_FECTHING:
      return {
        ...state,
        isClientFetching: true
      };
    case CLIENT_LIST_FETCHED:
      return {
        ...state,
        isClientFetching: false,
        dataClients: action.payload.payload.data.map(data => ({
          id: data.id,
          name: data.name,
          type_list: data.type_list
        })),
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case CLIENT_LIST_FAIL:
      return {
        ...state,
        isClientFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case CLIENT_LIST_ERROR:
      return {
        ...state,
        isClientFetching: false,
        fetchMessage: action.payload
      };

    // Casting Placement List for filter Banner Slot Calendar
    case CLIENT_PLACEMENT_LIST_FECTHING:
      return {
        ...state,
        isClientPlacementFetching: true
      }
    case CLIENT_PLACEMENT_LIST_FETCHED:
      // Construct for Auto Complete Select Placement Filter
      const constructPlacementList = {
        clientPlacementsLength: action.payload.payload.data.length,
        clientPlacementsData: action.payload.payload.data.map((item) => ({ label: item.name, value: item.id, data: item }))
      }

      return {
        ...state,
        isClientPlacementFetching: false,
        dataClientPlacements: constructPlacementList,
        fetchClientPlacementCode: action.payload.code,
        fetchClientPlacementStatus: action.payload.success,
        fetchClientPlacementMessage: action.payload.message,
      }
    case CLIENT_PLACEMENT_LIST_FAIL:
      return {
        ...state,
        isClientPlacementFetching: false,
        fetchClientPlacementCode: action.payload.code,
        fetchClientPlacementStatus: action.payload.success,
        fetchClientPlacementMessage: action.payload.message,
      }
    case CLIENT_PLACEMENT_LIST_ERROR:
      return {
        ...state,
        isClientPlacementFetching: false,
        fetchClientPlacementCode: action.payload.code,
        fetchClientPlacementMessage: action.payload.message,
      }
    case CLIENT_PLACEMENTBRANCH_LIST_FECTHING:
      return {
        ...state,
        isClientBranchFetching: true,
      }
    case CLIENT_PLACEMENTBRANCH_LIST_FETCHED:
      // Construct for Auto Complete Select Placement Branch Filter
      const constructBranchList = {
        clientBranchsLength: action.payload.payload.total,
        clientBranchsData: action.payload.payload.data.map((item) => ({ label: item.name, value: item.id, data: item }))
      }
      return {
        ...state,
        isClientBranchFetching: false,
        dataClientBranchs: constructBranchList,
        fetchClientBranchCode: action.payload.code,
        fetchClientBranchStatus: action.payload.success,
        fetchClientBranchMessage: action.payload.message,
      }
    case CLIENT_PLACEMENTBRANCH_LIST_FAIL:
      return {
        ...state,
        isClientBranchFetching: true,
        fetchClientBranchCode: action.payload.code,
        fetchClientBranchStatus: action.payload.success,
        fetchClientBranchMessage: action.payload.message,
      }
    case CLIENT_PLACEMENTBRANCH_LIST_ERROR:
      return {
        ...state,
        isClientBranchFetching: true,
        fetchClientBranchCode: action.payload.code,
        fetchClientBranchMessage: action.payload.message,
      }
    case FILTER_BANNER_SLOT_FETCHING:
      return {
        ...state,
        isBannerSlotFetching: true,
      }
    case FILTER_BANNER_SLOT_FETCHED:
      return {
        ...state,
        isBannerSlotFetching: false,
        dataBannerSlots: action.payload.payload,
        fetchBannerSlotCode: action.payload.code,
        fetchBannerSlotStatus: action.payload.success,
        fetchBannerSlotMessage: action.payload.message,
      }
    case FILTER_BANNER_SLOT_FAIL:
      return {
        ...state,
        isBannerSlotFetching: false,
        dataBannerSlots: action.payload.payload,
        fetchBannerSlotCode: action.payload.code,
        fetchBannerSlotStatus: action.payload.success,
        fetchBannerSlotMessage: action.payload.message,
      }
    case FILTER_BANNER_SLOT_ERROR:
      return {
        ...state,
        isBannerSlotFetching: false,
        fetchBannerSlotCode: action.payload.code,
        fetchBannerSlotMessage: action.payload.message,
      }
    // Casting Banner Slot
    case BANNER_SLOT_INIT:
      return {
        ...state,
        fetchCheckBannerSlotCode: 0,
        fetchCheckBannerSlotStatus: false,
        fetchCheckBannerSlotMessage: '',
      }
    case BANNER_SLOT_FETCHING:
      return {
        ...state,
        isCheckBannerSlotFetching: true,
      }
    case BANNER_SLOT_FETCHED:
      const availableBannerSlot = action.payload.payload.reduce((min, p) => p.slot_available < min ? p.slot_available : min, action.payload.payload[0].slot_available);
      return {
        ...state,
        isCheckBannerSlotFetching: false,
        dataCheckBannerSlots: availableBannerSlot > 0 ? availableBannerSlot : 0,
        fetchCheckBannerSlotCode: action.payload.code,
        fetchCheckBannerSlotStatus: action.payload.success,
        fetchCheckBannerSlotMessage: action.payload.message,
      }
    case BANNER_SLOT_FAIL:
      return {
        ...state,
        isCheckBannerSlotFetching: false,
        fetchCheckBannerSlotCode: action.payload.code,
        fetchCheckBannerSlotStatus: action.payload.success,
        fetchCheckBannerSlotMessage: action.payload.message,
      }
    case BANNER_SLOT_ERROR:
      return {
        ...state,
        isCheckBannerSlotFetching: false,
        fetchCheckBannerSlotCode: action.payload.code,
        fetchCheckBannerSlotStatus: action.payload.success,
        fetchCheckBannerSlotMessage: action.payload.message,
      }

    // Casting Profile List
    case PROFILE_LIST_FECTHING:
      return {
        ...state,
        isProductProfilesFetching: true
      };
    case PROFILE_LIST_FETCHED:
      return {
        ...state,
        isProductProfilesFetching: false,
        dataProductProfiles: action.payload.payload.data,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PROFILE_LIST_FAIL:
      return {
        ...state,
        isProductProfilesFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PROFILE_LIST_ERROR:
      return {
        ...state,
        isProductProfilesFetching: false,
        fetchMessage: action.payload
      };
    // Casting Banner Data
    case BANNER_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case BANNER_FETCHED:
      const extractForExport = action.payload.payload.data.map(item => {
        item.client_name = item.client ? item.client.name : "No Client";
        const tempObj = Object.assign({}, item);
        delete tempObj.id;
        delete tempObj.client_code;
        delete tempObj.client_id;
        return tempObj;
      });
      const constructData = {
        bannerData: Object.assign(
          action.payload.payload.data,
          action.payload.payload.data.forEach(item => {
            item.uploader = item.creator ? item.creator.email : "Unknown";
            item.client_name = item.client ? item.client.name : "No Client";
            item.client_code = item.client ? item.client.code : "No Client";
            item.payment_typenum = item.payment_type ? item.payment_type : 0;
            item.payment_type = item.payment_type ? item.payment_type === 1 ? 'PrePaid' : 'PostPaid' : 'Not Defined';
            item.payment_status = item.payment_slip ? 'Paid' : 'Unpaid';
            item.banner_status = item.status === 0 ? 'Inactive' : 'Active';
            item.shift = shiftParser(item.start, item.finish);
            item.shiftNumRule = shiftNumParser(item.start, item.finish);
          })
        ),
        bannerDataExport: extractForExport,
        bannerDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isFetching: false,
        dataBanners: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case BANNER_ADD_INIT:
      return {
        ...state,
        fetchAddBannerCode: 0,
        fetchAddBannerStatus: false,
        fetchAddBannerMessage: ""
      };
    case BANNER_ADDING:
      return {
        ...state,
        isBannerAddFetching: true
      };
    case BANNER_ADDED:
      return {
        ...state,
        isBannerAddFetching: false,
        addBanners: action.payload,
        dataBanners: state.dataBanners,
        fetchAddBannerCode: action.payload.code,
        fetchAddBannerStatus: action.payload.success,
        fetchAddBannerMessage: action.payload.message
      };
    case BANNER_ADD_FAIL:
      return {
        ...state,
        isBannerAddFetching: false,
        fetchAddBannerCode: action.payload.code,
        fetchAddBannerStatus: action.payload.success,
        fetchAddBannerMessage: action.payload.message
      };
    case BANNER_ADD_ERROR:
      return {
        ...state,
        isBannerAddFetching: false,
        fetchAddBannerCode: action.payload.code,
        fetchAddBannerMessage: action.payload.message
      };
    case BANNER_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateBannerCode: 0,
        fetchUpdateBannerStatus: false,
        fetchUpdateBannerMessage: ""
      };
    case BANNER_UPDATING:
      return {
        ...state,
        isBannerUpdateFetching: true
      };
    case BANNER_UPDATED:
      return {
        ...state,
        isBannerUpdateFetching: false,
        updateBanners: action.payload,
        dataBanners: state.dataBanners,
        fetchUpdateBannerCode: action.payload.code,
        fetchUpdateBannerStatus: action.payload.success,
        fetchUpdateBannerMessage: action.payload.message
      };
    case BANNER_UPDATE_FAIL:
      return {
        ...state,
        isBannerUpdateFetching: false,
        fetchUpdateBannerCode: action.payload.code,
        fetchUpdateBannerStatus: action.payload.success,
        fetchUpdateBannerMessage: action.payload.message
      };
    case BANNER_UPDATE_ERROR:
      return {
        ...state,
        isBannerUpdateFetching: false,
        fetchUpdateBannerCode: action.payload.code,
        fetchUpdateBannerMessage: action.payload.message
      };
    case BANNER_DELETE_INIT:
      return {
        ...state,
        fetchDeleteBannerStatus: false,
        fetchDeleteBannerCode: 0,
        fetchDeleteBannerMessage: ""
      }
    case BANNER_DELETING:
      return {
        ...state,
        isBannerDeleteFetching: true
      };
    case BANNER_DELETED:
      return {
        ...state,
        isBannerDeleteFetching: false,
        deleteBanners: action.payload,
        dataBanners: state.dataBanners,
        fetchDeleteBannerCode: action.payload.code,
        fetchDeleteBannerStatus: action.payload.success,
        fetchDeleteBannerMessage: action.payload.message
      };
    case BANNER_DELETE_FAIL:
      return {
        ...state,
        isBannerDeleteFetching: false,
        fetchDeleteBannerCode: action.payload.code,
        fetchDeleteBannerStatus: action.payload.success,
        fetchDeleteBannerMessage: action.payload.message
      };
    case BANNER_DELETE_ERROR:
      return {
        ...state,
        isBannerDeleteFetching: false,
        fetchDeleteBannerCode: action.payload.code,
        fetchDeleteBannerMessage: action.payload.message
      };
    case BANNER_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case BANNER_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };
    // Casting Banner Profile
    case PROFILE_BANNER_FECTHING:
      return {
        ...state,
        isProfileBannersFetching: true
      };
    case PROFILE_BANNER_FETCHED:
      const constructProfileData = Object.assign(
        action.payload.payload,
        action.payload.payload.forEach((item, i) => {
          item.no = i + 1;
        })
      );
      return {
        ...state,
        isProfileBannersFetching: false,
        dataProfileBanners: [...constructProfileData],
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PROFILE_BANNER_ADDING:
      return {
        ...state,
        isProfileBannersFetching: true
      };
    case PROFILE_BANNER_ADDED:
      return {
        ...state,
        isProfileBannersFetching: false,
        addProfileBanners: action.payload,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PROFILE_BANNER_DELETING:
      return {
        ...state,
        isProfileBannersFetching: true
      };
    case PROFILE_BANNER_DELETED:
      return {
        ...state,
        isProfileBannersFetching: false,
        deleteProfileBanners: action.payload,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PROFILE_BANNER_FAIL:
      return {
        ...state,
        isProfileBannersFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case PROFILE_BANNER_ERROR:
      return {
        ...state,
        isProfileBannersFetching: false,
        fetchMessage: action.payload
      };
    default:
      break;
  }
  return state;
}

export default banners;
