import {
    TRANSORDER_VIRTUAL_FECTHING,
    TRANSORDER_VIRTUAL_FETCHED,
    TRANSORDER_VIRTUAL_FAIL,
    TRANSORDER_VIRTUAL_ERROR,
    TRANSORDER_VIRTUAL_DETAIL_FECTHING,
    TRANSORDER_VIRTUAL_DETAIL_FETCHED,
    TRANSORDER_VIRTUAL_DETAIL_FAIL,
    TRANSORDER_VIRTUAL_DETAIL_ERROR,
    ORDERVIRTUAL_SEARCH_FETCHING,
    ORDERVIRTUAL_SEARCH_FETCHED,
    ORDERVIRTUAL_SEARCH_FAIL,
    ORDERVIRTUAL_SEARCH_ERROR,
} from "../actions/types";

import { payment_type, order_status } from "../helper/statusAPIParser";

const initialState = {
    isOrderVirtualFetching: true,
    dataTransOrderVirtuals: [],
    fetchCode: 0,
    fetchStatus: false,
    fetchMessage: "",

    isSearchedOrderVirtualFetching: true,
    dataSearchedOrderVirtuals: [],
    fetchSearchedOrderVirtualCode: 0,
    fetchSearchedOrderVirtualStatus: false,
    fetchSearchedOrderVirtualMessage: "",

    dataTransOrderVirtualDetails: {},
    fetchVirtualDetailStatus: false,
    fetchVirtualDetailCode: 0,
    fetchVirtualDetailMessage: "",
    isVirtualDetailFetching: true,
}

function transOrderVirtual(state = initialState, action) {
    switch (action.type) {
        case TRANSORDER_VIRTUAL_FECTHING:
            return {
                ...state,
                isOrderVirtualFetching: true
            }
        case TRANSORDER_VIRTUAL_FETCHED:
            const constructData = {
                orderVirtualData: Object.assign(
                    action.payload.payload.data,
                    action.payload.payload.data.forEach(item => {
                        item.customer_name = item.customer ? item.customer.name : 'Unknown';
                        item.payment_type = payment_type(item.payment);
                        item.order_status = order_status(item.status);
                    })
                ),
                orderVirtualDataLength: action.payload.payload.total
            };
            return {
                ...state,
                isOrderVirtualFetching: false,
                dataTransOrderVirtuals: constructData,
                fetchCode: action.payload.code,
                fetchStatus: action.payload.success,
                fetchMessage: action.payload.message,
            }
        case TRANSORDER_VIRTUAL_FAIL:
            return {
                ...state,
                isOrderVirtualFetching: false,
                fetchCode: action.payload.code,
                fetchStatus: action.payload.success,
                fetchMessage: action.payload.message,
            }
        case TRANSORDER_VIRTUAL_ERROR:
            return {
                ...state,
                isOrderVirtualFetching: false,
                fetchCode: action.payload.code,
                fetchMessage: action.payload.message,
            }
        case ORDERVIRTUAL_SEARCH_FETCHING:
            return {
                ...state,
                isSearchedOrderVirtualFetching: true
            }
        case ORDERVIRTUAL_SEARCH_FETCHED:
            const constructSearchedData = {
                orderVirtualData: Object.assign(
                    action.payload.payload.data,
                    action.payload.payload.data.forEach(item => {
                        item.customer_name = item.customer.name;
                        item.payment_type = payment_type(item.payment);
                        item.order_status = order_status(item.status);
                    })
                ),
                orderVirtualDataLength: action.payload.payload.total
            };
            return {
                ...state,
                isSearchedOrderVirtualFetching: false,
                dataSearchedOrderVirtuals: constructSearchedData,
                fetchSearchedOrderVirtualCode: action.payload.code,
                fetchSearchedOrderVirtualStatus: action.payload.success,
                fetchSearchedOrderVirtualMessage: action.payload.message,
            }
        case ORDERVIRTUAL_SEARCH_FAIL:
            return {
                ...state,
                isSearchedOrderVirtualFetching: false,
                fetchSearchedOrderVirtualCode: action.payload.code,
                fetchSearchedOrderVirtualStatus: action.payload.success,
                fetchSearchedOrderVirtualMessage: action.payload.message,
            }
        case ORDERVIRTUAL_SEARCH_ERROR:
            return {
                ...state,
                isSearchedOrderVirtualFetching: false,
                fetchSearchedOrderVirtualCode: action.payload.code,
                fetchSearchedOrderVirtualMessage: action.payload.message,
            }
        case TRANSORDER_VIRTUAL_DETAIL_FECTHING:
            return {
                ...state,
                isVirtualDetailFetching: true
            }
        case TRANSORDER_VIRTUAL_DETAIL_FETCHED:
            return {
                ...state,
                isVirtualDetailFetching: false,
                dataTransOrderVirtualDetails: action.payload.payload,
                fetchVirtualDetailStatus: action.payload.success,
                fetchVirtualDetailCode: action.payload.code,
                fetchVirtualDetailMessage: action.payload.message,
            }
        case TRANSORDER_VIRTUAL_DETAIL_FAIL:
            return {
                ...state,
                isVirtualDetailFetching: false,
                fetchVirtualDetailStatus: action.payload.success,
                fetchVirtualDetailCode: action.payload.code,
                fetchVirtualDetailMessage: action.payload.message,
            }
        case TRANSORDER_VIRTUAL_DETAIL_ERROR:
            return {
                ...state,
                isVirtualDetailFetching: false,
                fetchVirtualDetailCode: action.payload.code,
                fetchVirtualDetailMessage: action.payload.message,
            }
        default:
            break;
    }
    return state;
}

export default transOrderVirtual;