import {
    PRODUCT_VIRTUAL_FECTHING,
    PRODUCT_VIRTUAL_FETCHED,
    PRODUCT_VIRTUAL_FAIL,
    PRODUCT_VIRTUAL_ERROR,

    PRODUCTVIRTUAL_SEARCH_FETCHING,
    PRODUCTVIRTUAL_SEARCH_FETCHED,
    PRODUCTVIRTUAL_SEARCH_FAIL,
    PRODUCTVIRTUAL_SEARCH_ERROR,

    PRODUCT_VIRTUAL_ADD_INIT,
    PRODUCT_VIRTUAL_ADD_FETCHING,
    PRODUCT_VIRTUAL_ADD_FETCHED,
    PRODUCT_VIRTUAL_ADD_FAIL,
    PRODUCT_VIRTUAL_ADD_ERROR,

    PRODUCTVIRTUAL_DETAIL_FETCHING,
    PRODUCTVIRTUAL_DETAIL_FETCHED,
    PRODUCTVIRTUAL_DETAIL_FAIL,
    PRODUCTVIRTUAL_DETAIL_ERROR,
    PRODUCTVIRTUAL_VOUCHERADD_INIT,
    PRODUCTVIRTUAL_VOUCHERADDING,
    PRODUCTVIRTUAL_VOUCHERADDED,
    PRODUCTVIRTUAL_VOUCHERADD_FAIL,
    PRODUCTVIRTUAL_VOUCHERADD_ERROR,
    PRODUCTVIRTUAL_VOUCHERUPDATE_INIT,
    PRODUCTVIRTUAL_VOUCHERUPDATING,
    PRODUCTVIRTUAL_VOUCHERUPDATED,
    PRODUCTVIRTUAL_VOUCHERUPDATE_FAIL,
    PRODUCTVIRTUAL_VOUCHERUPDATE_ERROR,
    PRODUCTVIRTUAL_VOUCHERDELETE_INIT,
    PRODUCTVIRTUAL_VOUCHERDELETING,
    PRODUCTVIRTUAL_VOUCHERDELETED,
    PRODUCTVIRTUAL_VOUCHERDELETE_FAIL,
    PRODUCTVIRTUAL_VOUCHERDELETE_ERROR,
    PRODUCTVIRTUAL_UPDATE_INIT,
    PRODUCTVIRTUAL_UPDATE_FETCHING,
    PRODUCTVIRTUAL_UPDATE_FETCHED,
    PRODUCTVIRTUAL_UPDATE_FAIL,
    PRODUCTVIRTUAL_UPDATE_ERROR,

    PRODUCT_VIRTUAL_DELETE_INIT,
    PRODUCT_VIRTUAL_DELETING,
    PRODUCT_VIRTUAL_DELETED,
    PRODUCT_VIRTUAL_DELETE_FAIL,
    PRODUCT_VIRTUAL_DELETE_ERROR,
} from "../actions/types";

const initialState = {
    dataProductVirtuals: [],
    fetchStatus: false,
    fetchCode: 0,
    fetchMessage: "",
    isFetching: true,

    /* Search Virtual Product */
    isSearchedProductVirtualFetching: true,
    dataSearchedProductVirtuals: [],
    fetchSearchedProductVirtualCode: 0,
    fetchSearchedProductVirtualStatus: false,
    fetchSearchedProductVirtualMessage: "",

    /* Add Virtual Products */
    addProductVirtualData: {},
    fetchAddProductVirtualStatus: false,
    fetchAddProductVirtualCode: 0,
    fetchAddProductVirtualMessage: "",
    isAddProductVirtualFetching: true,

    /* Detail Virtual Products */
    dataDetailProductVirtuals: {},
    fetchDetailProductVirtualStatus: false,
    fetchDetailProductVirtualCode: 0,
    fetchDetailProductVirtualMessage: "",
    isDetailProductVirtualFetching: true,

    /* Add Virtual Vouchers */
    dataProductVirtualVouchers: {},
    fetchProductVirtualVoucherStatus: false,
    fetchProductVirtualVoucherCode: 0,
    fetchProductVirtualVoucherMessage: "",
    isProductVirtualVoucherFetching: true,

    /* update Virtual Vouchers */
    dataUpdateProductVirtualVouchers: {},
    fetchUpdateProductVirtualVoucherStatus: false,
    fetchUpdateProductVirtualVoucherCode: 0,
    fetchUpdateProductVirtualVoucherMessage: "",
    isUpdateProductVirtualVoucherFetching: true,

    /* delete Virtual Vouchers */
    dataDeleteProductVirtualVouchers: {},
    fetchDeleteProductVirtualVoucherStatus: false,
    fetchDeleteProductVirtualVoucherCode: 0,
    fetchDeleteProductVirtualVoucherMessage: "",
    isDeleteProductVirtualVoucherFetching: true,


    /* Update Detail Products */
    updatedDetailProductVirtuals: {},
    fetchUpdateProductVirtualStatus: false,
    fetchUpdateProductVirtualCode: 0,
    fetchUpdateProductVirtualMessage: "",
    isUpdateProductVirtualFetching: true,

    /* Delete Detail Products */
    deletedProductVirtuals: {},
    fetchDeleteProductVirtualStatus: false,
    fetchDeleteProductVirtualCode: 0,
    fetchDeleteProductVirtualMessage: "",
    isDeleteProductVirtualFetching: true
}

function productvirtuals(state = initialState, action) {
    switch (action.type) {
        case PRODUCT_VIRTUAL_FECTHING:
            return {
                ...state,
                isFetching: true,
            }
        case PRODUCT_VIRTUAL_FETCHED:
            const constructData = {
                productVirtualData: action.payload.payload.data,
                productVirtualDataLength: action.payload.payload.total
            };
            return {
                ...state,
                dataProductVirtuals: constructData,
                fetchCode: action.payload.code,
                fetchStatus: action.payload.success,
                fetchMessage: action.payload.message,
                isFetching: false,
            }
        case PRODUCT_VIRTUAL_FAIL:
            return {
                ...state,
                fetchCode: action.payload.code,
                fetchStatus: action.payload.success,
                fetchMessage: action.payload.message,
                isFetching: false,
            }
        case PRODUCT_VIRTUAL_ERROR:
            return {
                ...state,
                fetchMessage: action.payload.message,
                isFetching: false,
            }

        case PRODUCTVIRTUAL_SEARCH_FETCHING:
            return {
                ...state,
                isSearchedProductVirtualFetching: true,
            }
        case PRODUCTVIRTUAL_SEARCH_FETCHED:
            const constructSearchedData = {
                productVirtualData: action.payload.payload.data,
                productVirtualDataLength: action.payload.payload.total
            };
            return {
                ...state,
                isSearchedProductVirtualFetching: false,
                dataSearchedProductVirtuals: constructSearchedData,
                fetchSearchedProductVirtualCode: action.payload.code,
                fetchSearchedProductVirtualStatus: action.payload.success,
                fetchSearchedProductVirtualMessage: action.payload.message,
            }
        case PRODUCTVIRTUAL_SEARCH_FAIL:
            return {
                ...state,
                isSearchedProductVirtualFetching: false,
                fetchSearchedProductVirtualCode: action.payload.code,
                fetchSearchedProductVirtualStatus: action.payload.success,
                fetchSearchedProductVirtualMessage: action.payload.message,
            }
        case PRODUCTVIRTUAL_SEARCH_ERROR:
            return {
                ...state,
                isSearchedProductVirtualFetching: false,
                fetchSearchedProductVirtualCode: action.payload.code,
                fetchSearchedProductVirtualMessage: action.payload.message,
            }
        case PRODUCT_VIRTUAL_ADD_INIT:
            return {
                ...state,
                fetchAddProductVirtualStatus: false,
                fetchAddProductVirtualCode: 0,
                fetchAddProductVirtualMessage: ""
            }
        case PRODUCT_VIRTUAL_ADD_FETCHING:
            return {
                ...state,
                isAddProductVirtualFetching: true,
            }
        case PRODUCT_VIRTUAL_ADD_FETCHED:
            return {
                ...state,
                addProductVirtualData: action.payload.payload.data,
                fetchAddProductVirtualStatus: action.payload.success,
                fetchAddProductVirtualCode: action.payload.code,
                fetchAddProductVirtualMessage: action.payload.message,
                isAddProductVirtualFetching: false,
            }
        case PRODUCT_VIRTUAL_ADD_FAIL:
            return {
                ...state,
                fetchAddProductVirtualStatus: action.payload.success,
                fetchAddProductVirtualCode: action.payload.code,
                fetchAddProductVirtualMessage: action.payload.message,
                isAddProductVirtualFetching: false,
            }
        case PRODUCT_VIRTUAL_ADD_ERROR:
            return {
                ...state,
                fetchAddProductVirtualMessage: action.payload.message,
                fetchAddProductVirtualCode: action.payload.code,
                isAddProductVirtualFetching: false,
            }
        case PRODUCTVIRTUAL_DETAIL_FETCHING:
            return {
                ...state,
                isDetailProductVirtualFetching: true
            }
        case PRODUCTVIRTUAL_DETAIL_FETCHED:
            return {
                ...state,
                isDetailProductVirtualFetching: false,
                dataDetailProductVirtuals: action.payload.payload,
                fetchDetailProductVirtualStatus: action.payload.success,
                fetchDetailProductVirtualCode: action.payload.code,
                fetchDetailProductVirtualMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_DETAIL_FAIL:
            return {
                ...state,
                isDetailProductVirtualFetching: false,
                fetchDetailProductVirtualStatus: action.payload.success,
                fetchDetailProductVirtualCode: action.payload.code,
                fetchDetailProductVirtualMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_DETAIL_ERROR:
            return {
                ...state,
                isDetailProductVirtualFetching: false,
                fetchDetailProductVirtualCode: action.payload.code,
                fetchDetailProductVirtualMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_VOUCHERADD_INIT:
            return {
                ...state,
                fetchProductVirtualVoucherStatus: false,
                fetchProductVirtualVoucherCode: 0,
                fetchProductVirtualVoucherMessage: ""
            }
        case PRODUCTVIRTUAL_VOUCHERADDING:
            return {
                ...state,
                isProductVirtualVoucherFetching: true
            }
        case PRODUCTVIRTUAL_VOUCHERADDED:
            return {
                ...state,
                isProductVirtualVoucherFetching: false,
                dataProductVirtualVouchers: action.payload.payload,
                fetchProductVirtualVoucherStatus: action.payload.success,
                fetchProductVirtualVoucherCode: action.payload.code,
                fetchProductVirtualVoucherMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_VOUCHERADD_FAIL:
            return {
                ...state,
                isProductVirtualVoucherFetching: false,
                fetchProductVirtualVoucherStatus: action.payload.success,
                fetchProductVirtualVoucherCode: action.payload.code,
                fetchProductVirtualVoucherMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_VOUCHERADD_ERROR:
            return {
                ...state,
                isProductVirtualVoucherFetching: false,
                fetchProductVirtualVoucherCode: action.payload.code,
                fetchProductVirtualVoucherMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_VOUCHERUPDATE_INIT:
            return {
                ...state,
                fetchUpdateProductVirtualVoucherStatus: false,
                fetchUpdateProductVirtualVoucherCode: 0,
                fetchUpdateProductVirtualVoucherMessage: "",
            }
        case PRODUCTVIRTUAL_VOUCHERUPDATING:
            return {
                ...state,
                isUpdateProductVirtualVoucherFetching: true,
            }
        case PRODUCTVIRTUAL_VOUCHERUPDATED:
            return {
                ...state,
                isUpdateProductVirtualVoucherFetching: false,
                dataUpdateProductVirtualVouchers: action.payload.payload,
                fetchUpdateProductVirtualVoucherStatus: action.payload.success,
                fetchUpdateProductVirtualVoucherCode: action.payload.code,
                fetchUpdateProductVirtualVoucherMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_VOUCHERUPDATE_FAIL:
            return {
                ...state,
                isUpdateProductVirtualVoucherFetching: false,
                fetchUpdateProductVirtualVoucherStatus: action.payload.success,
                fetchUpdateProductVirtualVoucherCode: action.payload.code,
                fetchUpdateProductVirtualVoucherMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_VOUCHERUPDATE_ERROR:
            return {
                ...state,
                isUpdateProductVirtualVoucherFetching: false,
                fetchUpdateProductVirtualVoucherCode: action.payload.code,
                fetchUpdateProductVirtualVoucherMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_VOUCHERDELETE_INIT:
            return {
                ...state,
                fetchDeleteProductVirtualVoucherStatus: false,
                fetchDeleteProductVirtualVoucherCode: 0,
                fetchDeleteProductVirtualVoucherMessage: ""
            }

        case PRODUCTVIRTUAL_VOUCHERDELETING:
            return {
                ...state,
                isDeleteProductVirtualVoucherFetching: false,
            }

        case PRODUCTVIRTUAL_VOUCHERDELETED:
            return {
                ...state,
                isDeleteProductVirtualVoucherFetching: false,
                dataDeleteProductVirtualVouchers: action.payload.payload,
                fetchDeleteProductVirtualVoucherStatus: action.payload.success,
                fetchDeleteProductVirtualVoucherCode: action.payload.code,
                fetchDeleteProductVirtualVoucherMessage: action.payload.message
            }

        case PRODUCTVIRTUAL_VOUCHERDELETE_FAIL:
            return {
                ...state,
                isDeleteProductVirtualVoucherFetching: false,
                fetchDeleteProductVirtualVoucherStatus: action.payload.success,
                fetchDeleteProductVirtualVoucherCode: action.payload.code,
                fetchDeleteProductVirtualVoucherMessage: action.payload.message
            }

        case PRODUCTVIRTUAL_VOUCHERDELETE_ERROR:
            return {
                ...state,
                isDeleteProductVirtualVoucherFetching: false,
                fetchDeleteProductVirtualVoucherCode: action.payload.code,
                fetchDeleteProductVirtualVoucherMessage: action.payload.message
            }

        case PRODUCTVIRTUAL_UPDATE_INIT:
            return {
                ...state,
                fetchUpdateProductVirtualStatus: false,
                fetchUpdateProductVirtualCode: 0,
                fetchUpdateProductVirtualMessage: ""
            }

        case PRODUCTVIRTUAL_UPDATE_FETCHING:
            return {
                ...state,
                isUpdateProductVirtualFetching: true
            }
        case PRODUCTVIRTUAL_UPDATE_FETCHED:
            return {
                ...state,
                isUpdateProductVirtualFetching: false,
                updatedDetailProductVirtuals: action.payload.payload,
                dataDetailProductVirtuals: state.dataDetailProductVirtuals,
                fetchUpdateProductVirtualStatus: action.payload.success,
                fetchUpdateProductVirtualCode: action.payload.code,
                fetchUpdateProductVirtualMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_UPDATE_FAIL:
            return {
                ...state,
                isUpdateProductVirtualFetching: false,
                fetchUpdateProductVirtualStatus: action.payload.success,
                fetchUpdateProductVirtualCode: action.payload.code,
                fetchUpdateProductVirtualMessage: action.payload.message
            }
        case PRODUCTVIRTUAL_UPDATE_ERROR:
            return {
                ...state,
                isUpdateProductVirtualFetching: false,
                fetchUpdateProductVirtualCode: action.payload.code,
                fetchUpdateProductVirtualMessage: action.payload.message
            }

        case PRODUCT_VIRTUAL_DELETE_INIT:
            return {
                ...state,
                fetchDeleteProductVirtualStatus: false,
                fetchDeleteProductVirtualCode: 0,
                fetchDeleteProductVirtualMessage: ""
            }
        case PRODUCT_VIRTUAL_DELETING:
            return {
                ...state,
                isDeleteProductVirtualFetching: true
            }
        case PRODUCT_VIRTUAL_DELETED:
            return {
                ...state,
                isDeleteProductVirtualFetching: false,
                deletedProductVirtuals: action.payload,
                dataProductVirtuals: state.dataProductVirtuals,
                fetchDeleteProductVirtualStatus: action.payload.success,
                fetchDeleteProductVirtualCode: action.payload.code,
                fetchDeleteProductVirtualMessage: action.payload.message
            }
        case PRODUCT_VIRTUAL_DELETE_FAIL:
            return {
                ...state,
                isDeleteProductVirtualFetching: false,
                fetchDeleteProductVirtualStatus: action.payload.success,
                fetchDeleteProductVirtualCode: action.payload.code,
                fetchDeleteProductVirtualMessage: action.payload.message
            }
        case PRODUCT_VIRTUAL_DELETE_ERROR:
            return {
                ...state,
                isDeleteProductVirtualFetching: false,
                fetchDeleteProductVirtualCode: action.payload.code,
                fetchDeleteProductVirtualMessage: action.payload.message
            }

        default:
            break;
    }
    return state;
}

export default productvirtuals;