import {
  TRANS_REPORT_FECTHING,
  TRANS_REPORT_FETCHED,
  TRANS_REPORT_FAIL,
  TRANS_REPORT_ERROR,
  CLIENT_LIST_FECTHING,
  CLIENT_LIST_FETCHED,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_ERROR,
  TRANS_REPORT_EXPORTING,
  TRANS_REPORT_EXPORTED,
  TRANS_REPORT_EXPORT_FAIL,
  TRANS_REPORT_EXPORT_ERROR
} from "../actions/types";

const initialState = {
  dataClients: [],
  isClientFetching: true,
  dataReports: [],
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true,

  isExportDataFetching: true,
  dataExports: {},
  fetchExportDataStatus: false,
  fetchExportDataCode: 0,
  fetchExportDataMessage: ""
};
function transReportReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENT_LIST_FECTHING:
      return {
        ...state,
        isClientFetching: true
      };
    case CLIENT_LIST_FETCHED:
      return {
        ...state,
        isClientFetching: false,
        dataClients: action.payload.payload.data.map(data => ({
          id: data.id,
          name: data.name
        })),
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case CLIENT_LIST_FAIL:
      return {
        ...state,
        isClientFetching: false,
        dataClients: action.payload.payload.data,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case CLIENT_LIST_ERROR:
      return {
        ...state,
        isClientFetching: false,
        fetchMessage: action.payload
      };
    case TRANS_REPORT_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case TRANS_REPORT_FETCHED:
      const extractForExport = action.payload.payload.data.map(item => {
        const tempObj = Object.assign({}, item);
        delete tempObj.opened;
        return tempObj;
      });

      const constructData = {
        reportData: action.payload.payload.data,
        reportDataExport: extractForExport,
        reportDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isFetching: false,
        dataReports: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case TRANS_REPORT_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case TRANS_REPORT_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };
    case TRANS_REPORT_EXPORTING:
      return {
        ...state,
        isExportDataFetching: true
      };
    case TRANS_REPORT_EXPORTED:
      return {
        ...state,
        isExportDataFetching: false,
        dataExports: action.payload.data,
        fetchExportDataStatus: action.payload.status,
        fetchExportDataCode: action.payload.code,
        fetchExportDataMessage: action.payload.message
      };
    case TRANS_REPORT_EXPORT_FAIL:
      return {
        ...state,
        isExportDataFetching: false,
        fetchExportDataStatus: action.payload.status,
        fetchExportDataCode: action.payload.code,
        fetchExportDataMessage: action.payload.message
      };
    case TRANS_REPORT_EXPORT_ERROR:
      return {
        ...state,
        isExportDataFetching: false,
        fetchExportDataMessage: action.payload.message
      };
    default:
      break;
  }
  return state;
}

export default transReportReducer;
