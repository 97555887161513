import {
  CATEGORY_FECTHING,
  CATEGORY_FETCHED,
  CATEGORY_SEARCH_FETCHING,
  CATEGORY_SEARCH_FETCHED,
  CATEGORY_SEARCH_FAIL,
  CATEGORY_SEARCH_ERROR,
  CATEGORY_ADD_INIT,
  CATEGORY_ADDING,
  CATEGORY_ADDED,
  CATEGORY_ADD_FAIL,
  CATEGORY_ADD_ERROR,
  CATEGORY_UPDATE_INIT,
  CATEGORY_UPDATING,
  CATEGORY_UPDATED,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_DELETING,
  CATEGORY_DELETED,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_ERROR,
  CATEGORY_FAIL,
  CATEGORY_ERROR
} from "../actions/types";
const initialState = {
  dataCategories: [],
  fetchStatus: false,
  fetchCode: 0,
  fetchMessage: "",
  isFetching: true,

  dataSearchedCategories: [],
  fetchSearchedCategoryStatus: false,
  fetchSearchedCategoryCode: 0,
  fetchSearchedCategoryMessage: "",
  isSearchedCategoryFetching: true,

  addCategories: {},
  fetchAddCategoryStatus: false,
  fetchAddCategoryCode: 0,
  fetchAddCategoryMessage: "",
  isAddCategoryFetching: true,

  updateCategories: {},
  fetchUpdateCategoryStatus: false,
  fetchUpdateCategoryCode: 0,
  fetchUpdateCategoryMessage: "",
  isUpdateCategoryFetching: true,

  deleteCategories: {},
  fetchDeleteCategoryStatus: false,
  fetchDeleteCategoryCode: 0,
  fetchDeleteCategoryMessage: "",
  isDeleteCategoryFetching: true
};
function categories(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_FECTHING:
      return {
        ...state,
        isFetching: true
      };
    case CATEGORY_FETCHED:
      const constructData = {
        categoryData: action.payload.payload.data,
        categoryDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isFetching: false,
        dataCategories: constructData,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case CATEGORY_SEARCH_FETCHING:
      return {
        ...state,
        isSearchedCategoryFetching: true
      }
    case CATEGORY_SEARCH_FETCHED:
      const constructSearchedCategoryData = {
        categoryData: action.payload.payload.data,
        categoryDataLength: action.payload.payload.total
      };
      return {
        ...state,
        isSearchedCategoryFetching: false,
        dataSearchedCategories: constructSearchedCategoryData,
        fetchSearchedCategoryStatus: action.payload.success,
        fetchSearchedCategoryCode: action.payload.code,
        fetchSearchedCategoryMessage: action.payload.message
      }
    case CATEGORY_SEARCH_FAIL:
      return {
        ...state,
        isSearchedCategoryFetching: false,
        fetchSearchedCategoryStatus: action.payload.success,
        fetchSearchedCategoryCode: action.payload.code,
        fetchSearchedCategoryMessage: action.payload.message
      }
    case CATEGORY_SEARCH_ERROR:
      return {
        ...state,
        isSearchedCategoryFetching: false,
        fetchSearchedCategoryCode: action.payload.code,
        fetchSearchedCategoryMessage: action.payload.message
      }
    case CATEGORY_ADD_INIT:
      return {
        ...state,
        fetchAddCategoryStatus: false,
        fetchAddCategoryCode: 0,
        fetchAddCategoryMessage: ""
      }
    case CATEGORY_ADDING:
      return {
        ...state,
        isAddCategoryFetching: true
      };
    case CATEGORY_ADDED:
      return {
        ...state,
        isAddCategoryFetching: false,
        addCategories: action.payload,
        dataCategories: state.dataCategories,
        fetchAddCategoryStatus: action.payload.success,
        fetchAddCategoryCode: action.payload.code,
        fetchAddCategoryMessage: action.payload.message
      };
    case CATEGORY_ADD_FAIL:
      return {
        ...state,
        isAddCategoryFetching: false,
        fetchAddCategoryStatus: action.payload.success,
        fetchAddCategoryCode: action.payload.code,
        fetchAddCategoryMessage: action.payload.message
      };
    case CATEGORY_ADD_ERROR:
      return {
        ...state,
        isAddCategoryFetching: false,
        fetchAddCategoryMessage: action.payload.message
      };
    case CATEGORY_UPDATE_INIT:
      return {
        ...state,
        fetchUpdateCategoryStatus: false,
        fetchUpdateCategoryCode: 0,
        fetchUpdateCategoryMessage: ""
      }
    case CATEGORY_UPDATING:
      return {
        ...state,
        isUpdateCategoryFetching: true
      };
    case CATEGORY_UPDATED:
      return {
        ...state,
        isUpdateCategoryFetching: false,
        updateCategories: action.payload,
        dataCategories: state.dataCategories,
        fetchUpdateCategoryStatus: action.payload.success,
        fetchUpdateCategoryCode: action.payload.code,
        fetchUpdateCategoryMessage: action.payload.message
      };
    case CATEGORY_UPDATE_FAIL:
      return {
        ...state,
        isUpdateCategoryFetching: false,
        fetchUpdateCategoryStatus: action.payload.success,
        fetchUpdateCategoryCode: action.payload.code,
        fetchUpdateCategoryMessage: action.payload.message
      };
    case CATEGORY_UPDATE_ERROR:
      return {
        ...state,
        isUpdateCategoryFetching: false,
        fetchUpdateCategoryMessage: action.payload.message
      };
    case CATEGORY_DELETING:
      return {
        ...state,
        isDeleteCategoryFetching: true
      };
    case CATEGORY_DELETED:
      return {
        ...state,
        isDeleteCategoryFetching: false,
        deleteCategories: action.payload,
        dataCategories: state.dataCategories,
        fetchDeleteCategoryStatus: action.payload.success,
        fetchDeleteCategoryCode: action.payload.code,
        fetchDeleteCategoryMessage: action.payload.message
      };
    case CATEGORY_DELETE_FAIL:
      return {
        ...state,
        isDeleteCategoryFetching: false,
        fetchDeleteCategoryStatus: action.payload.success,
        fetchDeleteCategoryCode: action.payload.code,
        fetchDeleteCategoryMessage: action.payload.message
      };
    case CATEGORY_DELETE_ERROR:
      return {
        ...state,
        isDeleteCategoryFetching: false,
        fetchDeleteCategoryMessage: action.payload.message
      };
    case CATEGORY_FAIL:
      return {
        ...state,
        isFetching: false,
        fetchCode: action.payload.code,
        fetchStatus: action.payload.success,
        fetchMessage: action.payload.message
      };
    case CATEGORY_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchMessage: action.payload
      };

    default:
      break;
  }
  return state;
}

export default categories;
