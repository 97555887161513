import {
  DOMICILE_INIT_DATA_REGION,
  DOMICILE_ALL_FETCHING,
  DOMICILE_ALL_FETCHED,
  DOMICILE_ALL_FAIL,
  DOMICILE_ALL_ERROR,
  DOMICILE_INIT_DATA,
  DOMICILE_INIT_DATA_CITY,
  DOMICILE_INIT_DATA_DISTRICT,
  DOMICILE_PROVINCE_FETCHING,
  DOMICILE_PROVINCE_FETCHED,
  DOMICILE_PROVINCE_FAIL,
  DOMICILE_PROVINCE_ERROR,
  DOMICILE_CITY_FETCHING,
  DOMICILE_CITY_FETCHED,
  DOMICILE_CITY_FAIL,
  DOMICILE_CITY_ERROR,
  DOMICILE_DISTRICT_FETCHING,
  DOMICILE_DISTRICT_FETCHED,
  DOMICILE_DISTRICT_FAIL,
  DOMICILE_DISTRICT_ERROR,
  DOMICILE_SUBDISTRICT_FETCHING,
  DOMICILE_SUBDISTRICT_FETCHED,
  DOMICILE_SUBDISTRICT_FAIL,
  DOMICILE_SUBDISTRICT_ERROR
} from "../actions/types";

const initialState = {
  dataRegion: {},
  dataProvince: [],
  dataCity: [],
  dataDistrict: [],
  dataSubdistrict: [],

  /* Domicile Fetch Status, Code & Message*/
  fetchAllDomicileStatus: false,
  fetchAllDomicileCode: 0,
  fetchAllDomicileMessage: "",

  fetchDomicileStatus: false,
  fetchDomicileCode: 0,
  fetchDomicileMessage: "",

  /* Domicile Fetching */
  isAllRegionFetching: true,
  isProvinceFetching: true,
  isCityFetching: true,
  isDistrictFetching: true,
  isSubdistrictFetching: true
};

function domiciles(state = initialState, action) {
  switch (action.type) {
    case DOMICILE_INIT_DATA_REGION:
      return {
        ...state,
        dataRegion: {},
        fetchAllDomicileStatus: false,
        fetchAllDomicileCode: 0,
        fetchAllDomicileMessage: "",
      }
    case DOMICILE_ALL_FETCHING:
      return {
        ...state,
        isAllRegionFetching: true,

      }
    case DOMICILE_ALL_FETCHED:
      return {
        ...state,
        isAllRegionFetching: false,
        dataRegion: action.payload.payload,
        fetchAllDomicileStatus: action.payload.success,
        fetchAllDomicileCode: action.payload.code,
        fetchAllDomicileMessage: action.payload.message,
      }
    case DOMICILE_ALL_FAIL:
      return {
        ...state,
        isAllRegionFetching: false,
        fetchAllDomicileStatus: action.payload.success,
        fetchAllDomicileCode: action.payload.code,
        fetchAllDomicileMessage: action.payload.message,
      }
    case DOMICILE_ALL_ERROR:
      return {
        ...state,
        isAllRegionFetching: false,
        fetchAllDomicileCode: action.payload.code,
        fetchAllDomicileMessage: action.payload.message,
      }
    case DOMICILE_INIT_DATA:
      return {
        ...state,
        dataCity: [],
        dataDistrict: [],
        dataSubdistrict: [],
        fetchDomicileStatus: false,
        fetchDomicileCode: 0,
        fetchDomicileMessage: "",
      }

    case DOMICILE_INIT_DATA_CITY:
      return {
        ...state,
        dataDistrict: [],
        dataSubdistrict: [],
        fetchDomicileStatus: false,
        fetchDomicileCode: 0,
        fetchDomicileMessage: "",
      }
    case DOMICILE_INIT_DATA_DISTRICT:
      return {
        ...state,
        dataSubdistrict: [],
        fetchDomicileStatus: false,
        fetchDomicileCode: 0,
        fetchDomicileMessage: "",
      }
    case DOMICILE_PROVINCE_FETCHING:
      return {
        ...state,
        isProvinceFetching: true
      };
    case DOMICILE_PROVINCE_FETCHED:
      return {
        ...state,
        isProvinceFetching: false,
        dataProvince: action.payload.payload,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_PROVINCE_FAIL:
      return {
        ...state,
        isProvinceFetching: false,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_PROVINCE_ERROR:
      return {
        ...state,
        isProvinceFetching: false,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_CITY_FETCHING:
      return {
        ...state,
        isCityFetching: true
      };
    case DOMICILE_CITY_FETCHED:
      return {
        ...state,
        isCityFetching: false,
        dataCity: action.payload.payload,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_CITY_FAIL:
      return {
        ...state,
        isCityFetching: false,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_CITY_ERROR:
      return {
        ...state,
        isCityFetching: false,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_DISTRICT_FETCHING:
      return {
        ...state,
        isDistrictFetching: true
      };
    case DOMICILE_DISTRICT_FETCHED:
      return {
        ...state,
        isDistrictFetching: false,
        dataDistrict: action.payload.payload,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_DISTRICT_FAIL:
      return {
        ...state,
        isDistrictFetching: false,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_DISTRICT_ERROR:
      return {
        ...state,
        isDistrictFetching: false,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_SUBDISTRICT_FETCHING:
      return {
        ...state,
        isSubdistrictFetching: true
      };
    case DOMICILE_SUBDISTRICT_FETCHED:
      return {
        ...state,
        isSubdistrictFetching: false,
        dataSubdistrict: action.payload.payload,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_SUBDISTRICT_FAIL:
      return {
        ...state,
        isSubdistrictFetching: false,
        fetchDomicileStatus: action.payload.success,
        fetchDomicileCode: action.payload.code,
        fetchDomicileMessage: action.payload.message
      };
    case DOMICILE_SUBDISTRICT_ERROR:
      return {
        ...state,
        isSubdistrictFetching: false,
        fetchDomicileMessage: action.payload.message
      };

    default:
      break;
  }
  return state;
}
export default domiciles;
