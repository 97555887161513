export function getCookieValue() {
  let dataGetCookie = {
    token: "dav_token",
    expire: "dav_expire",
    user: "dav_user"
  };
  let documentToken = document.cookie.match(
    "(^|[^;]+)\\s*" + dataGetCookie.token + "\\s*=\\s*([^;]+)"
  )
    ? document.cookie.match(
        "(^|[^;]+)\\s*" + dataGetCookie.token + "\\s*=\\s*([^;]+)"
      )
    : "";

  let documentExpire = document.cookie.match(
    "(^|[^;]+)\\s*" + dataGetCookie.expire + "\\s*=\\s*([^;]+)"
  )
    ? document.cookie.match(
        "(^|[^;]+)\\s*" + dataGetCookie.expire + "\\s*=\\s*([^;]+)"
      )
    : "";
  let documentUser = document.cookie.match(
    "(^|[^;]+)\\s*" + dataGetCookie.user + "\\s*=\\s*([^;]+)"
  )
    ? document.cookie.match(
        "(^|[^;]+)\\s*" + dataGetCookie.user + "\\s*=\\s*([^;]+)"
      )
    : "";
  let token = documentToken ? documentToken.pop() : "";
  let expire = documentExpire ? documentExpire.pop() : "";
  let user = documentUser ? documentUser.pop() : "";
  let gotToken = {
    token,
    expire,
    user
  };
  return document.cookie.length > 0 ? gotToken : undefined;
}

export function setCookieValue(name, value, user, data, expiry) {
  /* 
        NOTE:
        name : nama untuk key token => dav_token
        value : untuk value tokennya => tokentok
        user : untuk data spesifik user => email di split ambil nama user > husni
        expiry : untuk data expire session dari API => expires_at
     */
  let expires;
  let tokenExpire;
  if (expiry) {
    var date = new Date(expiry).toGMTString();
    expires = "; expires=" + date;
    tokenExpire = date;
  } else {
    expires = "";
  }
  /* SameSite=Lax added because feature of Chrome 76 */
  document.cookie =
    "dav_expire=" + tokenExpire + expires + "; path=/;SameSite=Lax";
  document.cookie = name + "=" + value + expires + "; path=/;SameSite=Lax";
  document.cookie = user + "=" + data + expires + "; path=/;SameSite=Lax";
}

export function deleteCookie(name) {
  return (document.cookie =
    name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;");
  // return (document.cookie = name + "=; Max-Age=-99999999;path=/;");
}
