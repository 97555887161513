import {
    CLIENT_LIST_FECTHING,
    CLIENT_LIST_FETCHED,
    CLIENT_LIST_FAIL,
    CLIENT_LIST_ERROR,
    CLIENTBRANCH_GENERALLIST_FETCHING,
    CLIENTBRANCH_GENERALLIST_FETCHED,
    CLIENTBRANCH_GENERALLIST_FAIL,
    CLIENTBRANCH_GENERALLIST_ERROR,
    CLIENTBRANCH_REPORTINTERACTION_FECTHING,
    CLIENTBRANCH_REPORTINTERACTION_FETCHED,
    CLIENTBRANCH_REPORTINTERACTION_FAIL,
    CLIENTBRANCH_REPORTINTERACTION_ERROR,
    REPORT_INTERACTION_FETCHING,
    REPORT_INTERACTION_FETCHED,
    REPORT_INTERACTION_FAIL,
    REPORT_INTERACTION_ERROR,
    REPORT_INTERACTION_EXPORTING,
    REPORT_INTERACTION_EXPORTED,
    REPORT_INTERACTION_EXPORT_FAIL,
    REPORT_INTERACTION_EXPORT_ERROR,
    REPORTINTERACTION_V2_INIT,
    REPORTINTERACTION_V2_EXPORTING,
    REPORTINTERACTION_V2_EXPORTED,
    REPORTINTERACTION_V2_EXPORT_FAIL,
    REPORTINTERACTION_V2_EXPORT_ERROR
} from "../actions/types";

const initialState = {

    dataClients: [],
    isClientFetching: true,
    fetchClientsCode: 0,
    fetchClientsStatus: false,
    fetchClientsMessage: "",

    dataGeneralBranchs: [],
    isGeneralBranchFetching: true,
    fetchGeneralBranchCode: 0,
    fetchGeneralBranchStatus: false,
    fetchGeneralBranchMessage: "",

    dataClientBranchs: [],
    isClientBranchFetching: true,
    fetchClientBranchCode: 0,
    fetchClientBranchStatus: false,
    fetchClientBranchMessage: "",

    dataReportInteractions: [],
    isReportInteractionFetching: true,
    fetchReportInteractionCode: 0,
    fetchReportInteractionStatus: false,
    fetchReportInteractionMessage: "",

    dataExportReportInteractions: [],
    isExportReportInteractionFetching: true,
    fetchExportReportInteractionCode: 0,
    fetchExportReportInteractionStatus: false,
    fetchExportReportInteractionMessage: "",

    dataExportReportInteractionV2s: [],
    isExportReportInteractionV2Fetching: true,
    fetchExportReportInteractionV2Code: 0,
    fetchExportReportInteractionV2Status: false,
    fetchExportReportInteractionV2Message: "",
};

function reportInteraction(state = initialState, action) {
    switch (action.type) {
        case CLIENT_LIST_FECTHING:
            return {
                ...state,
                isClientFetching: true
            };
        case CLIENT_LIST_FETCHED:
            return {
                ...state,
                isClientFetching: false,
                dataClients: action.payload.payload.data.map(data => ({
                    value: data.id,
                    label: data.name,
                    client: data
                })),
                fetchClientsCode: action.payload.code,
                fetchClientsStatus: action.payload.success,
                fetchClientsMessage: action.payload.message
            };
        case CLIENT_LIST_FAIL:
            return {
                ...state,
                isClientFetching: false,
                dataClients: action.payload.payload.data,
                fetchClientsCode: action.payload.code,
                fetchClientsStatus: action.payload.success,
                fetchClientsMessage: action.payload.message
            };
        case CLIENT_LIST_ERROR:
            return {
                ...state,
                isClientFetching: false,
                fetchClientsCode: action.payload.code,
                fetchClientsMessage: action.payload.message
            };
        case CLIENTBRANCH_GENERALLIST_FETCHING:
            return {
                ...state,
                isGeneralBranchFetching: true,
            }
        case CLIENTBRANCH_GENERALLIST_FETCHED:
            return {
                ...state,
                isGeneralBranchFetching: false,
                dataGeneralBranchs: action.payload.payload.data.map(data => ({
                    value: data.id,
                    label: data.name,
                    branch: data
                })),
                fetchGeneralBranchCode: action.payload.code,
                fetchGeneralBranchStatus: action.payload.status,
                fetchGeneralBranchMessage: action.payload.message,
            }
        case CLIENTBRANCH_GENERALLIST_FAIL:
            return {
                ...state,
                isGeneralBranchFetching: false,
                fetchGeneralBranchCode: action.payload.code,
                fetchGeneralBranchStatus: action.payload.success,
                fetchGeneralBranchMessage: action.payload.message,
            }
        case CLIENTBRANCH_GENERALLIST_ERROR:
            return {
                ...state,
                isGeneralBranchFetching: false,
                fetchGeneralBranchCode: action.payload.code,
                fetchGeneralBranchMessage: action.payload.message,
            }
        case CLIENTBRANCH_REPORTINTERACTION_FECTHING:
            return {
                ...state,
                isClientBranchFetching: true,
            }
        case CLIENTBRANCH_REPORTINTERACTION_FETCHED:
            return {
                ...state,
                isClientBranchFetching: false,
                dataClientBranchs: action.payload.payload.data.map(data => ({
                    value: data.id,
                    label: data.name,
                    branch: data
                })),
                fetchClientBranchCode: action.payload.code,
                fetchClientBranchStatus: action.payload.status,
                fetchClientBranchMessage: action.payload.message,
            }
        case CLIENTBRANCH_REPORTINTERACTION_FAIL:
            return {
                ...state,
                isClientBranchFetching: false,
                fetchClientBranchCode: action.payload.code,
                fetchClientBranchStatus: action.payload.status,
                fetchClientBranchMessage: action.payload.message,
            }
        case CLIENTBRANCH_REPORTINTERACTION_ERROR:
            return {
                ...state,
                isClientBranchFetching: false,
                fetchClientBranchCode: action.payload.code,
                fetchClientBranchMessage: action.payload.message,
            }
        case REPORT_INTERACTION_FETCHING:
            return {
                ...state,
                isReportInteractionFetching: true,
            }
        case REPORT_INTERACTION_FETCHED:
            const constructData = {
                reportInteractionData: action.payload.payload.data,
                reportInteractionDataLength: action.payload.payload.total
            };
            return {
                ...state,
                isReportInteractionFetching: false,
                dataReportInteractions: constructData,
                fetchReportInteractionCode: action.payload.code,
                fetchReportInteractionStatus: action.payload.success,
                fetchReportInteractionMessage: action.payload.message,
            }
        case REPORT_INTERACTION_FAIL:
            return {
                ...state,
                isReportInteractionFetching: false,
                fetchReportInteractionCode: action.payload.code,
                fetchReportInteractionStatus: action.payload.success,
                fetchReportInteractionMessage: action.payload.message,
            }
        case REPORT_INTERACTION_ERROR:
            return {
                ...state,
                isReportInteractionFetching: false,
                fetchReportInteractionCode: action.payload.code,
                fetchReportInteractionMessage: action.payload.message,
            }
        case REPORT_INTERACTION_EXPORTING:
            return {
                ...state,
                isExportReportInteractionFetching: true
            }
        case REPORT_INTERACTION_EXPORTED:
            return {
                ...state,
                isExportReportInteractionFetching: false,
                dataExportReportInteractions: action.payload.payload.data,
                fetchExportReportInteractionCode: action.payload.code,
                fetchExportReportInteractionStatus: action.payload.success,
                fetchExportReportInteractionMessage: action.payload.message,
            }
        case REPORT_INTERACTION_EXPORT_FAIL:
            return {
                ...state,
                isExportReportInteractionFetching: false,
                fetchExportReportInteractionCode: action.payload.code,
                fetchExportReportInteractionStatus: action.payload.success,
                fetchExportReportInteractionMessage: action.payload.message,
            }
        case REPORT_INTERACTION_EXPORT_ERROR:
            return {
                ...state,
                isExportReportInteractionFetching: false,
                fetchExportReportInteractionCode: action.payload.code,
                fetchExportReportInteractionMessage: action.payload.message,
            }
        case REPORTINTERACTION_V2_INIT:
            return {
                ...state,
                fetchExportReportInteractionV2Code: 0,
                fetchExportReportInteractionV2Status: false,
                fetchExportReportInteractionV2Message: "",
            }
        case REPORTINTERACTION_V2_EXPORTING:
            return {
                ...state,
                isExportReportInteractionV2Fetching: true
            }
        case REPORTINTERACTION_V2_EXPORTED:
            return {
                ...state,
                isExportReportInteractionV2Fetching: false,
                // dataExportReportInteractionV2s: action.payload,
                fetchExportReportInteractionV2Code: action.payload.code,
                fetchExportReportInteractionV2Status: action.payload.success,
                fetchExportReportInteractionV2Message: action.payload.message,
            }
        case REPORTINTERACTION_V2_EXPORT_FAIL:
            return {
                ...state,
                fetchExportReportInteractionV2Code: action.payload.code,
                fetchExportReportInteractionV2Status: action.payload.success,
                fetchExportReportInteractionV2Message: action.payload.message,
            }
        case REPORTINTERACTION_V2_EXPORT_ERROR:
            return {
                ...state,
                fetchExportReportInteractionV2Code: action.payload.code,
                fetchExportReportInteractionV2Message: action.payload.message,
            }
        default:
            break;
    }
    return state;
}
export default reportInteraction;